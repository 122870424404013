import { takeLatest, put, call, fork, delay, select } from "redux-saga/effects";
import agent from "../../agent";
import { setUsers, getUserDetail, setUser ,getPayeeDetails,setPayeeDetailsList ,getInitiateDetails,setInitiateDetails,updateCsvName,
  checkTransactionIdExistOrNot,getInitiateDetailsWithDealy,setInitiatePaymentDetails , handleWebsocketCallbackResponse, initiateDetails ,
  updateInitateDetails , setCurrentPaymentTransactionId ,setInitiatePaymentDetailsAtKey , setTrackingStatusMap, getUsersListToDisplay, 
  setViewOfUser,
  initiateDetailsForUser,
  setSelectedUser,
  currentLoggedInUser,
  setCurrentUserEmail} from "./userDashboardSlice";
import { setRandomAlphaDigit} from "../FormsModal/FormSlice";

function* getUserDetails() {
  try {
    const response = yield call(agent.User.getUserDetails); 
    if (response && response.status === 200) {
      yield put(setUser(response.data.data));
    }
  } catch (e) {
    console.log(e);
  }
}

function* getPayeeDetailList(initiatePaymentId) {
  try {
    const response = yield call(agent.userDashboard.getPayeeDetails,initiatePaymentId.payload);
    if (response && response.status === 200) {
      yield put(setPayeeDetailsList(response.data));
    }
  } catch (e) {
    console.log(e);
  }
}

function* getInitiateDetailList() {
  try {
    const response = yield call(agent.userDashboard.getInitiateDetails);
    if (response && response.status === 200) {
    yield put(setInitiateDetails(response.data));
    let initiatePaymentDetails = {
      totalPayout:0,
      totalBulkPayTranser:0,
      inprogressCount:0,
      totalAmount:0
    }
    Object.keys(response.data).map((key,index)=>{
      let payment = response.data[key];
      if(payment.status === "SETTLED" || payment.status === "RECEIVED"){
          let previousTotalBulkPayTranser = initiatePaymentDetails.totalBulkPayTranser;
          initiatePaymentDetails.totalBulkPayTranser = Number(previousTotalBulkPayTranser + 1);
      }
      if(payment.status === "PENDING" || payment.status === "NOT STARTED"){
          let previousInprogressCount = initiatePaymentDetails.inprogressCount;
          initiatePaymentDetails.inprogressCount = Number( previousInprogressCount + 1);
      }
      let previousTotalPayout = payment.payeeCount;
      initiatePaymentDetails.totalPayout += Number(previousTotalPayout);
      initiatePaymentDetails.totalAmount += payment.amount;
  })
  yield put(setInitiatePaymentDetails(initiatePaymentDetails));
    }
  } catch (e) {
    console.log(e);
  }
}

function* updateCsvNameInDB(updateCsvName) {
  try {
    const response = yield call(agent.userDashboard.updateCsvName,updateCsvName.csvName,updateCsvName.paymentId);
    if (response && response.status === 200) {
      yield put(setInitiateDetails(response.data));
    }
  } catch (e) {
    console.log(e);
  }
}

function* checkTransactionIdExist(transactionObject){
  try {
    let transactionID = transactionObject.payload;
    const response = yield call(agent.userDashboard.checkTransactionIdExistOrNot,transactionID);
    if(response && response.status === 200 && !response.data){
     yield put(setRandomAlphaDigit(transactionID));
    }
  
  } catch (error) {
    console.log(error);
  }
}

function* handleWebsocketCallbackResponses(callbackResponse){
  try {
    const { initiateDetails, currentPaymentTransactionId ,initiatePaymentDetails ,payoutTransactionIds } = yield select((state) => state.userDashboardSlice)
    const { verifiedPayeeDetailsList } = yield select((state) => state.formSlice)

    let response = callbackResponse.payload;
    let key = response.transactionID.toString();
    let status = response.status;
    
    let initiateDetailsObj = initiateDetails[key];
    
    if (initiateDetailsObj != undefined && response.hasOwnProperty("payment")) {
      let walletTxID = response.walletTxID;
      yield put(setCurrentPaymentTransactionId(key));
      let updatedDetails = {
        walletTxID: response.walletTxID,
        status: "NOT STARTED",
      }
      yield put(updateInitateDetails({ key: key, updatedDetails: updatedDetails }))
    } else if (response.hasOwnProperty("payout")) {
      
      const key = response.transactionID.toString();
      const status = response.status;
      yield put(setTrackingStatusMap({ key: key, value: status }));

      const {currentPaymentTransactionId , trackingPayout} = yield select((state) => state.userDashboardSlice)

      if (Array.from(trackingPayout.values()).filter(item => item === "PENDING").length === verifiedPayeeDetailsList.length) {
        let updatedDetails = {
          status: "In Progress",
        }
        yield put(updateInitateDetails({ key: currentPaymentTransactionId, updatedDetails: updatedDetails }))
      }

      if (Array.from(trackingPayout.values()).filter(item => item === "SETTLED").length == 1) {
        let updatedDetails = {
          status: "PARTIALLY COMPLETED",
        }
        yield put(updateInitateDetails({ key: currentPaymentTransactionId, updatedDetails: updatedDetails }))
      }

      if (Array.from(trackingPayout.values()).filter(item => item === "SETTLED").length == verifiedPayeeDetailsList.length) {
        let updatedDetails = {
          status: "SETTLED",
        }
        yield put(updateInitateDetails({ key: currentPaymentTransactionId, updatedDetails: updatedDetails }))
        let totalBulkPayTranser = initiatePaymentDetails["totalBulkPayTranser"];
        let inprogressCount = initiatePaymentDetails["inprogressCount"];
        yield put(setInitiatePaymentDetailsAtKey({ key: "totalBulkPayTranser", value: (Number(totalBulkPayTranser) + 1) }))
        yield put(setInitiatePaymentDetailsAtKey({ key: "inprogressCount", value: (Number(inprogressCount) - 1) }))
      }
    }
   
  } catch (e) {
    console.log(e);

  }
}

function* getUsersList() {
  try {
    const response = yield call(agent.userDashboard.getUsersDetails);
    if (response && response.status === 200) {
      yield put(setUsers(response.data));
    }
  } catch (e) {
    console.log(e);
  }
}

function* sendInitiatedDetailsForUser(data) {
  try {
    const response = yield call(agent.userDashboard.initiateDetailsForUser, data);
    if (response && response.status === 200) {
      yield put(setInitiateDetails(response.data));
      let initiatePaymentDetails = {
        totalPayout:0,
        totalBulkPayTranser:0,
        inprogressCount:0,
        totalAmount:0
      }
      Object.keys(response.data).map((key,index)=>{
        let payment = response.data[key];
        if(payment.status === "SETTLED" || payment.status === "RECEIVED"){
            let previousTotalBulkPayTranser = initiatePaymentDetails.totalBulkPayTranser;
            initiatePaymentDetails.totalBulkPayTranser = Number(previousTotalBulkPayTranser + 1);
        }
        if(payment.status === "PENDING" || payment.status === "NOT STARTED"){
            let previousInprogressCount = initiatePaymentDetails.inprogressCount;
            initiatePaymentDetails.inprogressCount = Number( previousInprogressCount + 1);
        }
        let previousTotalPayout = payment.payeeCount;
        initiatePaymentDetails.totalPayout += Number(previousTotalPayout);
        initiatePaymentDetails.totalAmount += payment.amount;
    })
    yield put(setInitiatePaymentDetails(initiatePaymentDetails));
      }
    } catch (e) {
      console.log(e);
    }
}

function* getCurrentLoggedInUser() {
  try {
    const response = yield call(agent.userDashboard.getCurrentLoggedInUser);
    if (response && response.status === 200) {
      yield put(setCurrentUserEmail(response.data.data));
    }
  } catch (e) {
    console.log(e);
  }
}

function* onUserDashboard() {
  yield takeLatest(getUserDetail.type, getUserDetails);
  yield takeLatest(getPayeeDetails.type,getPayeeDetailList);
  yield takeLatest(getInitiateDetails.type,getInitiateDetailList);
  yield takeLatest(updateCsvName.type,updateCsvNameInDB);
  yield takeLatest(checkTransactionIdExistOrNot.type,checkTransactionIdExist);
  yield takeLatest(handleWebsocketCallbackResponse.type,handleWebsocketCallbackResponses);
  yield takeLatest(getUsersListToDisplay.type,getUsersList);
  yield takeLatest(initiateDetailsForUser.type, sendInitiatedDetailsForUser);
  yield takeLatest(currentLoggedInUser.type, getCurrentLoggedInUser);
}

export const userDashboardSaga = [fork(onUserDashboard)];

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Login from "../../pages/Login/Login";

import "./header.css";
import { useDispatch } from "react-redux";
import { setLoginStep } from "../../pages/Login/LoginSlice";
import {
  setActiveFormId,
  resetverifiedPayeeDetailsList,
} from "../../pages/FormsModal/FormSlice";
import {
  setActiveRegistrationStep,
  resetUserRegistration,
} from "../../pages/Registration/RegistrationSlice";

function Header(props) {
  const dispatch = useDispatch();

  const onClickLogin = () => {
    // setIsShowLogin(true);
    dispatch(setLoginStep("1"));
    dispatch(setActiveFormId(""));
    dispatch(resetverifiedPayeeDetailsList());
  };
  // const [isShowLogin, setIsShowLogin] = useState(false);

  const handleOnShowSignup = () => {
    let prodUrl = "https://accounts.culipa.com/register";
    let stagingUrl = "https://accounts-test.culipa.com/register";

    if (window.location.origin === "https://bulkpay.culipa.com") {
      window.location.href = prodUrl;
    } else if (
      window.location.origin === "http://bulkpay-test.culipa.com:443"
    ) {
      window.location.href = stagingUrl;
    } else {
      dispatch(setActiveRegistrationStep(1));
    }
    dispatch(resetverifiedPayeeDetailsList());
    dispatch(resetUserRegistration());
  };

  return (
    <nav className="navbar navbar-expand-sm navbar-dark">
      <div className="container">
        <a className="navbar-brand" href="/">
          <img src="images/logo-white.png" alt="" />
        </a>

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#mynavbar"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="mynavbar">
          <ul className="navbar-nav ms-auto">
            {/* <li className="nav-item px-lg-5 px-sm-3 px-1">
              <a className="nav-link" href="javascript:void(0)">Pricing</a>
            </li> */}
            <li className="nav-item ps-lg-5 pe-0 ps-sm-3 px-1">
              <a className="nav-link" onClick={onClickLogin}>
                {" "}
                Login{" "}
              </a>
            </li>
            <li className="nav-item px-lg-5 px-sm-3 px-1">
              <a
                className="nav-link"
                href="javascript:void(0);"
                onClick={handleOnShowSignup}
              >
                Sign up
              </a>
            </li>
          </ul>
        </div>
        {/* {isShowLogin ? <Login /> : null} */}
      </div>
    </nav>
  );
}

export default Header;

import React, { useState } from "react";
import { setShowLoader, setIsPaymentComplete } from "../FormsModal/FormSlice";
import { useDispatch, useSelector } from "react-redux";
function ConfirmationModal({ setShowConfirmationModel, processPayment }) {
    const dispatch = useDispatch();
    const [isLoaderVisible, setIsLoaderVisible] = useState(false);
    const { isShowLoader, isHideConfirmationModelButton, isPaymentComplete } =
        useSelector((state) => state.formSlice);
    const handleCloseModel = () => {
        setShowConfirmationModel(false);
    };
    const handleProcessPayment = () => {
        processPayment();
        dispatch(setIsPaymentComplete(true));
    };

    return (
        <React.Fragment>
            <div class={isPaymentComplete ? "lds-dual-ring" : ""}></div>
            <div
                className="modal fade show d-block all-modals-common-class"
                aria-hidden="true"
                tabIndex="-1"
            >
                <div className="modal-dialog">
                    <div className="modal-content" style={{ padding: "45px 40px" }}>
                        <div className="modal-close-btn">
                            <img
                                src="images/close.png"
                                alt="close-icon"
                                onClick={handleCloseModel}
                            />
                        </div>
                        <div className="modal-header align-items-center mb-4">
                            <span>
                                <img
                                    src="images/transfer.png"
                                    alt=""
                                    className="login-user-icon"
                                />
                            </span>
                            <div className="d-flex flex-column">
                                <h2
                                    className="h2"
                                    style={{ lineHeight: "40px", fontSize: "30px" }}
                                >
                                    Pay For Your Bulk Transfer
                                </h2>
                            </div>
                        </div>
                        <div className="modal-body pb-0">
                            <h5 className="modal-step-title mb-1">
                                {" "}
                                Please approve the payment on your phone{" "}
                            </h5>
                        </div>
                        {isHideConfirmationModelButton ? (
                            <div className="w-100 mt-4 mb-3 validate-total-amount-wrapper">
                                <div className="d-flex align-items-center justify-content-between">
                                    <button
                                        className="btn registration-login-btn w-80"
                                        onClick={handleProcessPayment}
                                    >
                                        {" "}
                                        Try again{" "}
                                    </button>
                                    <button
                                        className="btn registration-login-btn w-80"
                                        onClick={handleCloseModel}
                                    >
                                        {" "}
                                        Cancel{" "}
                                    </button>
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
export default ConfirmationModal;

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import {
    setActiveFormId,
    resetUploadedPayeeDetailsList,
    resetManuallyAddedPayeeDetailsList,
    setActiveUuid,
    blankManuallyAddedPayeeDetailsList,
} from "../FormsModal/FormSlice";
import InvalidPayeeDetailsModal from "../FormsModal/InvalidPayeeDetailsModal";
import ManualEntryFormModal from "../FormsModal/ManualEntryFormModal";
import UploadModal from "../FormsModal/UploadModal";
import ValidatedPayeeDetailsModal from "../FormsModal/ValidatedPayeeDetailsModal";
import EmailLoginOtp from "../Login/EmailLoginOtp";
import Login from "../Login/Login";
import { setActiveRegistrationStep } from "../Registration/RegistrationSlice";
import RegistrationStepFour from "../Registration/RegistrationStepFour";
import RegistrationStepOne from "../Registration/RegistrationStepOne";
import RegistrationStepThree from "../Registration/RegistrationStepThree";
import RegistrationStepTwo from "../Registration/RegistrationStepTwo";
import "./home.css";
import PaymentDetailsModal from "../FormsModal/PaymentDetailsModal";
import CheckoutModal from "../FormsModal/CheckoutModal";
import PreValidateUser from "../Login/PreValidateUser";
import uuid from "react-uuid";
function Home() {
    const dispatch = useDispatch();
    const { activeRegistrationStep, isLogin } = useSelector(
        (state) => state.registrationSlice
    );
    const { loginStep } = useSelector((state) => state.loginSlice);
    const { activeFormId, uploadedPayeeDetailsList } = useSelector(
        (state) => state.formSlice
    );

    const onClickMannual = () => {
        dispatch(resetUploadedPayeeDetailsList());
        dispatch(setActiveFormId("2"));
        dispatch(setActiveUuid(uuid()));
        dispatch(resetManuallyAddedPayeeDetailsList());
        localStorage.removeItem("csvName");
    };

    const onClickUpload = () => {
        dispatch(blankManuallyAddedPayeeDetailsList());
        dispatch(setActiveFormId("1"));
    };

    return (
        <section className="banner-container bg-image bg-dark pt-3">
            <Header />
            {activeRegistrationStep == "1" ? (
                <RegistrationStepOne />
            ) : activeRegistrationStep == "2" ? (
                <RegistrationStepTwo />
            ) : activeRegistrationStep == "3" ? (
                <RegistrationStepThree />
            ) : activeRegistrationStep == "4" ? (
                <RegistrationStepFour />
            ) : null}

            {loginStep == "1" ? (
                <Login />
            ) : loginStep == "2" ? (
                <EmailLoginOtp />
            ) : null}

            {activeFormId == "1" ? (
                <UploadModal />
            ) : activeFormId == "2" ? (
                <ManualEntryFormModal />
            ) : activeFormId == "3" ? (
                <InvalidPayeeDetailsModal />
            ) : activeFormId == "4" ? (
                <ValidatedPayeeDetailsModal />
            ) : activeFormId == "5" ? (
                <PaymentDetailsModal />
            ) : activeFormId == "6" ? (
                <CheckoutModal />
            ) : activeFormId == "7" ? (
                <PreValidateUser />
            ) : null}

            <main className="banner-container-text-box mt-lg-1 mt-md-5 mt-1 mb-0">
                <div className="container">
                    <div className="row gy-3 align-items-center justify-content-stretch">
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                            <div className="banner-container-heading">
                                <div className="row">
                                    <div className="col-12 col-sm-12 col-md-3 col-lg-2 d-none d-xl-inline-block ">
                                        <img
                                            src="images/banner-down-arrow.png"
                                            alt=""
                                            width={"70em"}
                                            height={"180em"}
                                        />
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-9 col-lg-10 text-center text-md-start">
                                        <p className="no-wrap">
                                            {" "}
                                            <img
                                                src="images/transfer.png"
                                                style={{
                                                    width: "1.4em",
                                                    height: "1.4em",
                                                    backgroundColor: "#3dad49",
                                                    borderRadius: "14px",
                                                }}
                                                alt=""
                                            />{" "}
                                            Transfer to up to{" "}
                                        </p>
                                        <h1>
                                            1,000 Mobile{" "}
                                            <span className="d-none d-md-inline d-lg-inline">
                                                <br />
                                            </span>
                                            Money Payees{" "}
                                            <span className="d-none d-md-inline d-lg-inline">
                                                <br />
                                            </span>{" "}
                                            In Minutes{" "}
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                            <div className="row">
                                <div className="col-12 banner-action-numbers-images">
                                    <div className="row justify-content-center align-items-center">
                                        <div className="col-3">
                                            <img
                                                src="images/Lines.png"
                                                className="img-fluid img image1"
                                            />
                                        </div>
                                        <div className="col-3">
                                            <img
                                                src="images/Lines2.png"
                                                className="img-fluid img image2"
                                                style={
                                                    {
                                                        // position: "relative",
                                                        // top: "20%",
                                                        // right: "20%",
                                                    }
                                                }
                                            />
                                        </div>
                                        <div className="col-3">
                                            <img
                                                src="images/Lines3.png"
                                                className="img-fluid img image3"
                                                style={
                                                    {
                                                        // position: "relative",
                                                        // top: "20%",
                                                    }
                                                }
                                            />
                                        </div>
                                        <div className="col-3">
                                            <img
                                                src="images/Lines4.png"
                                                className="img-fluid img image4"
                                                style={
                                                    {
                                                        // position: "relative",
                                                        // top: "35%",
                                                    }
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="col-12 banner-action-numbers-images"
                                    style={{ zIndex: "1" }}
                                >
                                    <div className="banner-action-container col-12">
                                        <h3>
                                            {" "}
                                            Ready to see <br /> BulkPay in action?{" "}
                                        </h3>
                                        <button
                                            className="banner-upload-csv-btn col-9 col-sm-9 col-md-11 col-lg-9 col-xl-9"
                                            onClick={onClickUpload}
                                        >
                                            {" "}
                                            Upload CSV or TXT file{" "}
                                        </button>
                                        <button
                                            className="banner-manually-btn col-6 col-sm-6 col-md-8 col-lg-8 col-xl-6"
                                            onClick={onClickMannual}
                                        >
                                            {" "}
                                            Enter Manually{" "}
                                        </button>
                                    </div>
                                </div>
                                <div className="col-12 banner-action-numbers-images">
                                    <div className="row">
                                        <div className="col-3">
                                            <img
                                                src="images/Lines5.png"
                                                className="img-fluid image5"
                                            // style={{ position: "relative", bottom: "5%" }}
                                            />
                                        </div>
                                        <div className="col-3">
                                            <img
                                                src="images/Lines6.png"
                                                className="img-fluid image6"
                                            // style={{ position: "relative", bottom: "10%" }}
                                            />
                                        </div>
                                        <div className="col-3">
                                            <img
                                                src="images/Lines7.png"
                                                className="img-fluid image7"
                                            // style={{ position: "relative", bottom: "14%" }}
                                            />
                                        </div>
                                        <div className="col-3">
                                            <img
                                                src="images/Lines8.png"
                                                className="img-fluid image8"
                                            // style={{ position: "relative", bottom: "15%" }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </section>
    );
}

export default Home;

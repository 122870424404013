import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    setProceedPayment,
    setActiveFormId,
    setPayingOptions,
} from "./FormSlice";
import jsPDF from "jspdf";
import { culipaFormatter } from "../../culipaUtils/culipaFormatter";

function BankPdfInstruction() {
    const dispatch = useDispatch();
    const { referenceId } = useSelector((state) => state.formSlice);
    const {
        payeeTotalCalculation,
        payeeUpdatedCalculation,
        withdrawToggle,
        verifiedPayeeDetailsList,
        updatedCulipaFees,
        currentTime,
        finalPayableAmount,
        totalTax,
        bankCollectionFees,
        defaultCalculationMode,
        culipaFees,
        updatedTax,
    } = useSelector((state) => state.formSlice);

    const generatePDF = () => {
        const unit = "pt";
        const size = "A4";
        const orientation = "portrait";
        const doc = new jsPDF(orientation, unit, size);

        doc.addImage("/images/BulkPay-dark-logo.png", "PNG", 10, 10, 50, 20);
        doc.setFontSize(16);
        doc.text(`${currentTime}`, 400, 50);
        doc.text(`BulkPay Transfer Id: `, 25, 70);
        doc
            .setFont(undefined, "bold")
            .text(`${referenceId}`, 175, 70)
            .setFont(undefined, "normal");
        doc.text(`No of Payees: ${verifiedPayeeDetailsList.length}`, 25, 90);
        doc.text(
            `Total Amount: UGX ${culipaFormatter.currencyFormatter.format(
                withdrawToggle ? payeeUpdatedCalculation : payeeTotalCalculation
            )}`,
            25,
            110
        );
        doc.text(
            `Culipa Fees: UGX ${culipaFormatter.currencyFormatter.format(
                withdrawToggle
                    ? defaultCalculationMode
                        ? updatedCulipaFees.totalFees
                        : updatedTax
                    : defaultCalculationMode
                        ? culipaFees.totalFees
                        : totalTax
            )}`,
            25,
            130
        );
        doc.text(
            `Bank Collection Fees: UGX ${culipaFormatter.currencyFormatter.format(
                defaultCalculationMode
                    ? culipaFees.bankCollectionFees
                    : bankCollectionFees
            )}`,
            25,
            150
        );
        doc.text(`Total: `, 25, 170);
        doc
            .setFont(undefined, "bold")
            .text(
                `UGX ${culipaFormatter.currencyFormatter.format(finalPayableAmount)}`,
                70,
                170
            )
            .setFont(undefined, "normal");

        doc.text(
            `To complete your bulk transfer, please transfer or deposit `,
            25,
            210
        );
        doc
            .setFont(undefined, "bold")
            .text(
                `UGX ${culipaFormatter.currencyFormatter.format(finalPayableAmount)}`,
                435,
                210
            )
            .setFont(undefined, "normal");
        doc.text(
            `to any of our bank accounts below: Make sure to enter the Bulk Transfer`,
            25,
            230
        );
        doc.text(`ID as the payment Reference or Narrative.`, 25, 250);

        doc
            .setFont(undefined, "bold")
            .text(`Stanbic Bank`, 25, 290)
            .setFont(undefined, "normal");
        doc.text(`Account Name: Culipa Limited`, 25, 310);
        doc.text(`Account number: 9030019971065`, 25, 330);
        doc.text(`Branch: Acacia Mall Kampala`, 25, 350);

        doc
            .setFont(undefined, "bold")
            .text(`Equity Bank`, 25, 390)
            .setFont(undefined, "normal");
        doc.text(`Account Name: Culipa Limited`, 25, 410);
        doc.text(`Account number: 1001201279303`, 25, 430);
        doc.text(`Branch: Church House Kampala`, 25, 450);

        doc.text(`After transfer/deposit, please call or WhatsApp us on `, 25, 490);
        doc
            .setFont(undefined, "bold")
            .text(`0393 324 095`, 405, 490)
            .setFont(undefined, "normal");
        doc.text(`to`, 513, 490);
        doc.text(
            `inform us of the payment. Have the Bulk Transfer ID on hand to share`,
            25,
            510
        );
        doc.text(
            `with us. Once we confirm the funds have been received, we will complete`,
            25,
            530
        );
        doc.text(`the bulk transfer and notify you.`, 25, 550);

        doc.save(`${referenceId}.pdf`);
        dispatch(setProceedPayment(false));
        dispatch(setActiveFormId(null));
    };

    const handleCloseModel = () => {
        dispatch(setProceedPayment(false));
    };

    return (
        <React.Fragment>
            <div
                className="modal fade show d-block all-modals-common-class"
                aria-hidden="true"
                tabIndex="-1"
            >
                <div className="modal-dialog ">
                    <div className="modal-content" style={{ padding: "45px 40px" }}>
                        <div className="modal-close-btn">
                            <img
                                src="images/close.png"
                                alt="close-icon"
                                onClick={handleCloseModel}
                            />
                        </div>
                        <div className="modal-header align-items-center mb-4">
                            <span>
                                <img
                                    src="images/transfer.png"
                                    alt=""
                                    className="login-user-icon"
                                />
                            </span>
                            <div className="d-flex flex-column">
                                <h2 className="modal-title fw-bold">
                                    Click on the below button to download the pdf.
                                </h2>
                            </div>
                        </div>
                        <div
                            className="solidBorder"
                            style={{ overflowY: "scroll", maxHeight: "300px" }}
                        >
                            <p>
                                <p
                                    className="text-start text-sm-end"
                                    style={{ textAlign: "right" }}
                                >
                                    {currentTime}
                                </p>
                                BulkPay Transfer Id : <b>{referenceId}</b> <br></br>
                                No of Payees : {verifiedPayeeDetailsList.length}
                                <br></br>
                                Total Amount : UGX{" "}
                                {culipaFormatter.currencyFormatter.format(
                                    withdrawToggle
                                        ? payeeUpdatedCalculation
                                        : payeeTotalCalculation
                                )}{" "}
                                <br></br>
                                Culipa Fees : UGX{" "}
                                {culipaFormatter.currencyFormatter.format(
                                    withdrawToggle
                                        ? defaultCalculationMode
                                            ? updatedCulipaFees.totalFees
                                            : updatedTax
                                        : defaultCalculationMode
                                            ? culipaFees.totalFees
                                            : totalTax
                                )}
                                <br></br>
                                Bank Collection Fees : UGX{" "}
                                {culipaFormatter.currencyFormatter.format(
                                    defaultCalculationMode
                                        ? culipaFees.bankCollectionFees
                                        : bankCollectionFees
                                )}
                                <br></br>
                                Total :{" "}
                                <b>
                                    {" "}
                                    UGX{" "}
                                    {culipaFormatter.currencyFormatter.format(
                                        finalPayableAmount
                                    )}{" "}
                                </b>{" "}
                                <br></br>
                                <br></br>
                                To complete your bulk transfer, please transfer or deposit{" "}
                                <b>
                                    UGX{" "}
                                    {culipaFormatter.currencyFormatter.format(finalPayableAmount)}{" "}
                                </b>
                                to any of our bank accounts below: <br></br>
                                Make sure to enter the Bulk Transfer ID as the payment Reference
                                or Narrative.
                                <br></br>
                                <br></br>
                                <b>Stanbic Bank </b>
                                <br></br>
                                Account Name: Culipa Limited
                                <br></br>
                                Account number: 9030019971065
                                <br></br>
                                Branch: Acacia Mall Kampala
                                <br></br> <br></br>
                                <b>Equity Bank </b>
                                <br></br>
                                Account Name: Culipa Limited
                                <br></br>
                                Account number: 1001201279303
                                <br></br>
                                Branch: Church House Kampala
                                <br></br> <br></br>
                                After transfer/deposit, please call or WhatsApp us on{" "}
                                <b>0393 324 095</b> to inform us of the payment. Have the Bulk
                                Transfer ID on hand to share with us. Once we confirm the funds
                                have been received, we will complete the bulk transfer and
                                notify you.
                                <br></br>
                            </p>
                        </div>
                        <br></br>
                        <div className="modal-footer d-inline-block">
                            <button
                                className="btn registration-login-btn w-100"
                                onClick={generatePDF}
                            >
                                {" "}
                                Download{" "}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default BankPdfInstruction;

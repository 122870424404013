import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isValidCardNo, isValidDate, isValidNationalID, isValue } from "../../components/Utility/ValidationUtilities";
import { setActiveRegistrationStep, setRegistrationDetails, takeRegisterUser, setUserRegistration, resetUserRegistration } from "./RegistrationSlice";
import { setLoginStep } from '../Login/LoginSlice'
import agent from "../../agent";
import { culipaFormatter } from "../../culipaUtils/culipaFormatter";


function RegistrationStepTwo() {
    const { registrationDetails, userRegistration } = useSelector((state) => state.registrationSlice);
    const { verifiedPayeeDetailsList, totalPayeeTransactions } = useSelector((state) => state.formSlice);
    const [sex, setSex] = useState(userRegistration["sex"] != "" ? userRegistration["sex"] : "Male");
    const [dateOfBirth, setDateOfBirth] = useState(userRegistration["dateOfBirth"]);
    const [nationalIdNumber, setNationalIdNumber] = useState(userRegistration["nationalIdNumber"]);
    const [nationalIdCardNumber, setNationalIdCardNumber] = useState(userRegistration["nationalIdCardNumber"]);
    const [showError, setShowError] = useState(false);
    const [isNationalIdExist, setIsNationalIdExist] = useState(false);
    const [isNationalIdCardExist, setIsNationalIdCardExist] = useState(false);

    const dispatch = useDispatch();
    const onClickPrevious = () => {
        dispatch(setActiveRegistrationStep("1"));
    }

    const onClickCancel = () => {
        dispatch(setActiveRegistrationStep(""));
    }

    const handleOnChangeSex = (sex) => {
        setSex(sex);
        dispatch(setUserRegistration({ key: "sex", info: sex }))
    }

    const handleOnChangeDateOfBirth = (dob) => {
        try {
            setShowError(false)
            setDateOfBirth(dob)
            dispatch(setUserRegistration({ key: "dateOfBirth", info: dob }))
        } catch (error) {
            setDateOfBirth(new Date())
            console.log(error);
        }
    }

    const handleOnChangeNationalIdNumber = (national_Id) => {
        setNationalIdNumber(national_Id);
        if (isValidNationalID(national_Id)) {
            return agent.User.checkNationalIdExist(national_Id).then((response) => {
                setIsNationalIdExist(response.data.data)
                dispatch(setUserRegistration({ key: "nationalIdNumber", info: national_Id }))
            })
        }
        setIsNationalIdExist(false);
        dispatch(setUserRegistration({ key: "nationalIdNumber", info: national_Id }))
    }

    const handleOnChangeNationalIdCardNumber = (national_Id_Card) => {
        setNationalIdCardNumber(national_Id_Card);
        if (isValidCardNo(national_Id_Card)) {
            return agent.User.checkNationalIdCardExist(national_Id_Card).then((response) => {
                setIsNationalIdCardExist(response.data.data)
                dispatch(setUserRegistration({ key: "nationalIdCardNumber", info: national_Id_Card }))
            })
        }
        setIsNationalIdCardExist(false);
        dispatch(setUserRegistration({ key: "nationalIdCardNumber", info: national_Id_Card }))
    }

    const getIsoDate = () => {
        try {
            return dateOfBirth.toISOString().substr(0, 10);
        } catch (error) {
            console.log(error);
        }
    }



    const underAgeValidate = (date) => {
        try {
            var birthday = date.getFullYear() + "-" + getMonth(date) + "-" + getDay(date)
            var optimizedBirthday = birthday.replace(/-/g, "/");
            var myBirthday = new Date(optimizedBirthday);
            var currentDate = new Date().toJSON().slice(0, 10) + ' 01:00:00';
            var myAge = ~~((Date.now(currentDate) - myBirthday) / (31557600000));
            if (myAge < 18) {
                return false;
            } else {
                setDateOfBirth(birthday);
                return true;
            }
        } catch (error) {
            console.log(error);
        }
    }

    const validateNationalId = () => {
        return agent.User.checkNationalIdExist(nationalIdNumber).then((response) => {
            return response.data.data;
        })
    }

    const validateNationalIdCard = () => {
        return agent.User.checkNationalIdCardExist(nationalIdCardNumber).then((response) => {
            return response.data.data;
        })
    }

    const isValidate = () =>
        isValue(sex) &&
        isValidDate(dateOfBirth) &&
        isValidNationalID(nationalIdNumber) &&
        isValidCardNo(nationalIdCardNumber) &&
        underAgeValidate(dateOfBirth)



    async function validateAndProceed() {
        let isNationalIdExist = false;
        let isNationalIdCardExist = false;

        isNationalIdExist = await validateNationalId();
        isNationalIdCardExist = await validateNationalIdCard();

        if (!isNationalIdCardExist && !isNationalIdExist) {
            if (isValidate()) {
                setShowError(false);
                const newObj = {
                    ...registrationDetails,
                    gender: sex,
                    dob: dateOfBirth,
                    nationalIdNo: nationalIdNumber,
                    nationalIdCardNo: nationalIdCardNumber
                };
                dispatch(setRegistrationDetails(newObj));
                dispatch(setActiveRegistrationStep("3"));
            } else {
                setShowError(true);
            }
        } else {
            setIsNationalIdCardExist(isNationalIdCardExist);
            setIsNationalIdExist(isNationalIdExist);
            return;
        }
    }

    const onClickNext = () => {
        try {
            validateAndProceed();
        } catch (error) {
            console.log(error);
        }
    }

    const getMonth = (date) => {
        return 1 + parseInt(date.getMonth())
    }

    const getDay = (date) => {
        return 1 + parseInt(date.getDay())
    }

    const handleLogin = () => {
        dispatch(setActiveRegistrationStep(""))
        dispatch(setLoginStep("1"));
    }
    const handleCloseModel = () => {
        dispatch(setActiveRegistrationStep(""))
        dispatch(resetUserRegistration());
    }

    return (
        <div className="modal fade show d-block all-modals-common-class" aria-hidden="true" tabindex="-1">
            <div className="modal-dialog">
                {registrationDetails.nationality !== "Uganda" || registrationDetails.userType !== "Individual" ?
                    <div className="modal-content">
                        <div className="modal-close-btn">
                            <img src="images/close.png" alt="close-icon" onClick={handleCloseModel} />
                        </div>
                        <div className="modal-header">
                            <h4 className="modal-title" >For now, only Ugandan individuals can register, we will let you know when you can send as a non-individual or non-Ugandan.</h4><br />
                        </div>
                        <div className="modal-footer" style={{ borderTop: "none" }}>
                            <button className="btn registration-next-btn" onClick={onClickCancel} >Cancel</button>
                        </div>
                    </div>
                    :
                    <div className="modal-content">
                        <div className="modal-close-btn">
                            <img src="images/close.png" alt="close-icon" onClick={handleCloseModel} />
                        </div>
                        <div className="modal-header" style={{ borderBottom: "none" }}>
                            <span>
                                <img src="images/login-user.png" alt="" className="login-user-icon" />
                            </span>
                            <div className="d-flex flex-column">
                                <h4 className="modal-title" style={{ lineHeight: '40px', fontSize: '35px' }}>
                                    {/* Please Register for a <br />Culipa Account */}
                                    Sign Up for a Culipa Account
                                </h4>
                                {verifiedPayeeDetailsList.length != 0 ? <p className="modal-subtitle mt-4"> {` or login to complete your UGX ${culipaFormatter.currencyFormatter.format(totalPayeeTransactions["totalAmount"])} transfer to ${culipaFormatter.currencyFormatter.format(totalPayeeTransactions["totalPayees"])} Payees `} </p> : null}
                            </div>
                        </div>
                        <div className="modal-body">
                            <h5 className='modal-step-title'>Step 2</h5>
                            <form className="row g-3">
                                <div className='col-12'>
                                    <label for="recipient-name" className="col-form-label">Sex </label>
                                    <select className="form-select" aria-label="Default select example" onChange={(e) => handleOnChangeSex(e.target.value)}>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                    </select>
                                    {showError ? <p className="error-msg">{!isValue(sex) ? "Please select your gender" : ""}</p> : null}
                                </div>
                                <div className='col-12 mt-1'>
                                    <label for="recipient-name" className="col-form-label">Date of Birth </label>
                                    <input type="date" className="form-control" id="recipient-name" value={getIsoDate()} onChange={(e) => handleOnChangeDateOfBirth(new Date(e.target.value))} />
                                    {showError ? <p className="error-msg">{!underAgeValidate(dateOfBirth) ? "Age should be above 18." : ""}</p> : null}
                                </div>
                                <div className='col-md-6 mt-1'>
                                    <label for="recipient-name" className="col-form-label">National ID Number (NIN) </label>
                                    <input type="text" className="form-control" id="recipient-name" value={nationalIdNumber} onChange={(e) => handleOnChangeNationalIdNumber(e.target.value)} />
                                    {showError ? <p className="error-msg">{!isValidNationalID(nationalIdNumber) ? "Please enter valid NIN" : ""}</p> : null}
                                    {isNationalIdExist ? <p className="error-msg">National Id Number Already Exists</p> : null}
                                </div>
                                <div className='col-md-6 mt-1'>
                                    <label for="recipient-name" className="col-form-label">National ID Card Number </label>
                                    <input type="text" className="form-control" id="recipient-name" value={nationalIdCardNumber} onChange={(e) => handleOnChangeNationalIdCardNumber(e.target.value)} />
                                    {showError ? <p className="error-msg">{!isValidCardNo(nationalIdCardNumber) ? "Enter valid National ID Card Number" : ""}</p> : null}
                                    {isNationalIdCardExist ? <p className="error-msg">National Id Card Number Already Exists</p> : null}

                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button className="btn float-start registration-next-btn" onClick={onClickPrevious} >Back</button>
                            <button className="btn float-end registration-login-btn" onClick={onClickNext} disabled={isNationalIdExist || isNationalIdCardExist} >Next</button>
                            <button className="btn registration-login-btn w-100 mt-2" onClick={handleLogin} >Login</button>
                        </div>
                    </div>}
            </div>
        </div>
    );
}

export default RegistrationStepTwo;
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    deleteThePayeeById,
    setActiveFormId,
    setShowLoader,
    updateObjWithInvalidStatus,
    validatePayeeDetails,
} from "./FormSlice";
import { culipaFormatter } from "../../culipaUtils/culipaFormatter";

function InvalidPayeeDetailsModal() {
    const { validatedPayeeDetailsList, isShowLoader, activeUuid } = useSelector(
        (state) => state.formSlice
    );
    const [editableId, setEditableId] = useState("");
    const [isClicked, setIsClicked] = useState();
    const [fullName, setFullName] = useState();
    const [mobile, setMobile] = useState();
    const [amount, setAmount] = useState();

    const dispatch = useDispatch();

    const onclickBack = () => {
        dispatch(setActiveFormId("2"));
    };

    const onclickNext = () => {
        dispatch(setShowLoader(true));
        let objToPass = {
            activeUuid: activeUuid,
            toByPass: true,
        };
        dispatch(validatePayeeDetails(objToPass));
        dispatch(setActiveFormId("4"));
    };

    const updatedPayeeObject = {
        id: "",
        fullName: fullName,
        amount: amount,
        mobile: mobile,
    };

    const setEditableTrueForIndex = (payeeDetailsObj) => {
        setEditableId(payeeDetailsObj.id);
        setFullName(payeeDetailsObj.fullName);
        setMobile(payeeDetailsObj.mobile);
        setAmount(payeeDetailsObj.amount);
        setIsClicked(true);
    };

    const validateFirstName = (e) => {
        setFullName(e);
    };
    const validateMobile = (e) => {
        if (e.length > 2 && e.length <= 12) {
            setMobile(e);
        }
    };
    const validateAmount = (e) => {
        if (Number(e) < 40000000) {
            setAmount(e);
        }
    };
    const handleSave = (payeeToUpdate, id) => {
        updatedPayeeObject.id = id;
        dispatch(setShowLoader(true));
        var reqObj = {
            obj: payeeToUpdate,
            uuid: activeUuid,
            isByPass: true,
        };
        dispatch(updateObjWithInvalidStatus(reqObj));
        setIsClicked(false);
        setEditableId("");
    };
    const handleDelete = (id) => {
        dispatch(setShowLoader(true));
        var reqObj = {
            id: id,
            uuid: activeUuid,
            isByPass: true,
        };
        dispatch(deleteThePayeeById(reqObj));
    };

    return (
        <React.Fragment>
            {/* Loader */}
            <div class={isShowLoader ? "lds-dual-ring" : ""}></div>
            <div
                className="modal fade show d-block all-modals-common-class"
                aria-hidden="true"
                tabIndex="-1"
            >
                <div className="modal-dialog modal-max-width1000">
                    <div className="modal-content " style={{ padding: "45px 35px" }}>
                        <div className="modal-header justify-content-start align-items-center mb-4">
                            <span>
                                <img
                                    src="images/plus-icon.png"
                                    alt=""
                                    className="login-user-icon"
                                />
                            </span>
                            <div className="d-flex flex-column">
                                <h2 className="h2 m-0 fw-bold">Please confirm these payees</h2>
                            </div>
                        </div>
                        <div className="modal-body">
                            <div className="payee-details-table table-responsive invalid-payee-modal">
                                <table className="table mb-0">
                                    <thead>
                                        <tr>
                                            <th
                                                className="table-full-name-column"
                                                style={{ minWidth: "160px" }}
                                            >
                                                {" "}
                                                <div className="d-flex align-items-center">
                                                    <img src="images/user.png" alt="" />{" "}
                                                    <p>Submitted Name </p>
                                                </div>
                                            </th>
                                            {!editableId ? (
                                                <th
                                                    className="table-full-name-column"
                                                    style={{ minWidth: "160px" }}
                                                >
                                                    <div className="d-flex align-items-center">
                                                        {" "}
                                                        <img src="images/user.png" alt="" />{" "}
                                                        <p>Registered Name </p>
                                                    </div>
                                                </th>
                                            ) : (
                                                ""
                                            )}
                                            <th
                                                className="table-number-column"
                                                style={{ minWidth: "200px" }}
                                            >
                                                {" "}
                                                <div className="d-flex align-items-center">
                                                    <img
                                                        src="images/number.png"
                                                        alt=""
                                                        className=" d-block d-md-inline"
                                                    />{" "}
                                                    <p>Mobile Money Number </p>
                                                </div>
                                            </th>
                                            <th
                                                className="table-amount-column"
                                                style={{ minWidth: "140px" }}
                                            >
                                                <div className="d-flex align-items-center">
                                                    {" "}
                                                    <img src="images/amount.png" alt="" />{" "}
                                                    <p>Amount (UGX) </p>
                                                </div>
                                            </th>
                                            <th
                                                className="table-status-column"
                                                style={{ minWidth: "170px" }}
                                            >
                                                <div className="d-flex align-items-center">
                                                    {" "}
                                                    <img src="images/status.png" alt="" /> <p>Status </p>
                                                </div>
                                            </th>
                                            <th
                                                className="table-btn-status"
                                                style={{ minWidth: "170px" }}
                                            >
                                                {" "}
                                                &nbsp;{" "}
                                            </th>
                                            <th> &nbsp; </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {validatedPayeeDetailsList?.map((payeeDetails) => {
                                            return (
                                                <tr>
                                                    {payeeDetails.isEditable == "false" &&
                                                        editableId === payeeDetails.id ? (
                                                        <>
                                                            <td>
                                                                {" "}
                                                                <input
                                                                    className="table-invalid-edit-input-field"
                                                                    autoFocus
                                                                    type="text"
                                                                    value={fullName}
                                                                    onChange={(e) =>
                                                                        validateFirstName(e.target.value)
                                                                    }
                                                                ></input>
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <input
                                                                    className="table-invalid-edit-input-field"
                                                                    type="text"
                                                                    value={mobile}
                                                                    onChange={(e) =>
                                                                        validateMobile(e.target.value)
                                                                    }
                                                                ></input>
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <input
                                                                    className="table-invalid-edit-input-field"
                                                                    type="text"
                                                                    value={amount}
                                                                    onChange={(e) =>
                                                                        validateAmount(e.target.value)
                                                                    }
                                                                ></input>
                                                            </td>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <td> {payeeDetails.fullName} </td>
                                                            <td>
                                                                {payeeDetails.registeredName != null
                                                                    ? payeeDetails.registeredName.toUpperCase()
                                                                    : ""}
                                                            </td>
                                                            <td> {payeeDetails.mobile} </td>
                                                            <td>
                                                                {" "}
                                                                {culipaFormatter.currencyFormatter.format(
                                                                    payeeDetails.amount
                                                                )}{" "}
                                                            </td>
                                                        </>
                                                    )}
                                                    <td>
                                                        {" "}
                                                        {isClicked && editableId === payeeDetails.id ? (
                                                            <button
                                                                onClick={() =>
                                                                    handleSave(
                                                                        updatedPayeeObject,
                                                                        payeeDetails.id
                                                                    )
                                                                }
                                                                className="btn confirm-save-btn"
                                                                style={{ backgroundColor: "#3dad49" }}
                                                            >
                                                                {" "}
                                                                Save{" "}
                                                            </button>
                                                        ) : payeeDetails.status ? (
                                                            <button className="btn error-btn">
                                                                {" "}
                                                                {payeeDetails.status}{" "}
                                                            </button>
                                                        ) : null}{" "}
                                                    </td>
                                                    {payeeDetails.isEditable == "false" &&
                                                        editableId === payeeDetails.id && <td></td>}
                                                    <td className="text-end">
                                                        {payeeDetails.isEditable == "false" ? (
                                                            <img
                                                                src="images/edit.png"
                                                                onClick={() =>
                                                                    setEditableTrueForIndex(payeeDetails)
                                                                }
                                                                alt=""
                                                                className="table-invalid-edit-btn"
                                                            />
                                                        ) : (
                                                            <span>&nbsp;</span>
                                                        )}
                                                        <img
                                                            src="images/delete.png"
                                                            onClick={() => handleDelete(payeeDetails.id)}
                                                            alt=""
                                                            className="table-invalid-delete-btn"
                                                        />
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="row w-100">
                                <div className="col-6">
                                    <button
                                        className="btn float-start registration-next-btn"
                                        onClick={onclickBack}
                                    >
                                        Back
                                    </button>
                                </div>
                                <div className="col-6">
                                    {validatedPayeeDetailsList.some(
                                        (payee) => payee.status === "Account not Found"
                                    ) ? (
                                        <button
                                            className="btn float-end registration-login-btn"
                                            disabled
                                        >
                                            Confirm
                                        </button>
                                    ) : (
                                        <button
                                            className="btn float-end registration-login-btn"
                                            onClick={onclickNext}
                                        >
                                            Confirm
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default InvalidPayeeDetailsModal;

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setIsTransfersPopupVisble } from "../UserDashboard/userDashboardSlice";
import {
    setActiveFormId,
    blankManuallyAddedPayeeDetailsList,
    resetUploadedPayeeDetailsList,
    setActiveUuid,
    resetManuallyAddedPayeeDetailsList,
    setIsManuallyAddedPayees,
} from "../FormsModal/FormSlice";
import uuid from "react-uuid";
function Transfers() {
    const { isShowLoader } = useSelector((state) => state.formSlice);

    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(setIsTransfersPopupVisble(false));
    };

    const onClickUpload = (e) => {
        dispatch(setActiveFormId("1"));
        dispatch(blankManuallyAddedPayeeDetailsList());
        dispatch(setIsTransfersPopupVisble(""));
        dispatch(setIsManuallyAddedPayees(false));
    };
    const onClickManually = (e) => {
        dispatch(resetUploadedPayeeDetailsList());
        dispatch(resetManuallyAddedPayeeDetailsList());
        dispatch(setActiveFormId("2"));
        dispatch(setActiveUuid(uuid()));
        localStorage.removeItem("csvName");
        dispatch(setIsTransfersPopupVisble(""));
        dispatch(setIsManuallyAddedPayees(true));
    };
    return (
        <React.Fragment>
            {/* Loader */}
            <div class={isShowLoader ? "lds-dual-ring" : ""}></div>

            <div
                className="modal fade show d-block all-modals-common-class"
                aria-hidden="true"
                tabIndex="-1"
            >
                <div className="modal-dialog">
                    <div className="modal-content" style={{ padding: "45px 40px" }}>
                        <div className="modal-close-btn" onClick={handleClose}>
                            <img src="images/close.png" alt="close-icon" />
                        </div>
                        <div className="modal-header row justify-content-start align-items-start mb-4">
                            <div className="col-12 d-flex align-items-center">
                                <span>
                                    <img
                                        src="images/transfer.png"
                                        alt=""
                                        className="login-user-icon"
                                    />
                                </span>
                                <h2 className="h2 m-0 fw-bold">New Bulk Transfer</h2>
                            </div>

                            <div className="col-12 ">
                                <h6 className="h6 ms-5">
                                    How do you want to submit your payee details?
                                </h6>
                            </div>
                        </div>

                        <div className="modal-footer ">
                            <div className="row w-100 gy-2">
                                <div className="col-12">
                                    <button
                                        className="btn registration-login-btn"
                                        style={{ fontSize: "80%" }}
                                        onClick={onClickUpload}
                                    >
                                        {" "}
                                        Upload CSV, Text, xls or xlsx File{" "}
                                    </button>
                                </div>
                                <div className="col-12">
                                    <button
                                        className="btn registration-login-btn "
                                        onClick={onClickManually}
                                    >
                                        {" "}
                                        Enter Manually{" "}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Transfers;

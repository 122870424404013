import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    setActiveFormId,
    setProceedPayment,
    generateReferenceId,
    setCurrentTime,
    setEmailContent,
    setStatusForPayees,
    setCulipaFees,
    initiateAdminPayment,
    showAdminCodeError,
    setFinalPayableAmount,
    setMobileMoneyFee,
    setUpdatedVerifiedPayeeDetailsList,
    uploadPayee,
} from "./FormSlice";
import { culipaFormatter } from "../../culipaUtils/culipaFormatter";
import PaymentOption from "./PaymentOption";

const PaymentDetailsModal = React.memo(() => {
    const {
        userReference,
        activeUuid,
        isShowLoader,
        payeeTotalCalculation,
        payeeUpdatedCalculation,
        currentTime,
        withdrawToggle,
        verifiedPayeeDetailsList,
        payingOption,
        referenceId,
        culipaFees,
        adminCode,
        updatedCulipaFees,
        totalTax,
        updatedTax,
        bankCollectionFees,
        defaultCalculationMode,
        totalMobileMoneyWithFee,
        mobileMoneyFee,
        updatedVerifiedPayeeDetailsList,
    } = useSelector((state) => state.formSlice);
    const { selectedUserEmail, user } = useSelector(
        (state) => state.userDashboardSlice
    );
    const dispatch = useDispatch();
    const [mobileNumber, setMobileNumber] = useState();
    let mobile_money_fee_with_amount = totalMobileMoneyWithFee;
    let pay_by_bank_fee = defaultCalculationMode
        ? culipaFees.bankCollectionFees
        : bankCollectionFees;
    const totalAmount = withdrawToggle
        ? defaultCalculationMode
            ? payeeUpdatedCalculation + updatedCulipaFees.totalFees
            : payeeUpdatedCalculation + updatedTax
        : defaultCalculationMode
            ? payeeTotalCalculation + culipaFees.totalFees
            : payeeTotalCalculation + totalTax;
    let mobileFeeToShow =
        mobile_money_fee_with_amount != 0 &&
            mobile_money_fee_with_amount !== "" &&
            mobile_money_fee_with_amount !== undefined &&
            mobile_money_fee_with_amount !== NaN
            ? mobile_money_fee_with_amount
            : totalAmount * mobileMoneyFee;
    const totalAmountWithWithdrawFees =
        (payingOption == "walletOption" ? mobileFeeToShow : pay_by_bank_fee) +
        totalAmount;
    console.log(
        totalMobileMoneyWithFee,
        mobileFeeToShow,
        mobile_money_fee_with_amount
    );

    dispatch(setMobileMoneyFee(totalMobileMoneyWithFee));

    useEffect(() => {
        dispatch(showAdminCodeError(false));
        if (
            user != undefined &&
            user.mobileNo != undefined &&
            user.mobileNo.length > 0
        ) {
            setMobileNumber(user.mobileNo.replace(/[\s+]/g, ""));
        }
        dispatch(generateReferenceId(activeUuid));
        dispatch(setCurrentTime(formatDate(new Date())));
    }, [1]);

    const onclickBack = () => {
        dispatch(setActiveFormId("4"));
    };

    const emailContent = {
        referenceIdToPass: referenceId,
        noOfPayees: verifiedPayeeDetailsList.length,
        payeeTotalCalculation: culipaFormatter.currencyFormatter.format(
            payeeTotalCalculation
        ),
        culipaTotalFees: culipaFormatter.currencyFormatter.format(
            defaultCalculationMode ? culipaFees.totalFees : totalTax
        ),
        bankCollectionFees: culipaFormatter.currencyFormatter.format(
            defaultCalculationMode
                ? culipaFees.bankCollectionFees
                : bankCollectionFees
        ),
        totalWithFees: culipaFormatter.currencyFormatter.format(
            totalAmountWithWithdrawFees
        ),
        currentTime: currentTime,
    };

    const onclickNext = () => {
        if (payingOption != "") {
            // dispatch(generateReferenceId(activeUuid));
            dispatch(setProceedPayment(true));
            dispatch(setFinalPayableAmount(totalAmountWithWithdrawFees));
            if (payingOption == "bankOption") {
                let obj = {
                    uuid: activeUuid,
                    payeeList:
                        updatedVerifiedPayeeDetailsList.length != 0
                            ? updatedVerifiedPayeeDetailsList
                            : verifiedPayeeDetailsList,
                    memo: userReference,
                    csvFileName:
                        localStorage.getItem("csvName") != null
                            ? localStorage.getItem("csvName")
                            : "MANUALLY_INSERTED",
                    emailConcatenatedString: selectedUserEmail,
                    transactionID: `${referenceId}`,
                };
                dispatch(setStatusForPayees(obj));
                dispatch(setEmailContent(emailContent));
            } else if (payingOption == "adminPayment") {
                if (adminCode.length != 44) {
                    dispatch(showAdminCodeError(true));
                } else {
                    dispatch(showAdminCodeError(false));
                    // dispatch(generateReferenceId());
                    let mobile = mobileNumber;
                    if (mobile.length === 12) {
                        mobile = mobile.slice(3);
                    }
                    let payoutList = [];
                    let payoutTransactionIds = [];
                    let referencePayoutId = "";
                    updatedVerifiedPayeeDetailsList.length != 0
                        ? updatedVerifiedPayeeDetailsList.map((payee, index) => {
                            referencePayoutId = `${referenceId}_${index + 1}`;
                            payoutTransactionIds.push(referencePayoutId);
                            payoutList.push({
                                account: payee.mobileNo.toString().substring(3),
                                amount: payee.amount,
                                payeeName: payee.name,
                                transactionID: `${referencePayoutId}`,
                            });
                        })
                        : verifiedPayeeDetailsList.map((payee, index) => {
                            referencePayoutId = `${referenceId}_${index + 1}`;
                            payoutTransactionIds.push(referencePayoutId);
                            payoutList.push({
                                account: payee.mobileNo.toString().substring(3),
                                amount: payee.amount,
                                payeeName: payee.name,
                                transactionID: `${referencePayoutId}`,
                            });
                        });
                    const objToPass = {
                        initiatePaymentObject: {
                            batchTransactionID: `${referenceId}`,
                            currency: "UGX",
                            memo: userReference,
                            payouts: payoutList,
                        },
                        adminCode: adminCode,
                        userEmail: selectedUserEmail,
                        csvFileName:
                            localStorage.getItem("csvName") != null
                                ? localStorage.getItem("csvName")
                                : "MANUALLY_INSERTED",
                    };
                    localStorage.setItem("randomAlphaDigit", referenceId);
                    dispatch(initiateAdminPayment(objToPass));
                }
            }
        }
    };

    const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, "0");
        const minutes = String(date.getMinutes()).padStart(2, "0");
        const seconds = String(date.getSeconds()).padStart(2, "0");

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };

    const handleCloseModel = () => {
        dispatch(showAdminCodeError(false));
        dispatch(setActiveFormId(""));
    };

    const handleShowPayeeDetails = () => {
        dispatch(setActiveFormId("4"));
    };

    return (
        <React.Fragment>
            <div className={isShowLoader ? "lds-dual-ring" : ""}></div>

            <div
                className="modal fade show d-block all-modals-common-class"
                aria-hidden="true"
                tabIndex="-1"
            >
                <div className="modal-dialog modal-max-width630">
                    <div className="modal-content" style={{ padding: "45px 40px" }}>
                        <div className="modal-close-btn">
                            <img
                                src="images/close.png"
                                alt="close-icon"
                                onClick={handleCloseModel}
                            />
                        </div>
                        <div
                            className="modal-header align-items-center mb-4"
                            style={{ justifyContent: "start" }}
                        >
                            <span>
                                <img
                                    src="images/transfer.png"
                                    alt=""
                                    className="login-user-icon"
                                />
                            </span>
                            <div className="d-flex flex-column">
                                <h2 className="h2 m-0 fw-bold">Pay For Your Bulk Transfer</h2>
                            </div>
                        </div>
                        <div
                            className="modal-body"
                            style={{
                                padding: "0 5px 0 5px",
                                maxHeight: "350px",
                                overflowY: "scroll",
                            }}
                        >
                            <h5 className="modal-step-title"> Payment Method</h5>

                            <PaymentOption totalAmount={totalAmountWithWithdrawFees} />

                            <div className="w-100 mt-4 mb-3 validate-total-amount-wrapper">
                                <div className="d-flex align-items-center justify-content-between mb-3">
                                    <h4 className="modal-step-title-1 fw-bold m-0 h4">
                                        {" "}
                                        {verifiedPayeeDetailsList.length} Payees{" "}
                                    </h4>
                                    <a
                                        href="#"
                                        className="payment-details-modal-link"
                                        onClick={handleShowPayeeDetails}
                                    >
                                        {" "}
                                        <p className="fs-5 modal-step-subtitle">
                                            See Payee Details{" "}
                                        </p>
                                    </a>
                                </div>
                                <div className="d-flex align-items-center justify-content-between mb-3">
                                    <span className="fw-normal"> Reference Number: </span>
                                    <span className="fw-bold text-end"> {referenceId} </span>
                                </div>
                                <div className="d-flex align-items-center justify-content-between mb-3">
                                    <span className="fw-normal"> Total Amount to Transfer: </span>
                                    <span className="fw-bold text-end">
                                        {" "}
                                        UGX{" "}
                                        {culipaFormatter.currencyFormatter.format(
                                            withdrawToggle
                                                ? payeeUpdatedCalculation
                                                : payeeTotalCalculation
                                        )}{" "}
                                    </span>
                                </div>
                                <div className="d-flex align-items-center justify-content-between mb-3">
                                    <span className="fw-normal"> Culipa Payout Fees: </span>
                                    <span className="fw-bold text-end">
                                        {" "}
                                        {defaultCalculationMode ? "UGX" : ""}{" "}
                                        {defaultCalculationMode
                                            ? ""
                                            : culipaFormatter.currencyFormatter.format(
                                                withdrawToggle ? updatedTax : totalTax
                                            )}{" "}
                                    </span>
                                </div>
                                {defaultCalculationMode ? (
                                    withdrawToggle ? (
                                        <>
                                            <div className="d-flex align-items-center justify-content-between">
                                                <span className="fw-bold text-end">
                                                    {" "}
                                                    <small>
                                                        {" "}
                                                        500 – 50,000 :{" "}
                                                        {updatedCulipaFees != undefined &&
                                                            Object.keys(updatedCulipaFees).length != 0
                                                            ? updatedCulipaFees.stage1Fees.payeeCount
                                                            : 0}{" "}
                                                        x UGX 750
                                                    </small>
                                                </span>
                                                <span className="fw-bold text-end">
                                                    {" "}
                                                    <small>
                                                        {" "}
                                                        UGX{" "}
                                                        {culipaFormatter.currencyFormatter.format(
                                                            updatedCulipaFees != undefined &&
                                                                Object.keys(updatedCulipaFees).length != 0
                                                                ? updatedCulipaFees.stage1Fees.payeeFees
                                                                : 0
                                                        )}{" "}
                                                    </small>
                                                </span>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-between">
                                                <span className="fw-bold text-end">
                                                    {" "}
                                                    <small>
                                                        {" "}
                                                        50,001 – 500,000 :{" "}
                                                        {updatedCulipaFees != undefined &&
                                                            Object.keys(updatedCulipaFees).length != 0
                                                            ? updatedCulipaFees.stage2Fees.payeeCount
                                                            : 0}{" "}
                                                        x UGX 1,250
                                                    </small>
                                                </span>
                                                <span className="fw-bold text-end">
                                                    {" "}
                                                    <small>
                                                        {" "}
                                                        UGX{" "}
                                                        {culipaFormatter.currencyFormatter.format(
                                                            updatedCulipaFees != undefined &&
                                                                Object.keys(updatedCulipaFees).length != 0
                                                                ? updatedCulipaFees.stage2Fees.payeeFees
                                                                : 0
                                                        )}{" "}
                                                    </small>
                                                </span>
                                            </div>

                                            <div className="d-flex align-items-center justify-content-between">
                                                <span className="fw-bold text-end">
                                                    {" "}
                                                    <small>
                                                        {" "}
                                                        500,001 – 1,000,000 :{" "}
                                                        {updatedCulipaFees != undefined &&
                                                            Object.keys(updatedCulipaFees).length != 0
                                                            ? updatedCulipaFees.stage3Fees.payeeCount
                                                            : 0}{" "}
                                                        x UGX 1,750
                                                    </small>
                                                </span>
                                                <span className="fw-bold text-end">
                                                    {" "}
                                                    <small>
                                                        {" "}
                                                        UGX{" "}
                                                        {culipaFormatter.currencyFormatter.format(
                                                            updatedCulipaFees != undefined &&
                                                                Object.keys(updatedCulipaFees).length != 0
                                                                ? updatedCulipaFees.stage3Fees.payeeFees
                                                                : 0
                                                        )}{" "}
                                                    </small>
                                                </span>
                                            </div>

                                            <div className="d-flex align-items-center justify-content-between">
                                                <span className="fw-bold text-end">
                                                    {" "}
                                                    <small>
                                                        {" "}
                                                        1,000,001 – 5,000,000 :{" "}
                                                        {updatedCulipaFees != undefined &&
                                                            Object.keys(updatedCulipaFees).length != 0
                                                            ? updatedCulipaFees.stage4Fees.payeeCount
                                                            : 0}{" "}
                                                        x UGX 2,500
                                                    </small>
                                                </span>
                                                <span className="fw-bold text-end">
                                                    {" "}
                                                    <small>
                                                        {" "}
                                                        UGX{" "}
                                                        {culipaFormatter.currencyFormatter.format(
                                                            updatedCulipaFees != undefined &&
                                                                Object.keys(updatedCulipaFees).length != 0
                                                                ? updatedCulipaFees.stage4Fees.payeeFees
                                                                : 0
                                                        )}{" "}
                                                    </small>
                                                </span>
                                            </div>
                                            <br />
                                        </>
                                    ) : (
                                        <>
                                            <div className="d-flex align-items-center justify-content-between">
                                                <span className="fw-bold text-end">
                                                    {" "}
                                                    <small>
                                                        {" "}
                                                        500 – 50,000 :{" "}
                                                        {culipaFees != undefined &&
                                                            Object.keys(culipaFees).length != 0
                                                            ? culipaFees.stage1Fees.payeeCount
                                                            : 0}{" "}
                                                        x UGX 750
                                                    </small>
                                                </span>
                                                <span className="fw-bold text-end">
                                                    {" "}
                                                    <small>
                                                        {" "}
                                                        UGX{" "}
                                                        {culipaFormatter.currencyFormatter.format(
                                                            culipaFees != undefined &&
                                                                Object.keys(culipaFees).length != 0
                                                                ? culipaFees.stage1Fees.payeeFees
                                                                : 0
                                                        )}{" "}
                                                    </small>
                                                </span>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-between">
                                                <span className="fw-bold text-end">
                                                    {" "}
                                                    <small>
                                                        {" "}
                                                        50,001 – 500,000 :{" "}
                                                        {culipaFees != undefined &&
                                                            Object.keys(culipaFees).length != 0
                                                            ? culipaFees.stage2Fees.payeeCount
                                                            : 0}{" "}
                                                        x UGX 1,250
                                                    </small>
                                                </span>
                                                <span className="fw-bold text-end">
                                                    {" "}
                                                    <small>
                                                        {" "}
                                                        UGX{" "}
                                                        {culipaFormatter.currencyFormatter.format(
                                                            culipaFees != undefined &&
                                                                Object.keys(culipaFees).length != 0
                                                                ? culipaFees.stage2Fees.payeeFees
                                                                : 0
                                                        )}{" "}
                                                    </small>
                                                </span>
                                            </div>

                                            <div className="d-flex align-items-center justify-content-between">
                                                <span className="fw-bold text-end">
                                                    {" "}
                                                    <small>
                                                        {" "}
                                                        500,001 – 1,000,000 :{" "}
                                                        {culipaFees != undefined &&
                                                            Object.keys(culipaFees).length != 0
                                                            ? culipaFees.stage3Fees.payeeCount
                                                            : 0}{" "}
                                                        x UGX 1,750
                                                    </small>
                                                </span>
                                                <span className="fw-bold text-end">
                                                    {" "}
                                                    <small>
                                                        {" "}
                                                        UGX{" "}
                                                        {culipaFormatter.currencyFormatter.format(
                                                            culipaFees != undefined &&
                                                                Object.keys(culipaFees).length != 0
                                                                ? culipaFees.stage3Fees.payeeFees
                                                                : 0
                                                        )}{" "}
                                                    </small>
                                                </span>
                                            </div>

                                            <div className="d-flex align-items-center justify-content-between">
                                                <span className="fw-bold text-end">
                                                    {" "}
                                                    <small>
                                                        {" "}
                                                        1,000,001 – 5,000,000 :{" "}
                                                        {culipaFees != undefined &&
                                                            Object.keys(culipaFees).length != 0
                                                            ? culipaFees.stage4Fees.payeeCount
                                                            : 0}{" "}
                                                        x UGX 2,500
                                                    </small>
                                                </span>
                                                <span className="fw-bold text-end">
                                                    {" "}
                                                    <small>
                                                        {" "}
                                                        UGX{" "}
                                                        {culipaFormatter.currencyFormatter.format(
                                                            culipaFees != undefined &&
                                                                Object.keys(culipaFees).length != 0
                                                                ? culipaFees.stage4Fees.payeeFees
                                                                : 0
                                                        )}{" "}
                                                    </small>
                                                </span>
                                            </div>
                                            <br />
                                        </>
                                    )
                                ) : (
                                    ""
                                )}
                                {payingOption == "walletOption" ? (
                                    <div className="d-flex align-items-center justify-content-between mb-3">
                                        <span className="fw-normal">
                                            {" "}
                                            Mobile Money Collection Fees:{" "}
                                        </span>
                                        <span className="fw-bold text-end">
                                            {" "}
                                            UGX{" "}
                                            {culipaFormatter.currencyFormatter.format(
                                                mobileFeeToShow
                                            )}{" "}
                                        </span>
                                    </div>
                                ) : (
                                    <div className="d-flex align-items-center justify-content-between mb-3">
                                        <span className="fw-normal"> Bank Collection Fees: </span>
                                        <span className="fw-bold text-end">
                                            {" "}
                                            UGX{" "}
                                            {culipaFormatter.currencyFormatter.format(
                                                pay_by_bank_fee
                                            )}{" "}
                                        </span>
                                    </div>
                                )}
                            </div>
                            <hr className="payment-details-footer-border my-4" />
                            <div className="w-100 mt-4 mb-3 validate-total-amount-wrapper">
                                <div className="d-flex gx-2 align-items-center justify-content-between mb-3">
                                    <h4 className="modal-step-title-1 fw-bold h4 mb-0">
                                        {" "}
                                        TOTAL:{" "}
                                    </h4>
                                    <span className="fs-4 modal-step-title-1 fw-bold">
                                        {" "}
                                        UGX{" "}
                                        {culipaFormatter.currencyFormatter.format(
                                            totalAmountWithWithdrawFees
                                        )}{" "}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="row p-1 w-100">
                                <div className="col-5">
                                    <button
                                        className="btn float-start registration-next-btn back-black-btn"
                                        onClick={onclickBack}
                                    >
                                        Back
                                    </button>
                                </div>
                                <div className="col-7">
                                    <button
                                        className="btn float-end registration-login-btn"
                                        onClick={onclickNext}
                                    >
                                        Pay & Send
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
});

export default PaymentDetailsModal;

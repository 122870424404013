import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setActiveRegistrationStep } from "../Registration/RegistrationSlice";
import {
    getUserDetail,
    getPayeeDetails,
    getInitiateDetails,
    setOpenModel,
    setBulkTransferReferenceId,
    getUsersListToDisplay,
    initiateDetailsForUser,
    openConfirmationPopUp,
    setCurrentUserEmail,
    currentLoggedInUser,
} from "./userDashboardSlice";
import { setUserReference } from "../FormsModal/FormSlice";
import "./userDashboard.css";
import UserDashboardHeader from "../../components/Header/UserDashboardHeader";
import UserDashBoardFooter from "../../components/Footer/UserDashboardFooter";
import ValidatedPayeeDetailsModal from "../FormsModal/ValidatedPayeeDetailsModal";
import PaymentDetailsModal from "../FormsModal/PaymentDetailsModal";
import CheckoutModal from "../FormsModal/CheckoutModal";
import BankPdfInstruction from "../FormsModal/BankPdfInstruction";
import { dateFormatter } from "../../culipaUtils/culipaUtils";
import PayeeDetails from "./PayeeDetails";
import { culipaFormatter } from "../../culipaUtils/culipaFormatter";
import Transfers from "./Transfers";
import UploadModal from "../FormsModal/UploadModal";
import ManualEntryFormModal from "../FormsModal/ManualEntryFormModal";
import InvalidPayeeDetailsModal from "../FormsModal/InvalidPayeeDetailsModal";
import { ToastContainer } from "react-toastify";
import Select from "react-select";
import AdminCode from "./AdminCode";

function UserDashboard() {
    const { users } = useSelector((state) => state.userDashboardSlice);
    const { user } = useSelector((state) => state.userDashboardSlice);
    const dispatch = useDispatch();

    const {
        activeFormId,
        payingOption,
        proceedPayment,
        isShowLoader,
        verifiedPayeeDetailsList,
    } = useSelector((state) => state.formSlice);
    const {
        selectedUserEmail,
        initiateDetails,
        openModel,
        confirmationPopUp,
        isTransfersPopupVisble,
        initiatePaymentDetails,
    } = useSelector((state) => state.userDashboardSlice);

    const [selectedOptions, setSelectedOptions] = useState();
    const [referenceIdToPass, setReferenceIdToPass] = useState();

    useEffect(() => {
        dispatch(setUserReference(""));
        dispatch(currentLoggedInUser());
        dispatch(getUserDetail());
        dispatch(getInitiateDetails());
        dispatch(getUsersListToDisplay());
        if (
            selectedUserEmail != undefined &&
            selectedUserEmail != null &&
            selectedUserEmail != ""
        ) {
            dispatch(initiateDetailsForUser(encodeURIComponent(selectedUserEmail)));
        }
    }, [1]);

    const handleSelect = (data) => {
        setSelectedOptions(data);
        dispatch(setCurrentUserEmail(data.value));
        dispatch(initiateDetailsForUser(encodeURIComponent(data.value)));
    };

    const onClickMannual = () => {
        dispatch(setActiveRegistrationStep("1"));
    };

    const checkStatus = (status) => {
        if (status === "SETTLED") {
            return (
                <button className="btn trnasfer-done-btn" style={{ width: "auto" }}>
                    {" "}
                    <img src="images/check-mark.png" alt="" /> COMPLETED{" "}
                </button>
            );
        } else if (status === "RECEIVED") {
            return (
                <button className="btn trnasfer-done-btn">
                    {" "}
                    <img src="images/check-mark.png" alt="" /> {status}{" "}
                </button>
            );
        } else if (status === "In Progress") {
            return (
                <button
                    className="btn trnasfer-inprogress-btn"
                    style={{ width: "auto" }}
                >
                    {" "}
                    <img src="images/time-left.png" alt="" /> IN PROGRESS{" "}
                </button>
            );
        } else if (status === "PARTIALLY COMPLETED") {
            return (
                <button
                    className="btn trnasfer-inprogress-btn"
                    style={{ backgroundColor: "#FFA500", width: "auto" }}
                >
                    {" "}
                    <img src="images/time-left.png" alt="" /> COMPLETED{" "}
                </button>
            );
        } else if (status === "PENDING") {
            return (
                <button className="btn trnasfer-inprogress-btn">
                    {" "}
                    <img src="images/time-left.png" alt="" /> PENDING{" "}
                </button>
            );
        } else if (status === "NOT STARTED") {
            return (
                <button
                    className="btn trnasfer-inprogress-btn"
                    style={{ backgroundColor: "#ffc107", width: "auto" }}
                >
                    {" "}
                    <img src="images/time-left.png" alt="" />
                    {status}{" "}
                </button>
            );
        } else {
            return (
                <button className="btn trnasfer-cancel-btn" style={{ width: "auto" }}>
                    {" "}
                    <img src="images/cancel.png" alt="" /> FAILED
                </button>
            );
        }
    };

    const fetchPayeeDetails = (initiatePayment) => {
        dispatch(setOpenModel(true));
        dispatch(getPayeeDetails(initiatePayment.id));
        dispatch(setBulkTransferReferenceId(initiatePayment.transactionID));
    };

    const askForConfirmation = (status, transactionID, id, memo) => {
        if (status == "PENDING" && localStorage.getItem("Role") == "Admin") {
            localStorage.setItem("memo", memo);
            dispatch(getPayeeDetails(id));
            setReferenceIdToPass(transactionID);
            dispatch(openConfirmationPopUp(true));
        }
    };

    return (
        <section className="">
            <UserDashboardHeader />
            <ToastContainer />
            <main className="banner-container-text-box inner-content-page-bg">
                {/* Loader */}
                <div class={isShowLoader ? "lds-dual-ring" : ""}></div>
                <div className="container userdashboard-container">
                    <div className="row">
                        <div className="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
                            <div className="userdashboard-heading">
                                <h2 className="text-center text-sm-start"> My Transfers </h2>
                                <p className="text-center text-sm-start">
                                    {" "}
                                    {initiatePaymentDetails.totalPayout} payouts totalling UGX{" "}
                                    {culipaFormatter.currencyFormatter.format(
                                        initiatePaymentDetails.totalAmount
                                    )}{" "}
                                </p>
                            </div>
                        </div>
                        <div className="col-6 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                            <div className="userdashboard-heading text-center">
                                <h2> {initiatePaymentDetails.totalBulkPayTranser} </h2>
                                <p className="fw-normal"> Bulk Transfers </p>
                            </div>
                        </div>
                        <div className="col-6 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                            <div className="userdashboard-heading text-center">
                                <h2> {initiatePaymentDetails.inprogressCount} </h2>
                                <p className="fw-normal"> In Progress </p>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <div className="container">
                <div className="row mt-2 justify-content-center align-items-center ">
                    <div
                        className="col-12"
                        style={{
                            display: localStorage.getItem("Role") != "Admin" ? "none" : "",
                        }}
                    >
                        <div className="row m-1 justify-content-center align-items-center">
                            <div className="col-12 col-sm-3 col-md-2 text-center text-sm-end">
                                <h5 style={{ marginRight: "15px" }}> View As : </h5>
                            </div>

                            <div className="col-12 col-sm-5 col-md-4">
                                <Select
                                    options={users}
                                    placeholder={user.email}
                                    value={selectedOptions}
                                    onChange={handleSelect}
                                    isSearchable={true}
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                // isMulti
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <main
                className="banner-container-text-box p-0 mb-5"
                style={{ paddingTop: "40px" }}
            >
                <div className="container userdashboard-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div
                                className="table-responsive userdashboard-transfer-list mt-5 py-0"
                                style={{ maxHeight: "350px" }}
                            >
                                <table className="table table-borderless">
                                    <thead>
                                        <tr>
                                            <th className="userdasboard-name-thead"> Transfer </th>
                                            <th className="userdasboard-payees-thead"> Payees </th>
                                            <th className="userdasboard-payees-thead"> Reference </th>
                                            <th className="userdasboard-status-thead"> Status </th>
                                            <th className="userdasboard-date-thead"> Date & Time </th>
                                            <th className="userdasboard-amount-thead">
                                                {" "}
                                                Amount (UGX){" "}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.keys(initiateDetails).map((key, id) => {
                                            let initiateDetail = initiateDetails[key];
                                            return (
                                                <tr key={id}>
                                                    <td
                                                        onClick={(e) => {
                                                            fetchPayeeDetails(initiateDetail);
                                                        }}
                                                        style={{ cursor: "pointer" }}
                                                    >
                                                        {" "}
                                                        {initiateDetail.transactionID}{" "}
                                                    </td>
                                                    <td
                                                        onClick={(e) => {
                                                            fetchPayeeDetails(initiateDetail);
                                                        }}
                                                        style={{ cursor: "pointer" }}
                                                    >
                                                        {" "}
                                                        {initiateDetail !== undefined
                                                            ? initiateDetail.payeeCount
                                                            : 0}{" "}
                                                    </td>
                                                    <td>{initiateDetail.memo}</td>
                                                    <td
                                                        onClick={(e) => {
                                                            askForConfirmation(
                                                                initiateDetail.status,
                                                                initiateDetail.transactionID,
                                                                initiateDetail.id,
                                                                initiateDetail.memo
                                                            );
                                                        }}
                                                        style={
                                                            initiateDetail.status === "PENDING"
                                                                ? { cursor: "pointer" }
                                                                : {}
                                                        }
                                                    >
                                                        {" "}
                                                        {checkStatus(initiateDetail.status)}
                                                    </td>
                                                    <td>
                                                        <span className="user-transfer-date">
                                                            {" "}
                                                            {dateFormatter(initiateDetail.time)}{" "}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        {culipaFormatter.currencyFormatter.format(
                                                            initiateDetail.amount
                                                        )}{" "}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <UserDashBoardFooter />
            {activeFormId === "4" ? <ValidatedPayeeDetailsModal /> : null}
            {activeFormId === "5" &&
                verifiedPayeeDetailsList !== undefined &&
                verifiedPayeeDetailsList.length !== 0 ? (
                <PaymentDetailsModal />
            ) : null}
            {payingOption === "walletOption" && proceedPayment ? (
                <CheckoutModal />
            ) : null}
            {payingOption === "bankOption" && proceedPayment ? (
                <BankPdfInstruction />
            ) : null}
            {openModel ? <PayeeDetails /> : null}
            {isTransfersPopupVisble ? <Transfers /> : null}
            {activeFormId === "1" ? <UploadModal /> : null}
            {activeFormId === "2" ? <ManualEntryFormModal /> : null}
            {activeFormId === "3" ? <InvalidPayeeDetailsModal /> : null}
            {confirmationPopUp ? <AdminCode referenceId={referenceIdToPass} /> : null}
        </section>
    );
}

export default UserDashboard;

import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    isShowLoader:false,
    activeFormId: '',
    file: {},
    activeUuid: '',
    formErrorMsg: '',
    uploadedPayeeDetailsList: [],
    validatedPayeeDetailsList: [],
    payeeTotalCalculation: 0,
    updatedPayeeTotalCalculation: 0,
    payeeUpdatedCalculation:0,
    verifiedPayeeDetailsList: [],
    updatedVerifiedPayeeDetailsList:[],
    payingOption:"",
    randomAlphaDigit:0,
    proceedPayment:false,
    manuallyAddedPayeeDetailsList:new Array(1).fill({id:"",name:"",mobileNo:0,amount:0,userId:null}),
    manuallyAddedPayeeDetailsListTemp:new Array(1).fill({id:"",name:"",mobileNo:0,amount:0,userId:null}),
    totalPayeeAmountAfterApplyingSurcharge:0,
    referenceId:'',
    culipaFees: {},
    updatedCulipaFees: {},
    isPaymentComplete : false,
    isHideConfirmationModelButton : false,
    totalPayeeTransactions:{},
    userReference:"",
    selectedCountryCode:0,
    selectedCountryObject:{},
    isManuallyAddedPayees:false,
    adminCode:"",
    currentTime:"",
    adminCodeError:false,
    withdrawToggle: false,
    finalPayableAmount: 0,
    finalWithdrawalAmount: 0,
    disbursementAmountRanges: {},
    totalTax: 0,
    updatedTax: 0,
    bankCollectionFees: 5000,
    defaultCalculationMode: true,
    totalMobileMoneyWithFee: 0.0,
    payeeAmountToCalculateMobileFee: 0,
    mobileMoneyFee:0
}

const formSlice = createSlice({
    name: "formSlice",
    initialState,
    reducers: {
        setActiveFormId(state, action) {
            state.activeFormId = action.payload;
        },
        setIsManuallyAddedPayees(state, action) {
            state.isManuallyAddedPayees = action.payload;
        },
        setSelectedCountryObject(state, action) {
            state.selectedCountryObject = action.payload;
        },
        resetSelectedCountryObject(state, action) {
            state.selectedCountryObject = {};
        },
        setSelectedCountryCode(state, action) {
            state.selectedCountryCode = action.payload;
        },
        setUserReference(state, action) {
            state.userReference = action.payload;
        },
        setTotalPayeeTransactions(state,action){
            state.totalPayeeTransactions = action.payload;
        },
        setIsHideConfirmationModelButton(state,action){
            state.isHideConfirmationModelButton = action.payload
        },
        setIsPaymentComplete(state,action){
            state.isPaymentComplete = action.payload;
        },
        setFile(state, action) {
            state.file = action.payload;
        },
        setActiveUuid(state, action) {
            state.activeUuid = action.payload;
        },
        setFormErrorMsg(state, action) {
            state.formErrorMsg = action.payload;
        },
        setUploadedPayeeDetailsList (state, action){
            state.uploadedPayeeDetailsList = action.payload
        },
        setValidatedPayeeDetailsList (state,action) {
            state.validatedPayeeDetailsList = action.payload
        },
        setShowLoader(state, action){
            state.isShowLoader =action.payload
        },
        setPayeeTotalCalculation (state,action) {
            state.payeeTotalCalculation = action.payload
        },
        setUpdatedPayeeTotalCalculation(state, action) {
            state.updatedPayeeTotalCalculation = action.payload
        },
        setPayeeUpdatedCalculation(state,action){
            state.payeeUpdatedCalculation = action.payload
        },
        setVerifiedPayeeDetailsList(state,action) {
            state.verifiedPayeeDetailsList = action.payload
        },
        setUpdatedVerifiedPayeeDetailsList(state,action) {
            state.updatedVerifiedPayeeDetailsList = action.payload
        },
        setPayingOptions(state,action){
            state.payingOption = action.payload;
        },
        setRandomAlphaDigit(state,action){
            state.randomAlphaDigit = action.payload;
        },
        setProceedPayment(state,action){
            state.proceedPayment = action.payload;
        },
        setManuallyAddedPayeeDetailsListAtIndex(state,action) {
            state.manuallyAddedPayeeDetailsList[action.payload.id]=action.payload;
        },
        setManuallyAddedPayeeDetailsList(state,action){
            state.manuallyAddedPayeeDetailsList=action.payload;
        },
        addMorePayee(state, action){
          let blankObject =  { id: state.manuallyAddedPayeeDetailsList.length, fullName: "", mobile: 0, amount: 0, status: null, isEditable: false }
            state.manuallyAddedPayeeDetailsList.push(blankObject);
        },
        resetUploadedPayeeDetailsList(state,action){
            return {...state,uploadedPayeeDetailsList:[]};
        },
        resetverifiedPayeeDetailsList(state,action){
            return {...state,verifiedPayeeDetailsList:[]};
        },
        resetManuallyAddedPayeeDetailsList(state,action){
            return {...state,manuallyAddedPayeeDetailsList:new Array(1).fill({id:"",fullName:"",mobile:0,amount:0,status:null,isEditable:false,userId:state.activeUuid})};
        },
        blankManuallyAddedPayeeDetailsList(state,action){
            return {...state,manuallyAddedPayeeDetailsList:[]};
        },
        updateUuidInManuallyAddedPayeeList(state,action){
            let uuid = action.payload;
            for(let i =0 ;i < state.manuallyAddedPayeeDetailsList.length ; i ++){
                state.manuallyAddedPayeeDetailsList[i].userId = uuid;
            }
        },
        totalPayeeAmountAfterApplyingSurcharge(state,action){
            state.totalPayeeAmountAfterApplyingSurcharge = action.payload;
        },
        setCulipaFees(state,action) {
            state.culipaFees = action.payload;
        },
        setUpdatedCulipaFees (state,action){
            state.updatedCulipaFees = action.payload;
        },
        setAdminCode (state, action) {
            state.adminCode = action.payload;
        },
        setCurrentTime (state, action) {
            state.currentTime = action.payload;
        },
        setWithDrawToggle (state, action) {
            state.withdrawToggle = action.payload;
        },
        setFinalPayableAmount (state, action) {
            state.finalPayableAmount = action.payload;
        },
        setFinalWithdrawalAmount (state, action) {
            state.finalWithdrawalAmount = action.payload;
        },
        setDisbursementAmountRanges (state, action) {
            state.disbursementAmountRanges = action.payload;
        },
        setTotalTax (state,  action) {
            state.totalTax = action.payload
        },
        setUpdatedTax (state, action) {
            state.updatedTax = action.payload
        },
        setBankCollectionFees (state, action) {
            state.bankCollectionFees = action.payload;
        },
        setDefaultCalculationMode (state, action) {
            state.defaultCalculationMode = action.payload;
        },
        setMobileMoneyFee (state, action) {
            state.mobileMoneyFee = action.payload;
        },
        setTotalMobileMoneyWithFee (state, action) {
            state.totalMobileMoneyWithFee = action.payload;
        },
        setPayeeAmountToCalculateMobileFee (state, action) {
            state.payeeAmountToCalculateMobileFee = 
                state.withdrawToggle ? parseInt(state.payeeUpdatedCalculation) + parseInt(state.updatedTax) : 
                    parseInt(state.payeeTotalCalculation) + parseInt(state.totalTax);
        },

        // Method call..

        uploadFile(state, uploadObjRequest) { },
        getUploadFileDataByUuid(uuid) { },
        validatePayeeDetails(obj) {}, 
        updateObjWithInvalidStatus(state, obj) {
            state.file = {}
        },
        deleteThePayeeById(id) {},
        uploadPayee(payeeList,uuid){},
        initiatePayments(initiatePaymentRequestPayload){},
        generateReferenceId(uuid){},
        setReferenceId(state,action){
            state.referenceId = action.payload;
        },
        setEmailContent (formData) {},
        setStatusForPayees (payeeListWithUuid) {},
        initiateAdminPayment(initiateAdminPaymentRequestPayload){},
        initiatePayByBank(initiatePayByBankRequestPayload){},
        showAdminCodeError(state, action) {
            state.adminCodeError = action.payload;
        },
        deleteThePayeeObjById(reqObj){},
        removeThePayee(state, action) {
            state.manuallyAddedPayeeDetailsListTemp = state.manuallyAddedPayeeDetailsList;
            state.manuallyAddedPayeeDetailsList = [];
            for (let i = 0; i < state.manuallyAddedPayeeDetailsListTemp.length; i++) {
                if (state.manuallyAddedPayeeDetailsListTemp.at(i).id != action.payload) {
                    state.manuallyAddedPayeeDetailsList.push(state.manuallyAddedPayeeDetailsListTemp.at(i));
                }   
            }
        },
        checkCulipaUserByEmail(email){},
        checkCulipaUserByMobile(mobile){}
    }
});

export const {
    setActiveFormId,
    setFile,
    uploadFile,
    setActiveUuid,
    getUploadFileDataByUuid,
    setFormErrorMsg,
    setUploadedPayeeDetailsList,
    validatePayeeDetails,
    setValidatedPayeeDetailsList,
    updateObjWithInvalidStatus,
    deleteThePayeeById,
    uploadPayee,
    setPayeeTotalCalculation,
    setUpdatedPayeeTotalCalculation,
    setPayeeUpdatedCalculation,
    setShowLoader,
    setPayingOptions,
    setVerifiedPayeeDetailsList,
    setRandomAlphaDigit,
    addMorePayee,
    setManuallyAddedPayeeDetailsListAtIndex,
    resetUploadedPayeeDetailsList,
    resetManuallyAddedPayeeDetailsList,
    initiatePayments,
    setProceedPayment,
    randomAlphaDigit,
    resetverifiedPayeeDetailsList,
    blankManuallyAddedPayeeDetailsList,
    generateReferenceId,
    setReferenceId,
    setIsPaymentComplete,
    setIsHideConfirmationModelButton,
    setTotalPayeeTransactions,
    setCulipaFees,
    setUpdatedCulipaFees,
    setUserReference,
    setSelectedCountryCode,
    setSelectedCountryObject,
    resetSelectedCountryObject,
    updateUuidInManuallyAddedPayeeList,
    setManuallyAddedPayeeDetailsList,
    setIsManuallyAddedPayees,
    file,
    setAdminCode,
    setCurrentTime,
    setEmailContent,
    setStatusForPayees,
    initiateAdminPayment,
    initiatePayByBank,
    showAdminCodeError,
    removeThePayee,
    deleteThePayeeObjById,
    checkCulipaUserByEmail,
    checkCulipaUserByMobile,
    setWithDrawToggle,
    setFinalPayableAmount,
    setFinalWithdrawalAmount,
    setTotalTax,
    setUpdatedTax,
    setDefaultCalculationMode,
    totalTax,
    updatedTax,
    payeeTotalCalculation,
    withdrawToggle,
    payeeUpdatedCalculation,
    setMobileMoneyFee,
    setPayeeAmountToCalculateMobileFee,
    payeeAmountToCalculateMobileFee,
    setTotalMobileMoneyWithFee,
    totalMobileMoneyWithFee,
    mobileMoneyFee,
    setUpdatedVerifiedPayeeDetailsList,
    updatedVerifiedPayeeDetailsList
} = formSlice.actions;
export default formSlice.reducer;
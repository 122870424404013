import React, { useEffect, useState } from "react";
import { openConfirmationPopUp } from "./userDashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import {
    generateReferenceId,
    initiatePayByBank,
    setActiveFormId,
    showAdminCodeError,
} from "../FormsModal/FormSlice";

function AdminCode(props) {
    const { selectedUserEmail, user, payeeDetails } = useSelector(
        (state) => state.userDashboardSlice
    );
    const {
        adminCodeError,
        userReference,
        verifiedPayeeDetailsList,
        referenceId,
    } = useSelector((state) => state.formSlice);
    const dispatch = useDispatch();
    const [adminPayBox, setAdminPayBox] = useState(Boolean);
    const [adminCode, setAdminCode] = useState("");
    const [mobileNumber, setMobileNumber] = useState();

    useEffect(() => {
        if (
            user != undefined &&
            user.mobileNo != undefined &&
            user.mobileNo.length > 0
        ) {
            setMobileNumber(user.mobileNo.replace(/[\s+]/g, ""));
        }
    }, [1]);

    const handleCloseModel = () => {
        dispatch(showAdminCodeError(false));
        dispatch(openConfirmationPopUp(false));
        dispatch(setActiveFormId(""));
    };

    const handleOnClick = () => {
        setAdminPayBox(true);
    };

    const handleOnChange = (event) => {
        let adminCodeToSet = event.target.value;
        setAdminCode(adminCodeToSet);
    };

    const handleOnSubmit = () => {
        if (adminCode.length != 44) {
            dispatch(showAdminCodeError(true));
        } else {
            dispatch(showAdminCodeError(false));
            dispatch(generateReferenceId());
            let mobile = mobileNumber;
            if (mobile.length === 12) {
                mobile = mobile.slice(3);
            }
            let payoutList = [];
            let payoutTransactionIds = [];
            let referencePayoutId = "";
            payeeDetails.map((payee, index) => {
                referencePayoutId = `${props.referenceId}_${index + 1}`;
                payoutTransactionIds.push(referencePayoutId);
                payoutList.push({
                    account: payee.account.toString().substring(3),
                    amount: payee.amount,
                    payeeName: payee.payeeName,
                    transactionID: `${referencePayoutId}`,
                });
            });
            const objToPass = {
                initiatePaymentObject: {
                    batchTransactionID: props.referenceId,
                    currency: "UGX",
                    memo: localStorage.getItem("memo"),
                    payouts: payoutList,
                },
                adminCode: adminCode,
                userEmail: selectedUserEmail,
            };
            localStorage.setItem("randomAlphaDigit", referenceId);
            dispatch(initiatePayByBank(objToPass));
            setAdminPayBox(false);
            dispatch(openConfirmationPopUp(false));
        }
    };

    return (
        <React.Fragment>
            {/* Loader */}

            <div
                className="modal fade show d-block all-modals-common-class"
                aria-hidden="true"
                tabIndex="-1"
            >
                <div className="modal-dialog">
                    <div className="modal-content" style={{ padding: "45px 40px 10px" }}>
                        <div className="modal-close-btn">
                            <img
                                src="images/close.png"
                                alt="close-icon"
                                onClick={handleCloseModel}
                            />
                        </div>
                        <div className="modal-header justify-content-start align-items-center mb-4">
                            <span>
                                <img
                                    src="images/transfer.png"
                                    alt=""
                                    className="login-user-icon"
                                />
                            </span>
                            <div className="d-flex flex-column">
                                <h2 className="modal-title fw-bold h2">Admin Payment</h2>
                            </div>
                        </div>
                        <div
                            style={{
                                paddingTop: "20px",
                                paddingBottom: "30px",
                                textAlign: "center",
                                cursor: "pointer",
                            }}
                        >
                            <h6 onClick={handleOnClick} className="modal-title">
                                Click to Complete
                            </h6>
                        </div>
                        {adminPayBox ? (
                            <div style={{ textAlign: "center" }}>
                                <div style={{ padding: "0 0 20px 0" }}>
                                    <input
                                        style={{ borderRadius: "12px", padding: "6px 12px" }}
                                        onChange={handleOnChange}
                                        value={adminCode}
                                    ></input>
                                    <br />
                                    {adminCodeError ? (
                                        <span className="error-message-admin-code">
                                            {" "}
                                            {"Code should be 44 characters long."}
                                        </span>
                                    ) : null}
                                </div>
                                <div className="w-100" style={{ paddingBottom: "30px" }}>
                                    <button
                                        onClick={handleOnSubmit}
                                        className="btn registration-login-btn"
                                        style={{ minWidth: "45%" }}
                                    >
                                        {" "}
                                        Pay & Submit{" "}
                                    </button>
                                </div>{" "}
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default AdminCode;

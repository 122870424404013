import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user:{},
  selectedUser: {},
  payeeDetails:[],
  initiateDetails:[],
  openModel:false,
  isTransfersPopupVisble:false,
  initiatePaymentDetails : {},
  bulkTransferReferenceId:'',
  currentPaymentTransactionId:"",
  payoutTransactionIds:[],
  users:[],
  trackingPayout:new Map(),
  confirmationPopUp:false,
  selectedUserEmail:""
};

const userDashboardSlice = createSlice({
  name: "userDashboard",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload
    },
    setPayeeDetailsList:(state,action)=>{
      state.payeeDetails = action.payload;
    },
    setInitiateDetails:(state,action)=>{
      state.initiateDetails = action.payload;
    },
    setOpenModel:(state,action)=>{
      state.openModel = action.payload;
    },
    setIsTransfersPopupVisble: (state, action) => {
      state.isTransfersPopupVisble = action.payload
    },
    setInitiatePaymentDetails:(state,action)=>{
      state.initiatePaymentDetails = action.payload;
    },
    setInitiatePaymentDetailsAtKey:(state,action)=>{
      let key = action.payload.key;
      let value = action.payload.value;
      state.initiatePaymentDetails[key] = value
    },
    setBulkTransferReferenceId:(state,action)=>{
      state.bulkTransferReferenceId = action.payload;
    },
    updateInitateDetails:(state,action)=>{
      console.log(state, "state hereeeee");
      console.log(action.payload.updatedDetails.status, "action status hereeeee");
      
      let updatedDetails = action.payload.updatedDetails.status;

      Object.keys(updatedDetails).map((keys,id) => {
        state.initiateDetails[keys] = updatedDetails;
      })
    },

    setPayoutTransactionIds:(state,action)=>{
     state.payoutTransactionIds = action.payload;
    },

    setCurrentPaymentTransactionId:(state,action)=>{
      state.currentPaymentTransactionId = action.payload;
    },
    setTrackingStatusMap:(state,action)=>{
      let key = action.payload.key;
      let latestStatus = action.payload.value;
      console.log("setTrackingStatusMap",latestStatus);
      state.trackingPayout.set(key,latestStatus);
    },
    setUsers: (state, action) => {
      state.users = action.payload;
    },
    setSelectedUser: (state, action) => {
      state.selectedUser = action.payload;
    },
    openConfirmationPopUp: (state, action) => {
      state.confirmationPopUp = action.payload;
    },
    handleWebsocketCallbackResponse(state,action){},
    getUserDetail(state, action) { },
    getPayeeDetails(state, action) {},
    getInitiateDetails(state, action) {},
    updateCsvName(state, action) {},
    checkTransactionIdExistOrNot(state, action) {},
    getUsersListToDisplay(state, action) {},
    initiateDetailsForUser(state, action) {},
    setCurrentUserEmail: (state, action) => {
      // action.payload here is a concatenation of [email + " " + firstname + " " + lastname].
      state.selectedUserEmail = action.payload;
    },
    currentLoggedInUser:(state, action) => {}
  },
});

export const {setUser, getUserDetail,getPayeeDetails,setPayeeDetailsList,getInitiateDetails,setInitiateDetails,setOpenModel,updateCsvName,checkTransactionIdExistOrNot,setIsTransfersPopupVisble,setInitiatePaymentDetails,setBulkTransferReferenceId
,handleWebsocketCallbackResponse , updateInitiateDetails ,initiateDetails ,updateInitateDetails ,setCurrentPaymentTransactionId ,setInitiatePaymentDetailsAtKey , setPayoutTransactionIds , setTrackingStatusMap, setUsers, getUsersListToDisplay, initiateDetailsForUser
,setSelectedUser, openConfirmationPopUp, setCurrentUserEmail, currentLoggedInUser} = userDashboardSlice.actions;
export default userDashboardSlice.reducer;
import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    setAdminCode,
    setPayingOptions,
    setProceedPayment,
    showAdminCodeError,
} from "./FormSlice";

const PaymentOption = React.memo(({ totalAmount }) => {
    const dispatch = useDispatch();
    const { adminCodeError } = useSelector((state) => state.formSlice);
    const [showAdminOption, setShowAdminOption] = useState(Boolean);
    const [adminCodeToDisplay, setAdminCodeToDisplay] = useState("");
    const isRestrictedPayment = totalAmount > 3900000 ? true : false;

    useEffect(() => {
        dispatch(showAdminCodeError(false));
        if (!isRestrictedPayment) {
            dispatch(setPayingOptions("walletOption"));
        }
    }, []);

    const selectionOfPaymentOption = (e) => {
        dispatch(setPayingOptions(e.target.id));
        if (e.target.id == "adminPayment") {
            setShowAdminOption(true);
        } else {
            setShowAdminOption(false);
        }
    };

    const handleAdminCode = (event) => {
        let adminCode = event.target.value;
        setAdminCodeToDisplay(adminCode);
        dispatch(setAdminCode(adminCode));
    };

    return (
        <React.Fragment>
            <div className="payment-and-send-modal-container">
                <div className="form-check">
                    <div className="row align-items-center gy-2">
                        <div className="custom-col col-12 col-sm-6 col-md-6 d-flex align-items-center justify-content-start">
                            <input
                                className="form-check-input"
                                type="radio"
                                disabled={isRestrictedPayment}
                                name="flexRadioDefault"
                                id="walletOption"
                                defaultChecked
                                onClick={(e) => {
                                    selectionOfPaymentOption(e);
                                }}
                            />
                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                                Pay with Mobile Money
                            </label>
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 d-flex justify-content-evenly">
                            {/* <img src="images/M-PESA.png" alt="" /> */}
                            <img src="images/mtn-logo.png" alt="" />
                            <img src="images/airtel-money.png" alt="" />
                        </div>
                    </div>
                </div>
                <div className="my-1 text-center"> or </div>
                <div className="form-check align-items-center justify-content-between">
                    <div className="row align-items-center gy-2">
                        <div className="custom-col col-12 col-sm-6 col-md-6 d-flex align-items-center justify-content-start">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="flexRadioDefault"
                                id="bankOption"
                                onClick={(e) => {
                                    selectionOfPaymentOption(e);
                                }}
                            />
                            <label className="form-check-label" htmlFor="flexRadioDefault2">
                                Pay by Bank Deposit / Transfer
                            </label>
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 d-flex justify-content-center">
                            <div>
                                <img src="images/bank-logo.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                {localStorage.getItem("Role") == "Admin" ? (
                    <>
                        <div className="my-1 text-center"> or </div>
                        <div>
                            <div className="form-check align-items-center justify-content-between">
                                <div className="row align-items-center gy-2">
                                    <div className="custom-col col-12 col-sm-6 col-md-6 d-flex align-items-center justify-content-start">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="flexRadioDefault"
                                            id="adminPayment"
                                            onClick={(e) => {
                                                selectionOfPaymentOption(e);
                                            }}
                                        />
                                        <label style={{ paddingLeft: "10px" }}>
                                            {" "}
                                            Admin Payment
                                        </label>
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-6 d-flex justify-content-center">
                                        <div>
                                            <img src="images/bank-logo.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {showAdminOption ? (
                                <div className="row pt-2 align-items-center justify-content-center">
                                    <div className="col-12 col-sm-5">
                                        <input
                                            placeholder="Admin Code Here"
                                            onChange={handleAdminCode}
                                            value={adminCodeToDisplay}
                                            required={true}
                                            className="w-100"
                                        ></input>
                                        <br />
                                        {adminCodeError ? (
                                            <span className="error-message-admin-code">
                                                {" "}
                                                {"Code should be 44 characters long."}
                                            </span>
                                        ) : null}
                                    </div>
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                    </>
                ) : (
                    ""
                )}
            </div>
        </React.Fragment>
    );
});

export default PaymentOption;

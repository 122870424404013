import { combineReducers } from "redux";
import registrationSlice from "../pages/Registration/RegistrationSlice";
import loginSlice from "../pages/Login/LoginSlice";
import formSlice from "../pages/FormsModal/FormSlice";
import userDashboardSlice from '../pages/UserDashboard/userDashboardSlice'
import { enableMapSet } from 'immer'

{/*
  Enable immer support for Map and Set.
*/}
enableMapSet();

const rootReducer = combineReducers({
  registrationSlice,
  loginSlice,
  formSlice,
  userDashboardSlice
});

export default rootReducer;

import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    loginWithEmail,
    setEmailOtp,
    setUserOtpMap,
    setLoginStep,
    loginWithMobile,
    setMobileOtp,
} from "./LoginSlice";
import { setActiveFormId } from "../FormsModal/FormSlice";
import { setShowLoader } from "../FormsModal/FormSlice";
import "./login.css";
import { isValidEmail } from "../../components/Utility/ValidationUtilities";

function EmailLoginOtp() {
    const dispatch = useDispatch();
    const { mobile, email, errorMsg, userOtpMap } = useSelector(
        (state) => state.loginSlice
    );
    const { verifiedPayeeDetailsList } = useSelector((state) => state.formSlice);
    const [userEmailOtp] = React.useState("");
    const [userMobileOtp] = React.useState("");
    const [showError, setShowError] = React.useState("");
    const inputRefs = useRef([]);
    const isDigitOnly = /^\d+$/;

    const onClickLogin = () => {
        try {
            let receivedOtp = Array.from(userOtpMap.values()).join("").toString();
            console.log("OTP", receivedOtp);
            if (/^\d{6}$/.test(receivedOtp)) {
                setShowError(false);
                if (email != "") {
                    dispatch(setEmailOtp(userEmailOtp));
                    var EmailLoginOtp = {
                        email: email,
                        otp: receivedOtp,
                    };
                    dispatch(loginWithEmail(EmailLoginOtp));
                    dispatch(setShowLoader(true));
                } else {
                    dispatch(setMobileOtp(userMobileOtp));
                    var MobileLoginOtp = {
                        mobile: mobile,
                        otp: receivedOtp,
                    };
                    dispatch(loginWithMobile(MobileLoginOtp));
                    dispatch(setShowLoader(true));
                }
            } else {
                setShowError(true);
            }
        } catch (error) {
            console.log("Exception occured while signing user.", error);
        }
    };

    const onChangeSetOTP = (e) => {
        dispatch(setUserOtpMap({ key: e.target.id, value: e.target.value }));
    };

    const handleInput = (index, event) => {
        const input = event.target;
        if (input.value.length === 1 && isDigitOnly.test(event.target.value)) {
            const nextInput = inputRefs.current[index + 1];
            if (nextInput) {
                nextInput.focus();
            }
        } else {
            inputRefs.current[index].value = "";
        }
    };

    const handleBackspace = (index, event) => {
        const input = event.target;
        if (event?.nativeEvent.key === "Backspace") {
            const prevInput = inputRefs.current[index - 1];
            if (input.value.length === 1) {
                inputRefs.current[index].value = "";
            } else if (prevInput) {
                prevInput.focus();
            }
        }
    };

    const handleCloseModel = () => {
        dispatch(setActiveFormId(""));
        dispatch(setLoginStep(""));
    };

    const handleClickPhoneNumber = () => {
        dispatch(setLoginStep("1"));
    };

    return (
        <div
            className="modal fade show d-block login-modal all-modals-common-class"
            aria-hidden="true"
            tabIndex="-1"
        >
            <div className="modal-dialog modal-max-width720">
                <div className="modal-content">
                    {/* Modal Close Icon */}
                    <div className="modal-close-btn">
                        <img
                            src="images/close.png"
                            alt="close-icon"
                            onClick={handleCloseModel}
                        />
                    </div>
                    <div className="modal-header justify-content-start">
                        <span>
                            <img
                                src="images/login-user.png"
                                alt=""
                                className="login-user-icon"
                            />
                        </span>
                        <div className="d-flex flex-column">
                            <h4 className="modal-title">Login</h4>
                            {email ? (
                                <p className="modal-subtitle">
                                    {" "}
                                    We sent a 6 digit code to{" "}
                                    <strong> {email != "" ? email : ""}</strong>
                                </p>
                            ) : (
                                <p className="modal-subtitle">
                                    {" "}
                                    We sent a 6 digit code to{" "}
                                    <strong> {mobile != "" ? mobile : ""}</strong>
                                </p>
                            )}
                        </div>
                    </div>
                    <div className="modal-body ">
                        <div
                            className="login-otp-box row justify-content-evenly "
                            style={{ minHeight: "2em" }}
                        >
                            <input
                                className="col-2 fs-3"
                                ref={(el) => (inputRefs.current[0] = el)}
                                onInput={(event) => handleInput(0, event)}
                                onKeyDown={(event) => handleBackspace(0, event)}
                                type="text"
                                id="digit-1"
                                name="digit-1"
                                data-next="digit-2"
                                maxLength="1"
                                onChange={(e) => {
                                    onChangeSetOTP(e);
                                }}
                            />
                            <input
                                className="col-2 fs-3"
                                ref={(el) => (inputRefs.current[1] = el)}
                                onInput={(event) => handleInput(1, event)}
                                onKeyDown={(event) => handleBackspace(1, event)}
                                type="text"
                                id="digit-2"
                                name="digit-2"
                                data-next="digit-3"
                                data-previous="digit-1"
                                maxLength="1"
                                onChange={(e) => {
                                    onChangeSetOTP(e);
                                }}
                            />
                            <input
                                className="col-2 fs-3"
                                ref={(el) => (inputRefs.current[2] = el)}
                                onInput={(event) => handleInput(2, event)}
                                onKeyDown={(event) => handleBackspace(2, event)}
                                type="text"
                                id="digit-3"
                                name="digit-3"
                                data-next="digit-4"
                                data-previous="digit-2"
                                maxLength="1"
                                onChange={(e) => {
                                    onChangeSetOTP(e);
                                }}
                            />
                            <input
                                className="col-2 fs-3"
                                ref={(el) => (inputRefs.current[3] = el)}
                                onInput={(event) => handleInput(3, event)}
                                onKeyDown={(event) => handleBackspace(3, event)}
                                type="text"
                                id="digit-4"
                                name="digit-4"
                                data-next="digit-5"
                                data-previous="digit-3"
                                maxLength="1"
                                onChange={(e) => {
                                    onChangeSetOTP(e);
                                }}
                            />
                            <input
                                className="col-2 fs-3"
                                ref={(el) => (inputRefs.current[4] = el)}
                                onInput={(event) => handleInput(4, event)}
                                onKeyDown={(event) => handleBackspace(4, event)}
                                type="text"
                                id="digit-5"
                                name="digit-5"
                                data-next="digit-6"
                                data-previous="digit-4"
                                maxLength="1"
                                onChange={(e) => {
                                    onChangeSetOTP(e);
                                }}
                            />
                            <input
                                className="col-2 fs-3"
                                ref={(el) => (inputRefs.current[5] = el)}
                                onInput={(event) => handleInput(5, event)}
                                onKeyDown={(event) => handleBackspace(5, event)}
                                type="text"
                                id="digit-6"
                                name="digit-6"
                                data-previous="digit-5"
                                maxLength="1"
                                onChange={(e) => {
                                    onChangeSetOTP(e);
                                }}
                            />
                        </div>
                    </div>
                    {errorMsg != "" ? <p className="error-msg">{errorMsg}</p> : null}
                    <br />
                    <div className="modal-footer" style={{ borderTop: "none" }}>
                        <button className="btn btn-primary send-btn" onClick={onClickLogin}>
                            {" "}
                            Sign in{" "}
                        </button>
                        <div className="footer-or-text">
                            <span> or </span>
                        </div>
                        <button
                            className="btn signin-with-mobile-btn"
                            onClick={() => {
                                handleClickPhoneNumber();
                            }}
                        >
                            {" "}
                            Sign in with Email{" "}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EmailLoginOtp;

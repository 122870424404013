import CountryDropdown from 'country-dropdown-with-flags-for-react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setActiveRegistrationStep, setPhoneCountryCode, setRegistrationDetails, takeRegisterUser, setUserRegistration, resetUserRegistration } from './RegistrationSlice';
import { useSelector } from "react-redux";
import { isValidEmail, isValidPhone, isValue } from '../../components/Utility/ValidationUtilities';
import { setLoginStep } from '../Login/LoginSlice';
import { checkMobileNumber, handleOnCheckMobileNumber } from '../../culipaUtils/culipaUtils';
import './registration.css';
import agent from '../../agent';
import { culipaFormatter } from '../../culipaUtils/culipaFormatter';
import countryOptions from './countryOptions';
import { setSelectedCountryCode, setSelectedCountryObject, resetSelectedCountryObject } from '../FormsModal/FormSlice';
import Select from 'react-select';
function RegistrationStepOne(props) {
    const { verifiedPayeeDetailsList, totalPayeeTransactions, selectedCountryObject } = useSelector((state) => state.formSlice);
    const { userRegistration } = useSelector((state) => state.registrationSlice);
    const dispatch = useDispatch();
    const [userType, setUserType] = useState("Individual");
    const [orgName, setOrgName] = useState("");
    const [name, setName] = useState(userRegistration["name"]);
    const [surname, setSurname] = useState(userRegistration["surname"]);
    const [nationality, setNationality] = useState("Uganda");
    const [mobNumber, setMobNumber] = useState(userRegistration["mobNumber"] != "" ? userRegistration["mobNumber"] : "256");
    const [email, setEmail] = useState(userRegistration["email"]);
    const [showError, setShowError] = useState(false);


    const [selectedCountry, setSelectedCountry] = useState(Object.keys(selectedCountryObject) != 0 ? selectedCountryObject : {
        "value": "ug",
        "label": "+256 Uganda"
    });


    const [maxLength, setMaxLength] = useState(12);
    const [error, setError] = useState("");
    const onlyDigit = /^\d+$/;
    const countryCode = selectedCountry.label.replace(/\D/g, '');

    const onChangeCountry = (e) => {
        var flag = e.target.nextSibling.firstChild.children[0].className;
        dispatch(setUserRegistration({ key: "countryCode", info: flag.toString().split(" ")[1] }))
        dispatch(setPhoneCountryCode(flag.toString().split(" ")[1]));
        setMobNumber("");
        if (e.isTrusted) {
            setNationality(e.target.value)
        } else {
            setTimeout(() => {
                setNationality(e.target.value)
            }, 100);
        }
    }

    const handleOnChangeName = (e) => {
        let userName = e.target.value;
        setName(userName)
        dispatch(setUserRegistration({ key: "name", info: userName }))
    }

    const handleOnChangeSurname = (e) => {
        let surName = e.target.value;
        setSurname(surName)
        dispatch(setUserRegistration({ key: "surname", info: surName }))
    }

    const handleOnChangeMobileNumber = (event) => {
        setError("");
        let mobileNo = event.target.value;
        if (onlyDigit.test(mobileNo)) {
            setMobNumber(mobileNo)
            dispatch(setUserRegistration({ key: "mobNumber", info: mobileNo }))
        }
        if (mobileNo === "") {
            setMobNumber("");
        }

    }
    const handleOnChangeEmail = (e) => {
        let email = e.target.value;
        setEmail(email)
        dispatch(setUserRegistration({ key: "email", info: email }))
    }

    const onclickBack = () => {
        dispatch(setRegistrationDetails({}));
        dispatch(setActiveRegistrationStep(""));
    }

    const addforeignNational = (userObject) => {
        try {
            let user = {
                "firstName": userObject["firstName"],
                "lastName": userObject["lastName"],
                "nationality": userObject["nationality"],
                "mobileNumber": userObject["mobileNo"],
                "email": userObject["email"],
            }
            return agent.User.addForeignNational(user).then((response) => {
                return response;
            })
        } catch (error) {
            console.log("exception occured during adding foreign nationals.", error);
        }

    }

    const isValidate = () =>
        isValue(userType) &&
        isValue(name) &&
        isValue(surname) &&
        isValue(nationality) &&
        (error === "" && mobNumber != countryCode) &&
        isValidEmail(email)

    const onclickNext = () => {
        dispatch(setSelectedCountryObject(selectedCountry));
        if (isValidate()) {
            setShowError(false)

            var requestObj = {
                userType: userType,
                orgName: orgName,
                firstName: name,
                lastName: surname,
                nationality: nationality,
                mobileNo: mobNumber,
                email: email
            }

            dispatch(setRegistrationDetails(requestObj));

            if (userType != "Individual" || nationality != "Uganda") {
                dispatch(takeRegisterUser(requestObj));
                addforeignNational(requestObj);
            }

            dispatch(setActiveRegistrationStep("2"));
        } else {
            setShowError(true)
        }

    }

    const validatePhoneNumber = (number) => {
        let userMobileNumber = number;
        return isValidPhone(userMobileNumber);
    }

    const handleLogin = () => {
        dispatch(setActiveRegistrationStep(""))
        dispatch(setLoginStep("1"));
    }
    const handleCloseModel = () => {
        dispatch(setActiveRegistrationStep(""))
        dispatch(resetUserRegistration());
        dispatch(resetSelectedCountryObject())
    }

    const handleCountryChange = (selectedOption) => {
        if (selectedOption.value != "ug") {
            setMaxLength(null)
            setMobNumber(selectedOption.label.replace(/\D/g, ''))
            dispatch(setUserRegistration({ key: "mobNumber", info: selectedOption.label.replace(/\D/g, '') }))
            dispatch(setSelectedCountryCode(selectedOption.label.replace(/\D/g, '')));
        } else {
            setMaxLength(12);
            setMobNumber("256");
            dispatch(setUserRegistration({ key: "mobNumber", info: "256" }))
            dispatch(setSelectedCountryCode("256"));
        }
        setSelectedCountry(selectedOption);
        dispatch(setSelectedCountryObject(selectedOption));
    };

    const handleOnBeforeInput = (mobileNumber) => {
        setMobNumber(mobileNumber);
        dispatch(setUserRegistration({ key: "mobNumber", info: mobileNumber }));
    }

    return (
        <div className="modal fade show d-block all-modals-common-class" aria-hidden="true" tabIndex="-1">
            <div className="modal-dialog">
                <div className="modal-content">
                    {/* Modal Close Icon */}
                    <div className="modal-close-btn">
                        <img src="images/close.png" alt="close-icon" onClick={handleCloseModel} />
                    </div>
                    <div className="modal-header">
                        <span>
                            <img src="images/login-user.png" alt="" className="login-user-icon" />
                        </span>
                        <div className="d-flex flex-column">
                            <h4 className="modal-title" style={{ lineHeight: '40px', fontSize: '35px' }}>
                                {/* Please Register for a <br />Culipa Account */}
                                Sign Up for a Culipa Account
                            </h4>
                            {verifiedPayeeDetailsList.length > 0 ? <p className="modal-subtitle mt-4"> {` or login to complete your UGX ${culipaFormatter.currencyFormatter.format(totalPayeeTransactions["totalAmount"])} transfer to ${culipaFormatter.currencyFormatter.format(totalPayeeTransactions["totalPayees"])} Payees `}</p> : null}

                        </div>
                    </div>
                    <div className="modal-body">
                        <div>
                            <h5 className='modal-step-title'>Step 1</h5>
                            <form className="row g-3">
                                <div className='col-12'>
                                    <label for="recipient-name" className="col-form-label">User Type</label>
                                    <select className="form-select" aria-label="Default select example" onChange={(event) => { setUserType(event.target.value) }}>
                                        <option value="Individual">Individual</option>
                                        <option value="Business">Business</option>
                                        <option value="Non-Profit/NGO">Non-Profit/NGO</option>
                                        <option value="Public/Government"> Public/Government</option>
                                    </select>
                                    {showError ? <p className="error-msg">{!isValue(userType) ? "Please select User Type" : ""}</p> : null}
                                </div>
                                {userType != "Individual" ?
                                    <div className='col-md-12 mt-1'>
                                        <label for="recipient-name" className="col-form-label">Business/Organisation Name</label>
                                        <input type="text" className="form-control" id="recipient-name" value={orgName} onChange={(event) => setOrgName(event.target.value)} />
                                    </div> : null}
                                <div className='col-md-6 mt-1'>
                                    <label for="recipient-name" className="col-form-label"> Given Names (As on National ID/Passport)</label>
                                    <input type="text" className="form-control" id="recipient-name" value={name} onChange={(event) => handleOnChangeName(event)} />
                                    {showError ? <p className="error-msg" >{!isValue(name) ? "Please enter name" : ""}</p> : null}
                                </div>
                                <div className='col-md-6 mt-1'>
                                    <label for="recipient-name" className="col-form-label">Surname (As on National ID/Passport)</label>
                                    <input type="text" className="form-control" id="recipient-name" value={surname} onChange={(event) => handleOnChangeSurname(event)} />
                                    {showError ? <p className="error-msg" >{!isValue(surname) ? "Please enter surname" : ""}</p> : null}
                                </div>
                                <div className='col-md-5 mt-1'>
                                    <label for="recipient-name" className="col-form-label">Nationality</label>
                                    <CountryDropdown id="UNIQUE_ID" classNameName='form-select w-100' value={nationality} handleChange={(e) => onChangeCountry(e)} preferredCountries={['ug', 'ke', 'tz', 'rw']}></CountryDropdown>
                                    {showError ? <p className="error-msg" >{!isValue(nationality) ? "Please select Nationality" : ""}</p> : null}
                                </div>
                                <div className='col-md-7 mt-1'>
                                    <label for="recipient-name" className="col-form-label">Country code</label>
                                    <Select
                                        options={countryOptions}
                                        onChange={handleCountryChange}
                                        placeholder="Select country"
                                        defaultValue={selectedCountry}
                                    />
                                    Phone Number
                                    <input type='tel' value={mobNumber} maxLength={maxLength} onChange={e => { handleOnChangeMobileNumber(e) }} onBlur={e => { handleOnCheckMobileNumber(e, handleOnBeforeInput, setError, countryCode) }} />
                                    <p className="error-msg" >{error != "" ? "Please enter valid mobile number (e.g , 0700123456)" : ""}</p>
                                </div>
                                <div className='col-12 mt-1'>
                                    <label for="recipient-name" className="col-form-label">Email Address</label>
                                    <input type="text" className="form-control" id="recipient-name" value={email} onChange={(event) => handleOnChangeEmail(event)} />
                                    {showError ? <p className="error-msg" >{!isValidEmail(email) ? "Please enter valid email" : ""}</p> : null}
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button className="btn float-start registration-next-btn" onClick={onclickBack}>Back</button>
                        <button className="btn float-end registration-login-btn" onClick={onclickNext}>Next</button>
                        <button className="btn registration-login-btn w-100 mt-2" onClick={handleLogin}>Login</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RegistrationStepOne;
import axios from "axios";
import { getInitiateDetails } from "./pages/UserDashboard/userDashboardSlice";

const API_ROOT = process.env.API_ROOT
  ? process.env.API_ROOT
  : "https://bulkpay.culipa.com:8080";

const responseBody = (res) => res;
const errorBody = (err) => err;

const tokenPlugin = (req) => {
  let token = ""
  if (localStorage.getItem("Role") && localStorage.getItem("Role") === "Admin") {
    token = localStorage.getItem("jwt-admin");
  } else {
    token = localStorage.getItem("jwt");
  }

  if (token) {
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
      },
    };
    return headers;
  } else {
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
      },
    };
    return headers;
  }
};

const requests = {
  delete: async (url) => {
    return await axios
      .delete(`${API_ROOT}${url}`, tokenPlugin())
      .then(responseBody)
      .catch(errorBody);
  },
  get: async (url) => {
    return await axios
      .get(`${API_ROOT}${url}`, tokenPlugin())
      .then(responseBody)
      .catch(errorBody);
  },
  put: async (url, body) => {
    return await axios
      .put(`${API_ROOT}${url}`, body, tokenPlugin())
      .then(responseBody)
      .catch(errorBody);
  },
  post: async (url, body) => {
    return await axios
      .post(`${API_ROOT}${url}`, body, tokenPlugin())
      .then(responseBody)
      .catch(errorBody);
  },
};

const User = {
  register: (registrationUserPayload) => {
    return requests.post("/api/user/registerUser", registrationUserPayload);
  },
  verifyOtpOnEmail : (verifyOtpRequestPayload) => {
    return requests.post("/api/user/verifyOtpOnEmail", verifyOtpRequestPayload);
  },
  verifyOtpOnPhone : (verifyOtpRequestPayload) => {
    return requests.post("/api/user/verifyOtpOnPhone", verifyOtpRequestPayload);
  },
  loginUser : (loginRequest) => {
    return requests.post("/api/user/signInWithEmail", loginRequest);
  },
  loginUserMobile : (loginRequest) => {
    return requests.post("/api/user/signInWithMobile", loginRequest);
  },
  sendEmailOtp : (email) => {
    return requests.post("/api/user/otpToLoginOnEmail/"+ email);
  },
  sendMobileOtp : (mobile) => {
    return requests.post("/api/user/otpToLoginOnMobile/"+ mobile);
  },
  getUserDetails :()=>{
    return requests.get("/api/user/getUserDetails");
  },
  checkNationalIdExist:(nationalId)=>{
    return requests.get("/api/user/checkNationalIdExist?nationalId="+nationalId);
  },
  checkNationalIdCardExist:(nationalIdCard)=>{
    return requests.get("/api/user/checkNationalIdCardExist?nationalIdCard="+nationalIdCard);
  },
  addForeignNational:(foreignNationals)=>{
    return requests.post("/api/user/foreignNational",foreignNationals);
  },
  payeeTotalTransactions:()=>{
    return requests.get("/api/user/totalTransactions");
  },
  sendAdminEmailOtp : (email) => {
    return requests.post("/api/user/otpForAdminLogin/"+ email);
  },
  sendNohaLoginRequest : (payload) => {
    return requests.post("/api/user/noha/login/callback?payload="+payload);
  },
  getNohaSession : (code) => {
    return requests.post("https://noha.culipa.net/api/session/validity?",code);
  },
  adminLogin : (loginRequest) => {
    return requests.post("/api/user/signInWithAdminEmail", loginRequest);
  },
  adminLoginNoha : (loginRequest) => {
    return requests.post("/api/user/signInWithAdminEmail", loginRequest);
  },
  getSelectedUserNumber : (obj) => {
    return requests.get("/api/user/getPhoneNumber?value="+obj.payload);
  },
  sendEmail: (obj) => {
    return requests.post("/api/user/sendEmail", obj);
  },
  updateStatus: (obj) => {
    return requests.post("/api/user/updateStatus", obj);
  }
};

const Upload = {
  uploadFile: (formData) => {
    return requests.post("/api/form/uploadCSV", formData);
  },
  getUploadedDetailsByUuid: (uuid) => {
    return requests.get("/api/form/getCSVData/"+uuid);
  }
}

const Form = {
  // uploadFile: (formData) => {
  //   return requests.post("/api/form/uploadCSV", formData);
  // },
  validateUserFromCulipa: (uuid) => {
    return requests.get("/api/culipaConnector/validateUserFromCulipa/"+uuid);
  },
  updatePayeeDetails: (obj) => {
    return requests.put("/api/form/updatePayeeDetails", obj);
  },
  deleteThePayee: (id) => {
    return requests.delete("/api/form/deleteThePayee/"+id);
  },
  uploadPayee:(payeeList)=>{
    return requests.post("/api/form/uploadPayee",payeeList);
  },
  deleteThePayeeObj: (id) => { 
    return requests.delete("/api/form/deleteThePayeeObj/"+id);
  },
  getCulipaUserByEmail: (obj) => {
    return requests.post("/api/form/getCulipaUserByEmail", obj);
  },
  getCulipaUserByMobile: (obj) => {
    return requests.post("/api/form/getCulipaUserByMobile", obj);
  },
  getJsonResToSetDisbursementRanges : (uuid) => {
    return requests.get("/api/form/getJsonForDisbursementRanges/"+uuid);
  }, 
}

const Payment = {
  initiatePayment:(initiatePaymentRequestPayload) => {
    return requests.post("/api/culipaConnector/initiatePayment", initiatePaymentRequestPayload)
  },
  initiateAdminPayment:(initiatePaymentRequestPayload) => {
    return requests.post("/api/culipaConnector/initiateAdminPayment", initiatePaymentRequestPayload)
  },
  initiatePayByBank:(initiatePayByBankRequestPayload) => {
    return requests.post("/api/culipaConnector/initiatePayByBank", initiatePayByBankRequestPayload)
  }
}

const userDashboard = {
  getPayeeDetails:(initiatePaymentId)=>{
    return requests.get("/dashboard/payeeDetails?initiateId="+initiatePaymentId);
  },
  getInitiateDetails:()=>{
    return requests.get("/dashboard/initiateDetails");
  },
  updateCsvName:(csvName,initiatePaymentId)=>{
    return requests.post("/dashboard/updateCsvName?csvName="+csvName+"&initiateId="+initiatePaymentId)
  },
  checkTransactionIdExistOrNot:(transactionID)=>{
    return requests.get("/dashboard/isTransactionIdExist?transactionID="+transactionID)
  },
  getPaymentStatus:(transactionID)=>{
    return requests.get("/dashboard/getPaymentStatus?transactionID="+transactionID)
  },
  getUsersDetails: () => {
    return requests.get("/dashboard/usersDetails");
  },
  initiateDetailsForUser: (obj) => {
    return requests.post("/dashboard/initiateDetailsForUser?value="+obj.payload);
  },
  getCurrentLoggedInUser: (obj) => {
    return requests.get("/dashboard/currentLoggedInUser");
  }
}

export default {
  // Auth,
  User,
  Upload,
  Form,
  Payment,
  userDashboard
};
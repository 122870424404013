import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    setActiveFormId,
    setPayingOptions,
    deleteThePayeeById,
    setShowLoader,
    updateObjWithInvalidStatus,
    setUserReference,
    setWithDrawToggle,
    setUpdatedVerifiedPayeeDetailsList,
    updatedVerifiedPayeeDetailsList,
} from "./FormSlice";
import { culipaFormatter } from "../../culipaUtils/culipaFormatter";
import { setIsTransfersPopupVisble } from "../UserDashboard/userDashboardSlice";
import Switch from "@mui/material/Switch";

function ValidatedPayeeDetailsModal() {
    const {
        isShowLoader,
        validatedPayeeDetailsList,
        payeeTotalCalculation,
        payeeUpdatedCalculation,
        verifiedPayeeDetailsList,
        culipaFees,
        updatedCulipaFees,
        activeUuid,
        withdrawToggle,
        userReference,
        defaultCalculationMode,
        totalTax,
        updatedTax,
    } = useSelector((state) => state.formSlice);
    const dispatch = useDispatch();
    const [editableId, setEditableId] = useState("");
    const [fullName, setFullName] = useState();
    const [mobile, setMobile] = useState();
    const [isClicked, setIsClicked] = useState(false);
    const [amount, setAmount] = useState();
    const [referenceToPass, setReferenceToPass] = useState("");
    const label = { inputProps: { "aria-label": "Switch demo" } };
    const [toggleForWithdraw, setToggleForWithdraw] = useState(withdrawToggle);

    useEffect(() => {
        dispatch(setIsTransfersPopupVisble(false));
        dispatch(setPayingOptions(""));
        setReferenceToPass(userReference);
    }, [1]);

    const updatedPayeeObject = {
        id: "",
        fullName: fullName,
        amount: amount,
        mobile: mobile,
    };

    const onclickBack = () => {
        if (validatedPayeeDetailsList.length != 0) {
            dispatch(setActiveFormId("3"));
        } else {
            dispatch(setActiveFormId("2"));
        }
    };

    const setEditableTrueForIndex = (validatePayee) => {
        setEditableId(validatePayee.id);
        setFullName(validatePayee.fullName);
        setMobile(validatePayee.mobileNo);
        setAmount(validatePayee.amount);
        setIsClicked(true);
    };

    const onclickNext = () => {
        let userToken = localStorage.getItem(
            localStorage.getItem("Role") && localStorage.getItem("Role") === "Admin"
                ? "jwt-admin"
                : "jwt"
        );
        if (userToken != "" && userToken != null && userToken != undefined) {
            dispatch(setActiveFormId("5"));
        } else {
            dispatch(setActiveFormId("7"));
        }
    };

    const handleCloseModel = () => {
        dispatch(setActiveFormId(""));
    };

    const handleDelete = (payeeDetailId) => {
        dispatch(setShowLoader(true));
        var reqObj = {
            id: payeeDetailId,
            uuid: activeUuid,
            isByPass: true,
        };
        dispatch(deleteThePayeeById(reqObj));
    };

    const validateFirstName = (e) => {
        setFullName(e);
    };

    const validateMobile = (e) => {
        setMobile(e);
    };

    const validateAmount = (e) => {
        if (Number(e) < 40000000) {
            setAmount(e);
        }
    };

    const validateUpdatedAmount = () => {
        let amount = JSON.parse(JSON.stringify(verifiedPayeeDetailsList));
        if (!withdrawToggle) {
            amount.map((validatePayee, index) => {
                validatePayee.amount = culipaFormatter.currencyFormatter.format(
                    calculateTheWithdrawalAmount(
                        validatePayee.mobileNo,
                        validatePayee.amount
                    )
                );
                return validatePayee;
            });
            dispatch(setUpdatedVerifiedPayeeDetailsList(amount));
        } else {
            dispatch(setUpdatedVerifiedPayeeDetailsList([]));
        }
        console.log(updatedVerifiedPayeeDetailsList);
    };

    const handleSave = (payeeToUpdate, id) => {
        updatedPayeeObject.id = id;
        dispatch(setShowLoader(true));
        var reqObj = {
            obj: payeeToUpdate,
            uuid: activeUuid,
            isByPass: true,
        };
        dispatch(updateObjWithInvalidStatus(reqObj));
        dispatch(setActiveFormId("3"));
        setEditableId("");
    };

    const handleOnReference = (event) => {
        let referenceToPass = event.target.value;
        setReferenceToPass(referenceToPass);
        dispatch(setUserReference(referenceToPass));
    };

    const reverseWithDrawToggle = () => {
        setToggleForWithdraw(!toggleForWithdraw);
        dispatch(setWithDrawToggle(!toggleForWithdraw));
    };

    const calculateTheWithdrawalAmount = (mobileNo, amount) => {
        if (
            mobileNo.startsWith("25670") ||
            mobileNo.startsWith("25674") ||
            mobileNo.startsWith("25675")
        ) {
            if (amount <= 2500) {
                return parseInt(amount) + 330;
            } else if (amount >= 2501 && amount <= 5000) {
                return parseInt(amount) + 440;
            } else if (amount >= 5001 && amount <= 15000) {
                return parseInt(amount) + 700;
            } else if (amount >= 15001 && amount <= 30000) {
                return parseInt(amount) + 880;
            } else if (amount >= 30001 && amount <= 45000) {
                return parseInt(amount) + 1210;
            } else if (amount >= 45001 && amount <= 60000) {
                return parseInt(amount) + 1500;
            } else if (amount >= 60001 && amount <= 125000) {
                return parseInt(amount) + 1925;
            } else if (amount >= 125001 && amount <= 250000) {
                return parseInt(amount) + 3575;
            } else if (amount >= 250001 && amount <= 500000) {
                return parseInt(amount) + 7000;
            } else if (amount >= 500001 && amount <= 1000000) {
                return parseInt(amount) + 12500;
            } else if (amount >= 1000001 && amount <= 2000000) {
                return parseInt(amount) + 12500;
            } else if (amount >= 2000001) {
                return parseInt(amount) + 12500;
            }
        } else if (
            mobileNo.startsWith("25676") ||
            mobileNo.startsWith("25677") ||
            mobileNo.startsWith("25678")
        ) {
            if (amount <= 2500) {
                return parseInt(amount) + 330;
            } else if (amount >= 2501 && amount <= 5000) {
                return parseInt(amount) + 440;
            } else if (amount >= 5001 && amount <= 15000) {
                return parseInt(amount) + 700;
            } else if (amount >= 15001 && amount <= 30000) {
                return parseInt(amount) + 880;
            } else if (amount >= 30001 && amount <= 45000) {
                return parseInt(amount) + 1210;
            } else if (amount >= 45001 && amount <= 60000) {
                return parseInt(amount) + 1500;
            } else if (amount >= 60001 && amount <= 125000) {
                return parseInt(amount) + 1925;
            } else if (amount >= 125001 && amount <= 250000) {
                return parseInt(amount) + 3575;
            } else if (amount >= 250001 && amount <= 500000) {
                return parseInt(amount) + 7000;
            } else if (amount >= 500001 && amount <= 1000000) {
                return parseInt(amount) + 12500;
            } else if (amount >= 1000001 && amount <= 2000000) {
                return parseInt(amount) + 15000;
            } else if (amount >= 2000001 && amount <= 4000000) {
                return parseInt(amount) + 18000;
            } else if (amount >= 4000001 && amount <= 5000000) {
                return parseInt(amount) + 20000;
            }
        }
    };

    return (
        <React.Fragment>
            {/* Loader */}
            <div class={isShowLoader ? "lds-dual-ring" : ""}></div>

            <div
                className="modal fade show d-block all-modals-common-class"
                aria-hidden="true"
                tabIndex="-1"
            >
                <div className="modal-dialog modal-max-width900">
                    <div className="modal-content">
                        {/* Modal Close Icon */}
                        <div className="modal-close-btn">
                            <img
                                src="images/close.png"
                                alt="close-icon"
                                onClick={handleCloseModel}
                            />
                        </div>
                        <div className="modal-header row align-items-center justify-content-start">
                            <div className="col-12 align-items-center d-flex">
                                <span>
                                    <img
                                        src="images/plus-icon.png"
                                        alt=""
                                        className="login-user-icon"
                                    />
                                </span>
                                <h2 className="h2 m-0 fw-bold">Payee Details Validated</h2>
                            </div>

                            <div className="col-12">
                                <p className="modal-subtitle ms-5 mt-2">
                                    {" "}
                                    Please review the payee details and our fees{" "}
                                </p>
                            </div>
                        </div>
                        <div
                            className="modal-body"
                            style={{
                                maxHeight: "350px",
                                overflowY: "scroll",
                                padding: "0 5px 0 5px",
                            }}
                        >
                            <div
                                className="payee-details-table table-responsive invalid-payee-modal"
                                style={{ maxHeight: "260px" }}
                            >
                                <table className="table mb-0">
                                    <thead>
                                        <tr>
                                            <th
                                                className="table-full-name-column"
                                                style={{ minWidth: "160px" }}
                                            >
                                                {" "}
                                                <div className="d-flex align-items-center">
                                                    <img src="images/user.png" alt="" />{" "}
                                                    <p>Submitted Name </p>
                                                </div>
                                            </th>
                                            <th
                                                className="table-number-column"
                                                style={{ minWidth: "200px" }}
                                            >
                                                <div className="d-flex align-items-center">
                                                    <img src="images/number.png" alt="" />{" "}
                                                    <p>Mobile Money Number </p>
                                                </div>{" "}
                                            </th>
                                            <th
                                                className="table-amount-column"
                                                style={{ minWidth: "140px" }}
                                            >
                                                <div className="d-flex align-items-center">
                                                    {" "}
                                                    <img src="images/amount.png" alt="" />{" "}
                                                    <p>Amount (UGX) </p>
                                                </div>
                                            </th>
                                            <th className="table-btn-status"> &nbsp; </th>
                                        </tr>
                                    </thead>
                                    <tbody style={{ textWrap: "nowrap" }}>
                                        {verifiedPayeeDetailsList.map((validatePayee, index) => {
                                            return (
                                                <tr key={index}>
                                                    {editableId == validatePayee.id ? (
                                                        <>
                                                            <td>
                                                                {" "}
                                                                <input
                                                                    className="table-invalid-edit-input-field"
                                                                    autoFocus
                                                                    type="text"
                                                                    value={fullName}
                                                                    onChange={(e) =>
                                                                        validateFirstName(e.target.value)
                                                                    }
                                                                ></input>
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <input
                                                                    className="table-invalid-edit-input-field"
                                                                    type="text"
                                                                    value={mobile}
                                                                    onChange={(e) =>
                                                                        validateMobile(e.target.value)
                                                                    }
                                                                ></input>
                                                            </td>
                                                            {toggleForWithdraw ? (
                                                                <td>
                                                                    {" "}
                                                                    <input
                                                                        className="table-invalid-edit-input-field"
                                                                        type="text"
                                                                        value={calculateTheWithdrawalAmount(
                                                                            mobile,
                                                                            amount
                                                                        )}
                                                                        onChange={(e) =>
                                                                            validateAmount(e.target.value)
                                                                        }
                                                                    ></input>
                                                                </td>
                                                            ) : (
                                                                <td>
                                                                    {" "}
                                                                    <input
                                                                        className="table-invalid-edit-input-field"
                                                                        type="text"
                                                                        value={amount}
                                                                        onChange={(e) =>
                                                                            validateAmount(e.target.value)
                                                                        }
                                                                    ></input>
                                                                </td>
                                                            )}
                                                            <td>
                                                                {" "}
                                                                {editableId === validatePayee.id ? (
                                                                    <button
                                                                        onClick={() =>
                                                                            handleSave(
                                                                                updatedPayeeObject,
                                                                                validatePayee.id
                                                                            )
                                                                        }
                                                                        className="btn confirm-save-btn"
                                                                        style={{ backgroundColor: "#3dad49" }}
                                                                    >
                                                                        {" "}
                                                                        Save{" "}
                                                                    </button>
                                                                ) : null}{" "}
                                                            </td>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <td> {validatePayee.name} </td>
                                                            <td> {validatePayee.mobileNo} </td>
                                                            {toggleForWithdraw ? (
                                                                <td>
                                                                    {" "}
                                                                    {culipaFormatter.currencyFormatter.format(
                                                                        calculateTheWithdrawalAmount(
                                                                            validatePayee.mobileNo,
                                                                            validatePayee.amount
                                                                        )
                                                                    )}{" "}
                                                                </td>
                                                            ) : (
                                                                <td>
                                                                    {" "}
                                                                    {culipaFormatter.currencyFormatter.format(
                                                                        validatePayee.amount
                                                                    )}{" "}
                                                                </td>
                                                            )}
                                                            <td className="text-end">
                                                                {/* {!withdrawToggle ? 
                                                                <img src="images/edit.png" alt="" className="table-invalid-edit-btn" onClick={e => { setEditableTrueForIndex(validatePayee) }} /> : ""} */}
                                                                <img
                                                                    src="images/delete.png"
                                                                    alt=""
                                                                    className="table-invalid-delete-btn"
                                                                    onClick={(e) => {
                                                                        handleDelete(validatePayee.id);
                                                                    }}
                                                                />
                                                            </td>
                                                        </>
                                                    )}
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>

                            <div className="w-100 mt-4 mb-3 validate-total-amount-wrapper">
                                <div className="d-flex align-items-center justify-content-between">
                                    <span>
                                        <small> Add Withdrawal Fees </small>
                                    </span>
                                    <Switch
                                        {...label}
                                        onChange={() => reverseWithDrawToggle()}
                                        value={withdrawToggle}
                                        onClick={validateUpdatedAmount}
                                        checked={withdrawToggle}
                                    />
                                </div>
                                <div className="d-flex align-items-center justify-content-between">
                                    <span>
                                        <small> Your Reference </small>
                                    </span>
                                </div>
                                <div className="d-flex align-items-center justify-content-between">
                                    <input
                                        onChange={handleOnReference}
                                        value={userReference}
                                        required={true}
                                    ></input>
                                </div>
                                <br />
                                <div className="d-flex align-items-center justify-content-between">
                                    <span>
                                        <small> Total Amount to Transfer: </small>
                                    </span>
                                    <span>
                                        <small>
                                            {" "}
                                            UGX{" "}
                                            {culipaFormatter.currencyFormatter.format(
                                                withdrawToggle
                                                    ? payeeUpdatedCalculation
                                                    : payeeTotalCalculation
                                            )}{" "}
                                        </small>
                                    </span>
                                </div>
                                <br />
                                <div className="d-flex align-items-center justify-content-between mb-3">
                                    <span>
                                        <small> Culipa Payout Fees: </small>
                                    </span>
                                    <span>
                                        {" "}
                                        UGX{" "}
                                        {defaultCalculationMode
                                            ? ""
                                            : culipaFormatter.currencyFormatter.format(
                                                withdrawToggle ? updatedTax : totalTax
                                            )}
                                    </span>
                                </div>

                                {defaultCalculationMode ? (
                                    toggleForWithdraw ? (
                                        <>
                                            <div className="d-flex align-items-center justify-content-between">
                                                <span>
                                                    {" "}
                                                    <small>
                                                        {" "}
                                                        500 – 50,000 :{" "}
                                                        {updatedCulipaFees != undefined &&
                                                            Object.keys(updatedCulipaFees).length != 0
                                                            ? updatedCulipaFees.stage1Fees.payeeCount
                                                            : 0}{" "}
                                                        x UGX 750
                                                    </small>
                                                </span>
                                                <span>
                                                    {" "}
                                                    <small>
                                                        {" "}
                                                        UGX{" "}
                                                        {culipaFormatter.currencyFormatter.format(
                                                            updatedCulipaFees != undefined &&
                                                                Object.keys(updatedCulipaFees).length != 0
                                                                ? updatedCulipaFees.stage1Fees.payeeFees
                                                                : 0
                                                        )}{" "}
                                                    </small>
                                                </span>
                                            </div>

                                            <div className="d-flex align-items-center justify-content-between">
                                                <span>
                                                    {" "}
                                                    <small>
                                                        {" "}
                                                        50,001 – 500,000 :{" "}
                                                        {updatedCulipaFees != undefined &&
                                                            Object.keys(updatedCulipaFees).length != 0
                                                            ? updatedCulipaFees.stage2Fees.payeeCount
                                                            : 0}{" "}
                                                        x UGX 1,250
                                                    </small>
                                                </span>
                                                <span>
                                                    {" "}
                                                    <small>
                                                        {" "}
                                                        UGX{" "}
                                                        {culipaFormatter.currencyFormatter.format(
                                                            updatedCulipaFees != undefined &&
                                                                Object.keys(updatedCulipaFees).length != 0
                                                                ? updatedCulipaFees.stage2Fees.payeeFees
                                                                : 0
                                                        )}{" "}
                                                    </small>
                                                </span>
                                            </div>

                                            <div className="d-flex align-items-center justify-content-between">
                                                <span>
                                                    {" "}
                                                    <small>
                                                        {" "}
                                                        500,001 – 1,000,000 :{" "}
                                                        {updatedCulipaFees != undefined &&
                                                            Object.keys(updatedCulipaFees).length != 0
                                                            ? updatedCulipaFees.stage3Fees.payeeCount
                                                            : 0}{" "}
                                                        x UGX 1,750
                                                    </small>
                                                </span>
                                                <span>
                                                    {" "}
                                                    <small>
                                                        {" "}
                                                        UGX{" "}
                                                        {culipaFormatter.currencyFormatter.format(
                                                            updatedCulipaFees != undefined &&
                                                                Object.keys(updatedCulipaFees).length != 0
                                                                ? updatedCulipaFees.stage3Fees.payeeFees
                                                                : 0
                                                        )}{" "}
                                                    </small>
                                                </span>
                                            </div>

                                            <div className="d-flex align-items-center justify-content-between">
                                                <span>
                                                    {" "}
                                                    <small>
                                                        {" "}
                                                        1,000,001 – 5,000,000 :{" "}
                                                        {updatedCulipaFees != undefined &&
                                                            Object.keys(updatedCulipaFees).length != 0
                                                            ? updatedCulipaFees.stage4Fees.payeeCount
                                                            : 0}{" "}
                                                        x UGX 2,500
                                                    </small>
                                                </span>
                                                <span>
                                                    {" "}
                                                    <small>
                                                        {" "}
                                                        UGX{" "}
                                                        {culipaFormatter.currencyFormatter.format(
                                                            updatedCulipaFees != undefined &&
                                                                Object.keys(updatedCulipaFees).length != 0
                                                                ? updatedCulipaFees.stage4Fees.payeeFees
                                                                : 0
                                                        )}{" "}
                                                    </small>
                                                </span>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="d-flex align-items-center justify-content-between">
                                                <span>
                                                    {" "}
                                                    <small>
                                                        {" "}
                                                        500 – 50,000 :{" "}
                                                        {culipaFees != undefined &&
                                                            Object.keys(culipaFees).length != 0
                                                            ? culipaFees.stage1Fees.payeeCount
                                                            : 0}{" "}
                                                        x UGX 750
                                                    </small>
                                                </span>
                                                <span>
                                                    {" "}
                                                    <small>
                                                        {" "}
                                                        UGX{" "}
                                                        {culipaFormatter.currencyFormatter.format(
                                                            culipaFees != undefined &&
                                                                Object.keys(culipaFees).length != 0
                                                                ? culipaFees.stage1Fees.payeeFees
                                                                : 0
                                                        )}{" "}
                                                    </small>
                                                </span>
                                            </div>

                                            <div className="d-flex align-items-center justify-content-between">
                                                <span>
                                                    {" "}
                                                    <small>
                                                        {" "}
                                                        50,001 – 500,000 :{" "}
                                                        {culipaFees != undefined &&
                                                            Object.keys(culipaFees).length != 0
                                                            ? culipaFees.stage2Fees.payeeCount
                                                            : 0}{" "}
                                                        x UGX 1,250
                                                    </small>
                                                </span>
                                                <span>
                                                    {" "}
                                                    <small>
                                                        {" "}
                                                        UGX{" "}
                                                        {culipaFormatter.currencyFormatter.format(
                                                            culipaFees != undefined &&
                                                                Object.keys(culipaFees).length != 0
                                                                ? culipaFees.stage2Fees.payeeFees
                                                                : 0
                                                        )}{" "}
                                                    </small>
                                                </span>
                                            </div>

                                            <div className="d-flex align-items-center justify-content-between">
                                                <span>
                                                    {" "}
                                                    <small>
                                                        {" "}
                                                        500,001 – 1,000,000 :{" "}
                                                        {culipaFees != undefined &&
                                                            Object.keys(culipaFees).length != 0
                                                            ? culipaFees.stage3Fees.payeeCount
                                                            : 0}{" "}
                                                        x UGX 1,750
                                                    </small>
                                                </span>
                                                <span>
                                                    {" "}
                                                    <small>
                                                        {" "}
                                                        UGX{" "}
                                                        {culipaFormatter.currencyFormatter.format(
                                                            culipaFees != undefined &&
                                                                Object.keys(culipaFees).length != 0
                                                                ? culipaFees.stage3Fees.payeeFees
                                                                : 0
                                                        )}{" "}
                                                    </small>
                                                </span>
                                            </div>

                                            <div className="d-flex align-items-center justify-content-between">
                                                <span>
                                                    {" "}
                                                    <small>
                                                        {" "}
                                                        1,000,001 – 5,000,000 :{" "}
                                                        {culipaFees != undefined &&
                                                            Object.keys(culipaFees).length != 0
                                                            ? culipaFees.stage4Fees.payeeCount
                                                            : 0}{" "}
                                                        x UGX 2,500
                                                    </small>
                                                </span>
                                                <span>
                                                    {" "}
                                                    <small>
                                                        {" "}
                                                        UGX{" "}
                                                        {culipaFormatter.currencyFormatter.format(
                                                            culipaFees != undefined &&
                                                                Object.keys(culipaFees).length != 0
                                                                ? culipaFees.stage4Fees.payeeFees
                                                                : 0
                                                        )}{" "}
                                                    </small>
                                                </span>
                                            </div>
                                        </>
                                    )
                                ) : (
                                    ""
                                )}
                                <br />
                                <div className="d-flex align-items-center justify-content-between">
                                    <span>
                                        {" "}
                                        <strong> TOTAL: </strong>
                                    </span>
                                    <span>
                                        <strong>
                                            {" "}
                                            {defaultCalculationMode ? "UGX" : ""}{" "}
                                            {defaultCalculationMode
                                                ? culipaFees != undefined &&
                                                    Object.keys(culipaFees).length != 0
                                                    ? culipaFormatter.currencyFormatter.format(
                                                        toggleForWithdraw
                                                            ? payeeUpdatedCalculation +
                                                            updatedCulipaFees.totalFees
                                                            : payeeTotalCalculation + culipaFees.totalFees
                                                    )
                                                    : 0
                                                : culipaFormatter.currencyFormatter.format(
                                                    toggleForWithdraw
                                                        ? payeeUpdatedCalculation + updatedTax
                                                        : payeeTotalCalculation + totalTax
                                                )}{" "}
                                        </strong>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="row w-100 pt-1">
                                <div className="col-6">
                                    {" "}
                                    <button
                                        className="btn float-start registration-next-btn back-black-btn"
                                        onClick={onclickBack}
                                    >
                                        Back
                                    </button>
                                </div>
                                <div className="col-6">
                                    <button
                                        className="btn float-end registration-login-btn"
                                        onClick={onclickNext}
                                        disabled={
                                            referenceToPass === "" ||
                                            referenceToPass.length <= 3 ||
                                            userReference === "" ||
                                            userReference.length <= 3
                                        }
                                    >
                                        Pay & Send
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default ValidatedPayeeDetailsModal;

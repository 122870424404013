import { useDispatch, useSelector } from "react-redux";
import { setActiveRegistrationStep, takeRegisterUser, setUserRegistration, resetUserRegistration } from "./RegistrationSlice";
import { useEffect, useState } from "react";
import PhoneInput from 'react-phone-input-2';
import { setLoginStep } from '../Login/LoginSlice';
import { culipaFormatter } from "../../culipaUtils/culipaFormatter";
import { handleOnCheckMobileNumber } from '../../culipaUtils/culipaUtils';

function RegistrationStepThree(props) {
    const { registrationDetails, registrationError, countryCode, userRegistration } = useSelector((state) => state.registrationSlice);
    const { verifiedPayeeDetailsList, totalPayeeTransactions, selectedCountryCode, selectedCountryObject } = useSelector((state) => state.formSlice);
    const [confirmEmail, setConfirmEmail] = useState(userRegistration["confirmEmail"]);
    const [confirmPhoneNumber, setConfirmPhoneNumber] = useState(userRegistration["confirmPhoneNumber"]);
    const [isFieldValidated, setIsFieldValidated] = useState(false);
    const { isShowLoader } = useSelector((state) => state.formSlice);
    const [email, setEmail] = useState(userRegistration["email"]);
    const [mobNumber, setMobNumber] = useState(userRegistration["mobNumber"]);
    const [maxLength, setMaxLength] = useState(null);
    const [error, setError] = useState("");
    const mobileRegex = /^\d+$/;
    const dispatch = useDispatch();
    const onClickPrevious = () => {
        dispatch(setActiveRegistrationStep("2"));
    }

    const onClickCancle = () => {
        props.nextClick(false)
    }

    const handleOnChangeEmail = (email) => {
        setEmail(email);
        dispatch(setUserRegistration({ key: "email", info: email }))
    }

    const handleOnConfirmEmail = (confirmEmail) => {
        setConfirmEmail(confirmEmail)
        dispatch(setUserRegistration({ key: "confirmEmail", info: confirmEmail }))
        if (confirmEmail.toUpperCase() === email.toUpperCase() && confirmPhoneNumber === email) {
            setIsFieldValidated(true);
        } else {
            setIsFieldValidated(false);
        }
    }

    const handleOnChangeConfirmMobileNumber = (e) => {
        let mobileNumber = e.target.value;
        setConfirmPhoneNumber(mobileNumber);
        if (mobileRegex.test(mobileNumber)) {
            dispatch(setUserRegistration({ key: "confirmPhoneNumber", info: mobileNumber }))
            if (mobNumber === mobileNumber) {
                setIsFieldValidated(true);
            } else {
                setIsFieldValidated(false);
            }
        }
        if (mobileNumber === "") {
            setConfirmPhoneNumber("")
        }

    }

    const handleOnChangeMobileNumber = (e) => {
        let mobileNumber = e.target.value;
        setMobNumber(mobileNumber)
        console.log(mobileNumber);
        if (mobileRegex.test(mobileNumber)) {
            dispatch(setUserRegistration({ key: "mobNumber", info: mobileNumber }))
            if (confirmPhoneNumber === mobileNumber) {
                setIsFieldValidated(true);
            } else {
                setIsFieldValidated(false);
            }

        }
        if (mobileNumber === "") {
            setConfirmPhoneNumber("")
        }

    }

    const onLogin = () => {
        let userRegistrationDetails = {
            "userType": registrationDetails["userType"],
            "orgName": registrationDetails["orgName"],
            "firstName": userRegistration["name"],
            "lastName": registrationDetails["lastName"],
            "nationality": registrationDetails["nationality"],
            "mobileNo": userRegistration["mobNumber"],
            "email": userRegistration["email"],
            "gender": userRegistration["sex"],
            "dob": registrationDetails["dob"],
            "nationalIdNo": userRegistration["nationalIdNumber"],
            "nationalIdCardNo": userRegistration["nationalIdCardNumber"]
        }
        dispatch(takeRegisterUser(userRegistrationDetails))
    }

    const handleLogin = () => {
        dispatch(setActiveRegistrationStep(""))
        dispatch(setLoginStep("1"));
    }
    const handleCloseModel = () => {
        dispatch(setActiveRegistrationStep(""))
        dispatch(resetUserRegistration())
    }
    const handleOnBeforeInput = (mobileNumber) => {
        setMobNumber(mobileNumber);
        dispatch(setUserRegistration({ key: "mobNumber", info: mobileNumber }));
        if (error != "") {
            setIsFieldValidated(false);
        }
    }
    const handleOnBeforeInputForConfirmNumber = (mobileNumber) => {
        setConfirmPhoneNumber(mobileNumber);
        dispatch(setUserRegistration({ key: "confirmPhoneNumber", info: mobileNumber }));
        if (error != "") {
            setIsFieldValidated(false);
        }
    }
    useEffect(() => {
        if (selectedCountryObject.value === "ug") {
            setMaxLength(12);
        } else {
            setMaxLength(null);
        }
        if (confirmEmail === "" || confirmPhoneNumber === "" || mobNumber === "" || confirmEmail === "") {
            setIsFieldValidated(false);
        } else {
            setIsFieldValidated(true);
        }
    }, [])
    return (

        <div className="modal fade show d-block all-modals-common-class" aria-hidden="true" tabindex="-1">
            <div class={isShowLoader ? "lds-dual-ring" : ""}></div>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-close-btn">
                        <img src="images/close.png" alt="close-icon" onClick={handleCloseModel} />
                    </div>
                    <div className="modal-header">
                        <span>
                            <img src="images/login-user.png" alt="" className="login-user-icon" />
                        </span>
                        <div className="d-flex flex-column">
                            <h4 className="modal-title" style={{ lineHeight: '40px', fontSize: '35px' }}>
                                {/* Please Register for a <br />Culipa Account */}
                                Sign Up for a Culipa Account
                            </h4>
                            {verifiedPayeeDetailsList.length != 0 ? <p className="modal-subtitle mt-4"> {` or login to complete your UGX ${culipaFormatter.currencyFormatter.format(totalPayeeTransactions["totalAmount"])} transfer to ${culipaFormatter.currencyFormatter.format(totalPayeeTransactions["totalPayees"])} Payees `} </p> : null}
                        </div>
                    </div>
                    <div className="modal-body">
                        <h5 className='modal-step-title'>Step 3</h5>
                        <form className="row g-3">
                            <div className='col-12'>
                                <label for="recipient-name" className="col-form-label">Your Email Address:</label>
                                <input type="text" className="form-control" id="recipient-name" value={email} onChange={(event) => handleOnChangeEmail(event.target.value)} />
                            </div>
                            <div className='col-12 mt-1'>
                                <label for="recipient-name" className="col-form-label">Confirm Your Email Address:</label>
                                <input type="text" className="form-control" id="recipient-name" value={confirmEmail} onChange={(event) => handleOnConfirmEmail(event.target.value)} />
                            </div>
                            <div className='col-12 mt-1'>
                                <label for="recipient-name" className="col-form-label">Your Mobile Phone Number</label>
                                <input type="text" className="form-control" id="recipient-name" value={mobNumber} onChange={e => { handleOnChangeMobileNumber(e) }} maxLength={maxLength} onBlur={e => { handleOnCheckMobileNumber(e, handleOnBeforeInput, setError, selectedCountryObject.value) }} />

                            </div>
                            <div className='col-12 mt-1'>
                                <label for="recipient-name" className="col-form-label">Confirm Your Phone Number</label>

                                <input type="text" className="form-control" id="recipient-name" value={confirmPhoneNumber} onChange={(event) => handleOnChangeConfirmMobileNumber(event)} maxLength={maxLength} onBlur={e => { handleOnCheckMobileNumber(e, handleOnBeforeInputForConfirmNumber, setError, selectedCountryObject.value) }} />
                            </div>
                            <span style={{ color: 'red' }}>{error}</span>
                        </form>
                    </div>
                    <div className="fs-6 fw-light mb-2" style={{ color: 'red' }}>{registrationError}</div>
                    <div className="modal-footer">
                        <button className="btn float-start registration-next-btn" onClick={onClickPrevious} >Back</button>
                        <button className="btn float-end registration-login-btn" disabled={!isFieldValidated} onClick={isFieldValidated ? onLogin : null}>Next</button>
                        <button className="btn registration-login-btn w-100 mt-2" onClick={handleLogin} >Login</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RegistrationStepThree;
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setOpenModel } from "./userDashboardSlice";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { culipaFormatter } from "../../culipaUtils/culipaFormatter";
import { dateFormatter } from "../../culipaUtils/culipaUtils";
import { setActiveFormId } from "../FormsModal/FormSlice";
function PayeeDetails() {
    const { payeeDetails, bulkTransferReferenceId } = useSelector(
        (state) => state.userDashboardSlice
    );
    const dispatch = useDispatch();
    const COUNTRY_CODE = 256;

    const handleCloseModel = () => {
        dispatch(setOpenModel(false));
        dispatch(setActiveFormId(""));
        //dispatch(getInitiateDetails())
    };

    const exportDetails = () => {
        const unit = "pt";
        const size = "A4";
        const orientation = "portrait";
        const themeToApply = "grid";
        const marginLeft = 230;
        const marginTop = 60;

        const doc = new jsPDF(orientation, unit, size);
        doc.setFontSize(20);

        const title = "PAYEE DETAILS";
        const headers = [
            [
                "NAME",
                "STATUS",
                "TRANSACTION ID",
                "ACCOUNT",
                "ISSUER",
                "DATE & TIME",
                "AMOUNT (UGX)",
            ],
        ];

        const data = payeeDetails.map((obj) => [
            obj.payeeName,
            obj.status == null ? "FAILED" : obj.status,
            obj.walletTxID,
            `${COUNTRY_CODE}${obj.account}`,
            `${obj.wallet}`,
            dateFormatter(obj.time),
            culipaFormatter.currencyFormatter.format(obj.amount),
        ]);

        let content = {
            startY: 80,
            head: headers,
            body: data,
            theme: themeToApply,
            alternateRowStyles: { fillColor: "#dce3ed" },
            headStyles: { fillColor: "#439138", halign: "center" },
            bodyStyles: { halign: "center" },
        };

        doc.text(title, marginLeft, marginTop);
        doc.autoTable(content);
        doc.save("report.pdf");
    };

    const checkStatus = (status) => {
        if (status === "SETTLED") {
            return (
                <button className="btn trnasfer-done-btn" style={{ width: "auto" }}>
                    {" "}
                    <img src="images/check-mark.png" alt="" /> COMPLETED{" "}
                </button>
            );
        } else if (status === "RECEIVED") {
            return (
                <button className="btn trnasfer-done-btn">
                    {" "}
                    <img src="images/check-mark.png" alt="" /> {status}{" "}
                </button>
            );
        } else if (status === "PENDING") {
            return (
                <button
                    className="btn trnasfer-inprogress-btn"
                    style={{ width: "auto" }}
                >
                    {" "}
                    <img src="images/time-left.png" alt="" /> PENDING{" "}
                </button>
            );
        } else if (status === "NOT STARTED") {
            return (
                <button
                    className="btn trnasfer-inprogress-btn"
                    style={{ backgroundColor: "#ffc107", width: "auto" }}
                >
                    {" "}
                    <img src="images/time-left.png" alt="" />
                    {status}{" "}
                </button>
            );
        } else {
            return (
                <button className="btn trnasfer-cancel-btn" style={{ width: "auto" }}>
                    {" "}
                    <img src="images/cancel.png" alt="" /> FAILED
                </button>
            );
        }
    };

    return (
        <React.Fragment>
            {/* Loader */}

            <div
                className="modal fade show d-block all-modals-common-class"
                aria-hidden="true"
                tabIndex="-1"
            >
                <div className="modal-dialog modal-max-width1000">
                    <div className="modal-content">
                        <div className="modal-close-btn">
                            <img
                                src="images/close.png"
                                alt="close-icon"
                                onClick={handleCloseModel}
                            />
                        </div>
                        <div className="modal-header justify-content-start align-items-center mb-4">
                            <span>
                                <img
                                    src="images/transfer.png"
                                    alt=""
                                    className="login-user-icon"
                                />
                            </span>
                            <div className="">
                                <h3 className="h3 fw-bold p-0 m-0 ">
                                    {bulkTransferReferenceId} Payee Details
                                </h3>
                            </div>
                        </div>
                        <div className="col-12">
                            <button className="exportBtn" onClick={() => exportDetails()}>
                                {" "}
                                Export All{" "}
                            </button>
                        </div>

                        <main
                            className="banner-container-text-box"
                        // style={{ maxHeight: "350px", overflowY: "scroll" }}
                        >
                            <div className="">
                                <div className="">
                                    <div
                                        className="table-responsive userdashboard-transfer-list-2 py-0 mt-0"
                                        style={{ maxHeight: "300px" }}
                                    >
                                        <table className="table table-borderless">
                                            <thead>
                                                <tr>
                                                    {/* <th className="userdasboard-checkbox-thead">
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" value="" id="NameCheckbox" />
                                                        </div>
                                                    </th> */}
                                                    <th className="userdasboard-name-thead"> Name </th>
                                                    <th className="userdasboard-status-thead">
                                                        {" "}
                                                        Status{" "}
                                                    </th>
                                                    <th className="userdasboard-status-thead">
                                                        {" "}
                                                        Transaction ID{" "}
                                                    </th>
                                                    <th className="userdasboard-status-thead">
                                                        {" "}
                                                        Account{" "}
                                                    </th>
                                                    <th className="userdasboard-status-thead">
                                                        {" "}
                                                        Issuer{" "}
                                                    </th>
                                                    <th className="userdasboard-date-thead">
                                                        {" "}
                                                        Date & Time{" "}
                                                    </th>
                                                    <th className="userdasboard-amount-thead">
                                                        {" "}
                                                        Amount (UGX){" "}
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {payeeDetails.map((payeeDetails, id) => {
                                                    return (
                                                        <tr key={id}>
                                                            {/* <td>
                                                                    <div className="form-check mt-1">
                                                                        <input className="form-check-input" type="checkbox" value="" id="NameCheckbox" />
                                                                    </div>
                                                                </td> */}
                                                            <td> {payeeDetails.payeeName} </td>
                                                            <td>{checkStatus(payeeDetails.status)}</td>
                                                            <td>{payeeDetails.walletTxID}</td>
                                                            <td>
                                                                {payeeDetails.account.toString().includes("256")
                                                                    ? `${payeeDetails.account}`
                                                                    : `${COUNTRY_CODE}${payeeDetails.account}`}
                                                            </td>
                                                            <td>{`${payeeDetails.wallet}`}</td>
                                                            <td>
                                                                <span className="user-transfer-date">
                                                                    {" "}
                                                                    {dateFormatter(payeeDetails.time)}{" "}
                                                                </span>
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                {culipaFormatter.currencyFormatter.format(
                                                                    payeeDetails.amount
                                                                )}{" "}
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            {/* <button className="btn float-start registration-next-btn" onClick={logout}>Logout</button> */}
                        </main>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default PayeeDetails;

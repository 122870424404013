import React from "react";

import "./footer.css";

function Footer() {
    return (
        <div className="userdashboard-footer-container">
            <div className="container userdashboard-container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="userdashboard-footer-nav">
                            <a href="#" > USER AGREEMENT </a>
                            <a href="#" > PRIVACY POLICY </a>
                            <a href="#" > CULIPA </a>
                            <a href="#" > HELP </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;
"use strict";

Object.defineProperty(exports, "__esModule", { value: true });

exports.isAlwaysValid = exports.isValidPercent = exports.isNumber = exports.isPositiveNumber = exports.isValidBoolean = exports.isNotSelect = exports.isValidDate = exports.isValidZip = exports.isValidEmail = exports.isValidPhone = exports.isValue = exports.isValidJson = void 0;
var isEmail_1 = require("validator/lib/isEmail");
var isMobilePhone_1 = require("validator/lib/isMobilePhone");
var isValidJson = function (json) { return json !== null && json !== undefined; };
exports.isValidJson = isValidJson;
var isValue = function (input) {
    return input !== null && input !== undefined && input.trim().length > 0;
};
exports.isValue = isValue;

var isValidCardNo = function (input) {
    return input !== null && input !== undefined && input.trim().length > 0 &&  /^\d+$/.test(input) && input.trim().length == 9;
};
exports.isValidCardNo = isValidCardNo;

var isValidNationalID = function (input) {
    return input !== null && input !== undefined && input.trim().length > 0 &&  /^[a-zA-Z0-9_.-]*$/.test(input) && input.trim().length == 14;
};
exports.isValidNationalID = isValidNationalID;

var isValidPhone = function (input) {
    return input !== null && input !== undefined && (0, isMobilePhone_1.default);
};
exports.isValidPhone = isValidPhone;
var isValidEmail = function (input) {
    return input !== null && input !== undefined && (0, isEmail_1.default)(input);
};
exports.isValidEmail = isValidEmail;
var isValidZip = function (input) {
    var zipPattern = /^\d{5}(-\d{4})?(?!-)$/;
    return input !== null && input !== undefined && zipPattern.test(input);
};
exports.isValidZip = isValidZip;
var isValidDate = function (input) { return input !== null && input !== undefined; };
exports.isValidDate = isValidDate;
var isNotSelect = function (input) {
    return input !== null && input !== undefined && input !== "Select one";
};
exports.isNotSelect = isNotSelect;
var isValidBoolean = function (input) {
    return input === "Yes" || input === "No" || input === true || input === false;
};
exports.isValidBoolean = isValidBoolean;
var isPositiveNumber = function (input) {
    return input !== null && input !== undefined && input > 0;
};
exports.isPositiveNumber = isPositiveNumber;
var isNumber = function (input) { return /^\d+$/.test(input); };
exports.isNumber = isNumber;
var isValidPercent = function (input) {
    return input !== null && input !== undefined && input > 0 && input <= 100;
};
exports.isValidPercent = isValidPercent;
var isAlwaysValid = function () { return true; };
exports.isAlwaysValid = isAlwaysValid;

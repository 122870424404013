import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    isValidEmail,
    isValidPhone,
} from "../../components/Utility/ValidationUtilities";
import { setErrorMsg, setLoginStep } from "./LoginSlice";
import {
    checkCulipaUserByEmail,
    checkCulipaUserByMobile,
    setActiveFormId,
} from "../FormsModal/FormSlice";

function Login() {
    const dispatch = useDispatch();
    const { errorMsg } = useSelector((state) => state.loginSlice);
    const { isShowLoader, formErrorMsg } = useSelector(
        (state) => state.formSlice
    );

    const [userEmail, setUserEmail] = React.useState("");
    const [userPhone, setUserPhone] = React.useState("");
    const [mobileError, setMobileError] = React.useState(0);
    const [showError, setShowError] = React.useState("");
    const [isSignInWithEmail, setIsSignInWithEmail] = React.useState(true);

    const onEmailChange = (event) => {
        dispatch(setErrorMsg(""));
        setUserEmail(event.target.value);
    };

    const onPhoneChange = (event) => {
        dispatch(setErrorMsg(""));
        setUserPhone(event.target.value);
    };

    const manuallyNumberEnter = {
        mobileNo: userPhone,
    };
    const validateMobileNumber = (mobileNumber) => {
        const COUNTRY_CODE = 256;
        if (mobileNumber != undefined) {
            if (mobileNumber.startsWith("256") && mobileNumber.length === 12) {
                const prefixesWithCountryCode = [
                    "25670",
                    "25674",
                    "25675",
                    "25676",
                    "25677",
                    "25678",
                ];
                if (
                    prefixesWithCountryCode.some((prefix) =>
                        mobileNumber.startsWith(prefix)
                    )
                ) {
                    manuallyNumberEnter.mobileNo = userPhone;
                    return true;
                } else {
                    return false;
                }
            } else if (mobileNumber.startsWith("0") && mobileNumber.length === 10) {
                const prefixesWithZero = ["070", "074", "075", "076", "077", "078"];
                if (
                    prefixesWithZero.some((prefix) => mobileNumber.startsWith(prefix))
                ) {
                    manuallyNumberEnter.mobileNo = COUNTRY_CODE + mobileNumber.slice(1);
                    return true;
                } else {
                    return false;
                }
            } else if (mobileNumber.length === 9) {
                const prefixes = ["70", "74", "75", "76", "77", "78"];
                if (prefixes.some((prefix) => mobileNumber.startsWith(prefix))) {
                    manuallyNumberEnter.mobileNo = COUNTRY_CODE + mobileNumber;
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        } else {
            return false;
        }
    };

    const handleCloseModel = () => {
        dispatch(setLoginStep(""));
        dispatch(setActiveFormId(""));
    };

    const checkUserExists = () => {
        if (isSignInWithEmail) {
            if (isValidEmail(userEmail)) {
                let emailObj = {
                    emailAddress: userEmail,
                };
                dispatch(checkCulipaUserByEmail(emailObj));
            } else {
                setShowError("Please enter a valid email address.");
            }
        } else {
            manuallyNumberEnter.mobileNo = userPhone;
            const allFieldsAreValid = new Map([
                ["userPhone", validateMobileNumber(userPhone)],
            ]);
            setMobileError(
                allFieldsAreValid.get("userPhone")
                    ? ""
                    : "Mobile number is invalid please enter 9 digit or 13 including +256."
            );
            if (
                (isValidPhone(userPhone) &&
                    userPhone != "" &&
                    userPhone != null &&
                    validateMobileNumber(userPhone)) ||
                userPhone.length == 9 ||
                userPhone.length == 13
            ) {
                let phoneObj = {
                    mobilePhoneNumber: userPhone,
                };
                dispatch(checkCulipaUserByMobile(phoneObj));
            } else {
                setShowError("Please enter a valid phone number");
            }
        }
    };

    return (
        <React.Fragment>
            <div class={isShowLoader ? "lds-dual-ring" : ""}></div>
            <div className={isShowLoader ? "lds-dual-ring" : ""}></div>
            <div
                className="modal fade show d-block login-modal all-modals-common-class"
                aria-hidden="true"
                tabIndex="-1"
            >
                <div className="modal-dialog">
                    <div className="modal-content">
                        {/* Modal Close Icon */}
                        <div className="modal-close-btn">
                            <img
                                src="images/close.png"
                                alt="close-icon"
                                onClick={handleCloseModel}
                            />
                        </div>
                        <div className="modal-header">
                            <div className="d-flex flex-column">
                                <img
                                    className="culipa-logo"
                                    alt="culipa logo"
                                    src="images/culipaLogo.png"
                                ></img>
                                <p className="modal-subtitle fs-6">
                                    Log in using your email address or phone number registered
                                    with Culipa
                                </p>
                            </div>
                        </div>
                        <div className="modal-body mt-4">
                            {isSignInWithEmail && (
                                <div>
                                    <input
                                        type="text"
                                        placeholder="Enter your email"
                                        className="form-control"
                                        id="recipient-name"
                                        value={userEmail}
                                        onChange={(event) => onEmailChange(event)}
                                    />
                                    {showError && (
                                        <p className="error-msg">
                                            {!isValidEmail(userEmail)
                                                ? "Please enter valid email"
                                                : ""}
                                        </p>
                                    )}
                                </div>
                            )}
                            {!isSignInWithEmail && (
                                <div>
                                    <input
                                        type="number"
                                        placeholder="Enter your phone number"
                                        className="form-control"
                                        id="recipient-name"
                                        value={userPhone}
                                        onChange={(event) => onPhoneChange(event)}
                                    />
                                    {mobileError != "" ? (
                                        <span className="form-validation-span"> {mobileError}</span>
                                    ) : null}
                                </div>
                            )}
                        </div>
                        {errorMsg !== "" && <p className="error-msg">{errorMsg}</p>}
                        {formErrorMsg !== "" && (
                            <p className="error-msg">{formErrorMsg}</p>
                        )}{" "}
                        <br />
                        <div className="modal-footer" style={{ borderTop: "none" }}>
                            {isValidEmail(userEmail) ? (
                                <button
                                    className="btn btn-primary send-btn"
                                    onClick={checkUserExists}
                                >
                                    {" "}
                                    <p className="fs-6">Send 6-digit code </p>
                                </button>
                            ) : isValidPhone(userPhone) ? (
                                <button
                                    className="btn btn-primary send-btn"
                                    onClick={checkUserExists}
                                >
                                    {" "}
                                    <p className="fs-6">Send 6-digit code </p>
                                </button>
                            ) : (
                                <button
                                    className="btn btn-primary send-btn"
                                    onClick={checkUserExists}
                                    disabled
                                >
                                    {" "}
                                    <p className="fs-6">Send 6-digit code </p>
                                </button>
                            )}
                            <div className="footer-or-text">
                                <span>
                                    {" "}
                                    <p className="fs-6">or </p>{" "}
                                </span>
                            </div>
                            <button
                                className="btn signin-with-mobile-btn"
                                onClick={() => setIsSignInWithEmail(!isSignInWithEmail)}
                            >
                                {" "}
                                {isSignInWithEmail ? (
                                    <p className="fs-6">Sign in with Phone number</p>
                                ) : (
                                    <p className="fs-6">Sign in with Email</p>
                                )}{" "}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Login;

import React, { useState } from "react";

import "./header.css";
import {
  setActiveRegistrationStep,
  setJwtToken,
  setJwtAdminToken,
} from "../../pages/Registration/RegistrationSlice";
import { setLoginStep, setErrorMsg } from "../../pages/Login/LoginSlice";
import { useDispatch } from "react-redux";
import {
  setActiveFormId,
  setUpdatedVerifiedPayeeDetailsList,
} from "../../pages/FormsModal/FormSlice";
import Transfers from "../../pages/UserDashboard/Transfers";
import { setIsTransfersPopupVisble } from "../../pages/UserDashboard/userDashboardSlice";
function UserDashboardHeader(props) {
  const dispatch = useDispatch();

  const logout = () => {
    localStorage.removeItem("jwt-admin");
    localStorage.removeItem("jwt");
    localStorage.removeItem("Role");
    dispatch(setJwtToken(""));
    dispatch(setJwtAdminToken(""));
    dispatch(setActiveRegistrationStep(""));
    dispatch(setLoginStep(""));
    dispatch(setActiveFormId(""));
    dispatch(setErrorMsg(""));
  };

  const handleOnTransfer = (e) => {
    dispatch(setIsTransfersPopupVisble(true));
    dispatch(setUpdatedVerifiedPayeeDetailsList([]));
  };

  return (
    <nav className="navbar navbar-dark bg-light navbar-expand-sm custom-inner-page-banner">
      <div className="container userdashboard-container">
        <a className="navbar-brand" href="/">
          <img src="images/logo-white.png" alt="logo" />
        </a>

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#mynavbar"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="mynavbar">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item px-lg-4 px-sm-3 px-1">
              <a className="nav-link" href="javascript:void(0)">
                My Transfers
              </a>
            </li>
            <li className="nav-item px-lg-4 px-sm-3 px-1">
              <a
                className="nav-link"
                href="javascript:void(0)"
                onClick={(e) => {
                  handleOnTransfer(e);
                }}
              >
                New Bulk Transfer
              </a>
            </li>
            {/* <li className="nav-item px-lg-4 px-sm-3 px-1">
              <a className="nav-link" href="javascript:void(0)">Payees</a>
            </li>
            <li className="nav-item px-lg-4 px-sm-3 px-1">
              <a className="nav-link"> Accounts </a>
            </li> */}
            <li className="nav-item px-lg-4 px-sm-3 px-1">
              <a
                className="nav-link"
                onClick={logout}
                href="javascript:void(0)"
              >
                Logout
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default UserDashboardHeader;

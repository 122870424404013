import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    setActiveFormId,
    setProceedPayment,
    initiatePayments,
    generateReferenceId,
    setIsPaymentComplete,
    setIsHideConfirmationModelButton,
    updatedCulipaFees,
    culipaFees,
    withdrawToggle,
    setUpdatedVerifiedPayeeDetailsList,
} from "./FormSlice";
import { culipaFormatter } from "../../culipaUtils/culipaFormatter";
import { checkMobileNumber } from "../../culipaUtils/culipaUtils";
import {
    getInitiateDetails,
    setPayoutTransactionIds,
} from "../UserDashboard/userDashboardSlice";
import ConfirmationModal from "../UserDashboard/ConfirmationModal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import agent from "../../agent";

function CheckoutModal() {
    const {
        isShowLoader,
        verifiedPayeeDetailsList,
        finalPayableAmount,
        referenceId,
        userReference,
        paymentResponse,
        updatedCulipaFees,
        culipaFees,
        withdrawToggle,
        mobileMoneyFee,
        updatedVerifiedPayeeDetailsList,
    } = useSelector((state) => state.formSlice);
    const { user } = useSelector((state) => state.userDashboardSlice);
    const dispatch = useDispatch();
    const [totalPayeeAmount, setTotalPayeeAmount] = useState();
    const [culipaFee, setCulipaFee] = useState();
    const [mobileNumber, setMobileNumber] = useState();
    const [mobileErrorMessage, setMobileError] = useState();
    const [showConfirmationModel, setShowConfirmationModel] = useState();

    useEffect(() => {
        setTotalPayeeAmount(finalPayableAmount);
        if (
            user != undefined &&
            user.mobileNo != undefined &&
            user.mobileNo.length > 0
        ) {
            setMobileNumber(user.mobileNo.replace(/[\s+]/g, ""));
        }
    }, [user.mobileNo]);

    const monitorTransactionStatus = () => {
        dispatch(setIsPaymentComplete(true));
        const intervalId = setInterval(async () => {
            try {
                const response = await agent.userDashboard
                    .getPaymentStatus(referenceId)
                    .then((response) => {
                        return response.data;
                    });
                const status = response;
                if (status === "SETTLED" || status === "PARTIALLY COMPLETED") {
                    clearInterval(intervalId);
                    dispatch(setIsPaymentComplete(false));
                    setShowConfirmationModel(false);
                    dispatch(setIsHideConfirmationModelButton(false));
                    dispatch(setProceedPayment(false));
                    toast.success("Payment Received !", {
                        position: toast.POSITION.BOTTOM_LEFT,
                    });
                    dispatch(getInitiateDetails());
                }

                if (status === "ERROR" || status === "REFUSED") {
                    clearInterval(intervalId);
                    dispatch(setIsPaymentComplete(false));
                    toast.error("Payment Has Failed !", {
                        position: toast.POSITION.BOTTOM_LEFT,
                    });
                    dispatch(setIsHideConfirmationModelButton(true));
                    dispatch(getInitiateDetails());
                }
            } catch (error) {
                console.error("Error fetching transaction status:", error);
                clearInterval(intervalId);
            }
        }, 2000); // Interval of 2 seconds
    };

    const onclickBack = () => {
        dispatch(setActiveFormId("5"));
    };

    const onclickNext = () => {
        let detailsObj = checkMobileNumber(mobileNumber);
        if (!detailsObj.isValidMobile) {
            setMobileError(
                "Please enter valid mobile number (e.g , 0700123456) without any spaces or special characters."
            );
        } else {
            dispatch(setIsPaymentComplete(true));
            setMobileError("");
            processPayment();
        }
    };

    const processPayment = () => {
        dispatch(generateReferenceId());
        let mobile = mobileNumber;
        if (mobile.length === 12) {
            mobile = mobile.slice(3);
        }
        let payoutList = [];
        let payoutTransactionIds = [];
        updatedVerifiedPayeeDetailsList.length != 0
            ? updatedVerifiedPayeeDetailsList.map((payee, index) => {
                let referencePayoutId = `${referenceId}_${index + 1}`;
                payoutTransactionIds.push(referencePayoutId);
                payoutList.push({
                    account: payee.mobileNo.toString().substring(3),
                    amount: payee.amount,
                    currency: "UGX",
                    wallet: "",
                    payeeName: payee.name,
                    payeeType: "individual",
                    payeeTel: payee.mobileNo.toString().substring(3),
                    merchant: "BulkPay",
                    transactionID: `${referencePayoutId}`,
                    memo: "test",
                });
            })
            : verifiedPayeeDetailsList.map((payee, index) => {
                let referencePayoutId = `${referenceId}_${index + 1}`;
                payoutTransactionIds.push(referencePayoutId);
                payoutList.push({
                    account: payee.mobileNo.toString().substring(3),
                    amount: payee.amount,
                    currency: "UGX",
                    wallet: "",
                    payeeName: payee.name,
                    payeeType: "individual",
                    payeeTel: payee.mobileNo.toString().substring(3),
                    merchant: "BulkPay",
                    transactionID: `${referencePayoutId}`,
                    memo: "test",
                });
            });

        const initiatePaymentObject = {
            userMobileNumber: Number(mobile),
            initiatePayment: {
                account: Number(mobile),
                amount: Number(totalPayeeAmount),
                currency: "UGX",
                wallet: "airtelug",
                merchant: "BulkPay",
                transactionID: `${referenceId}`,
                memo: userReference,
            },
            payoutList: payoutList,
            csvFileName:
                localStorage.getItem("csvName") != null
                    ? localStorage.getItem("csvName")
                    : "MANUALLY_INSERTED",
            culipaFees: Number(culipaFees) + Number(mobileMoneyFee),
        };
        localStorage.setItem("randomAlphaDigit", referenceId);
        dispatch(initiatePayments(initiatePaymentObject));
        dispatch(setPayoutTransactionIds(payoutTransactionIds));
        dispatch(setUpdatedVerifiedPayeeDetailsList([]));
        setShowConfirmationModel(true);
        monitorTransactionStatus();
    };

    const handleCloseModel = () => {
        dispatch(setProceedPayment(false));
    };

    const handleOnChangeMobileNumber = (mobileNumber) => {
        let mobileNo = mobileNumber;
        setMobileNumber(mobileNo);
    };

    return (
        <React.Fragment>
            {/* Loader */}
            <div class={isShowLoader ? "lds-dual-ring" : ""}></div>

            <div
                className="modal fade show d-block all-modals-common-class"
                aria-hidden="true"
                tabIndex="-1"
            >
                <div
                    className="modal-dialog"
                    style={{ maxWidth: "680px", marginTop: "45px" }}
                >
                    <div className="modal-content" style={{ padding: "45px 40px" }}>
                        <div className="modal-close-btn">
                            <img
                                src="images/close.png"
                                alt="close-icon"
                                onClick={handleCloseModel}
                            />
                        </div>
                        <div className="modal-header justify-content-start align-items-center mb-4">
                            <span>
                                <img
                                    src="images/transfer.png"
                                    alt=""
                                    className="login-user-icon"
                                />
                            </span>
                            <div className="d-flex flex-column">
                                <h2 className="h2 fw-bold">Pay For Your Bulk Transfer</h2>
                            </div>
                        </div>
                        <div className="modal-body pb-0">
                            <h5 className="modal-step-title mb-1"> Pay with Mobile money </h5>
                            <form className="row">
                                <div className="col-12">
                                    <label
                                        htmlFor="your-number"
                                        className="col-form-label fw-normal pb-1"
                                    >
                                        {" "}
                                        Your Mobile Money Number{" "}
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="your-number"
                                        value={mobileNumber}
                                        onChange={(e) => setMobileNumber(e.target.value)}
                                        maxLength={12}
                                    />
                                    {mobileErrorMessage != "" ? (
                                        <span className="form-validation-span">
                                            {" "}
                                            {mobileErrorMessage}
                                        </span>
                                    ) : null}
                                </div>
                            </form>

                            <div className="w-100 mt-4 mb-2 validate-total-amount-wrapper">
                                <div className="d-flex gx-2 align-items-center justify-content-between mb-3">
                                    <h4 className="modal-step-title-1 h4 fw-bold mb-0">
                                        {" "}
                                        TOTAL:{"  "}
                                    </h4>
                                    <span className="fs-4 modal-step-title-1 fw-bold">
                                        {" "}
                                        UGX{" "}
                                        {culipaFormatter.currencyFormatter.format(
                                            totalPayeeAmount
                                        )}{" "}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer d-inline-block">
                            <button
                                className="btn registration-login-btn w-100"
                                onClick={onclickNext}
                            >
                                {" "}
                                Pay{" "}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {showConfirmationModel ? (
                <ConfirmationModal
                    setShowConfirmationModel={setShowConfirmationModel}
                    processPayment={processPayment}
                />
            ) : null}
        </React.Fragment>
    );
}

export default CheckoutModal;

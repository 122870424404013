import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { isValidEmail } from "../../components/Utility/ValidationUtilities";
import {
    sendAdminEmailOtp,
    sendNohaLoginRequest,
    setEmail,
    setErrorMsg,
    setLoginStep,
} from "./LoginSlice";
import { setActiveFormId, setShowLoader } from "../FormsModal/FormSlice";

function AdminLogin() {
    const dispatch = useDispatch();
    const { errorMsg } = useSelector((state) => state.loginSlice);
    const { isShowLoader } = useSelector((state) => state.formSlice);
    const [userEmail, setUserEmail] = React.useState("");
    const [showError, setShowError] = React.useState("");

    const onClicksendEmailOtp = () => {
        if (isValidEmail(userEmail) && validateAdminEmail(userEmail)) {
            dispatch(setShowLoader(true));
            setShowError(false);
            dispatch(setEmail(userEmail));
            dispatch(sendAdminEmailOtp(userEmail));
        } else {
            setShowError(true);
            dispatch(setShowLoader(false));
        }
    }

    const handleNohaLogin = () => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        if (window.location.pathname.includes("/noha/login/callback")) {
            localStorage.setItem('nohaUrl', "/noha/login/callback")
            dispatch(setShowLoader(true));
            const payload = urlParams.get('payload');
            const encodedPayload = encodeURIComponent(payload);
            dispatch(sendNohaLoginRequest(encodedPayload))
        }
    }
    React.useEffect(() => {
        handleNohaLogin()
    }, []);


    const onClickLoginViaNoha = () => {
        window.location.href = "https://noha.culipa.net/";
    };

    const validateAdminEmail = (email) => {
        const pattern = /^[a-zA-Z0-9._%+-]+@culipa\.com$/;
        return pattern.test(email);
    };

    const onEmailChange = (event) => {
        dispatch(setErrorMsg(""));
        setUserEmail(event.target.value);
    };

    const handleCloseModel = () => {
        dispatch(setLoginStep(""));
        dispatch(setActiveFormId(""));
        window.location.href = "/";
    };

    return (
        <React.Fragment>
            <div class={isShowLoader ? "lds-dual-ring" : ""}></div>
            <div
                className="modal fade show d-block login-modal all-modals-common-class"
                aria-hidden="true"
                tabIndex="-1"
            >
                <div className="modal-dialog">
                    <div className="modal-content">
                        {/* Modal Close Icon */}
                        <div className="modal-close-btn">
                            <img
                                src="images/close.png"
                                alt="close-icon"
                                onClick={handleCloseModel}
                            />
                        </div>
                        <div className="modal-header">
                            <span>
                                <img
                                    src="images/login-user.png"
                                    alt=""
                                    className="login-user-icon"
                                />
                            </span>
                            <div className="d-flex flex-column">
                                <h4 className="modal-title">Login</h4>
                                <p className="modal-subtitle">Using Admin's Email Address</p>
                            </div>
                        </div>
                        <div className="modal-body mt-4">
                            <div>
                                <input
                                    type="text"
                                    placeholder="Enter your email"
                                    className="form-control"
                                    id="recipient-name"
                                    value={userEmail}
                                    onChange={(event) => onEmailChange(event)}
                                />
                                {showError ? (
                                    <p className="error-msg">
                                        {!isValidEmail(userEmail)
                                            ? "Please enter valid email"
                                            : !validateAdminEmail(userEmail)
                                                ? "Email should be ending with @culipa.com"
                                                : ""}
                                    </p>
                                ) : null}
                            </div>
                        </div>
                        {errorMsg != "" ? <p className="error-msg">{errorMsg}</p> : null}
                        <div
                            className="modal-footer"
                            style={{ marginTop: "10px", borderTop: "none" }}
                        >
                            <button
                                className="btn btn-primary send-btn"
                                onClick={onClicksendEmailOtp}
                            >
                                {" "}
                                Send 6-digit code{" "}
                            </button>
                        </div>
                        <div
                            className="modal-footer"
                            style={{ marginTop: "10px", borderTop: "none" }}
                        >
                            <div className="footer-or-text">
                                <span> or </span>
                            </div>
                            <button className="btn signin-with-mobile-btn" onClick={onClickLoginViaNoha}> Continue with NOHA </button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default AdminLogin;
import React, { useState, useEffect ,useRef } from 'react';
import {
  BrowserRouter as Router,
  useRoutes,
} from 'react-router-dom';
import Home from "./pages/Home/Home";
import Culipa from "./pages/Culipa/Culipa";
import ErrorPage from "./pages/ErrorPage/ErrorPage";
import Login from "./pages/Login/Login";
import UserDashboard from "./pages/UserDashboard/UserDashboard";
import { useSelector, useDispatch} from "react-redux";
import {handleWebsocketCallbackResponse} from './pages/UserDashboard/userDashboardSlice';
import SockJsClient from 'react-stomp';
import Home2 from './pages/Home/Home2';

const SOCKET_URL = true  ? "https://bulkpay.culipa.com:8080/ws-message"
  : "http://localhost:8080/ws-message";

function AppRoutes() {

  const {isLogin, isAdminLogin } = useSelector((state) => state.registrationSlice);
  const dispatch = useDispatch();

  var isDashboard = isLogin || localStorage.getItem("jwt") ? true : false;
  var isAdminDashboard = isAdminLogin || localStorage.getItem("jwt-admin") ? true : false;
  const routes = useRoutes(
    [
      {path:'/', element: isDashboard ? <UserDashboard/> : <Home/>},
      {path:'/adminLogin', element: isAdminDashboard ? <UserDashboard/> : <Home2/>},
      {path:'/login', element: <Login/>},
      {path:'/culipa', element: <Culipa/>},
      {path:'*', element: <ErrorPage/>},
      {path:'/noha/login/callback', element: isAdminDashboard ? <UserDashboard/> : <Home2/>},
      {path:'/backoffice', element: isAdminDashboard ? <UserDashboard/> : <Home2/>},
    ]
  )
  return routes;
}
const WebSocketComponent = () => {
  const [message, setMessage] = useState('Your server message here.');
  const [isConnected, setIsConnected] = useState(false);
  const [isSubscriptionRequested, setIsSubscriptionRequested] = useState(false);
  const clientRef = useRef(null);
  const [topics, setTopics] = useState([]);
  const dispatch = useDispatch();
  
  const onConnected = () => {
    console.log('Connected!!');
    setIsConnected(true);
    setTopics(['/topic/message']);
  };

  const onDisconnected = () => {
    console.log('Disconnected!');
    setIsConnected(false);
    setIsSubscriptionRequested(false);
  };

  const onMessageReceived = (callbackResponse) => {
    console.log(callbackResponse);
    dispatch(handleWebsocketCallbackResponse(callbackResponse))
  };

  useEffect(() => {
    let timer;
    if (isConnected && !isSubscriptionRequested) {
      timer = setTimeout(() => {
        clientRef.current.sendMessage('/app/subscribe', 'subscribe');
        setIsSubscriptionRequested(true);
      }, 500);
    }
    return () => clearTimeout(timer);
  }, [isConnected, isSubscriptionRequested]);

  return (
    <SockJsClient
      url={SOCKET_URL}
      topics={topics}
      onConnect={onConnected}
      onDisconnect={onDisconnected}
      onMessage={onMessageReceived}
      debug={false}
      ref={(client) => (clientRef.current = client)}
    />
  );
};


function App(){
  return (
    <Router>
      <WebSocketComponent/>
      <AppRoutes />
    </Router>
  )
}

export default App;
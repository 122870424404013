import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    setActiveFormId,
    setShowLoader,
    validatePayeeDetails,
    setManuallyAddedPayeeDetailsListAtIndex,
    addMorePayee,
    uploadPayee,
    setActiveUuid,
    removeThePayee,
    deleteThePayeeObjById,
} from "./FormSlice";
import "./modal-form.css";
import { culipaFormatter } from "../../culipaUtils/culipaFormatter";
import uuid from "react-uuid";

function ManualEntryFormModal() {
    const dispatch = useDispatch();
    const ref = useRef(null);
    const {
        uploadedPayeeDetailsList,
        activeUuid,
        isShowLoader,
        manuallyAddedPayeeDetailsList,
    } = useSelector((state) => state.formSlice);
    const [editableId, setEditableId] = useState("");
    const [fullName, setFullName] = useState("");
    const [mobile, setMobile] = useState(0);
    const [amount, setAmount] = useState(0);
    const [amountError, setAmountError] = useState(0);
    const [mobileError, setMobileError] = useState(0);
    const [nameError, setNameError] = useState("");
    const [duplicatePayeeError, setDuplicatePayeeError] = useState("");
    const isAlphabateOnly = /^[a-zA-Z ]+$/;
    const isDigitOnly = /^\d+$/;

    // set uuid
    if (activeUuid == undefined || activeUuid == "") {
        dispatch(setActiveUuid(uuid()));
    }

    useEffect(() => {
        if (
            manuallyAddedPayeeDetailsList.length != 0 &&
            manuallyAddedPayeeDetailsList.at(0).id == ""
        ) {
            setEditableId(0);
        }
    }, [1]);

    const onclickBack = () => {
        dispatch(setActiveFormId("1"));
    };

    const handleCloseModel = () => {
        dispatch(setActiveFormId(""));
    };

    const manuallyPayeeObject = {
        id: "",
        name: fullName,
        mobileNo: amount,
        amount: mobile,
        statusCode: null,
        isEditable: false,
        userId: activeUuid,
    };

    const handleOnFullnameChange = (event) => {
        let name = event.target.value;
        if (isAlphabateOnly.test(name)) {
            setFullName(name);
        }
        if (event.nativeEvent.inputType === "deleteContentBackward") {
            setFullName(name);
        }
    };

    const handleOnChangeAmount = (event) => {
        let amount = event.target.value;
        if (isDigitOnly.test(amount)) {
            setAmount(amount);
        }
        if (event.nativeEvent.inputType === "deleteContentBackward") {
            setAmount(amount);
        }
    };

    const handleOnChangeMobile = (event) => {
        let mobileNumber = event.target.value;
        if (isDigitOnly.test(mobileNumber)) {
            setMobile(mobileNumber);
        }
        if (event.nativeEvent.inputType === "deleteContentBackward") {
            setMobile(mobileNumber);
        }
    };

    const onclickNext = () => {
        try {
            // if payees are added manually.
            if (manuallyAddedPayeeDetailsList.length > 0) {
                dispatch(setShowLoader(true));
                let payeeObj = {
                    payeeList: manuallyAddedPayeeDetailsList,
                    uuid: activeUuid,
                };
                dispatch(uploadPayee(payeeObj));
            } else {
                // if payees extracted from CSV.
                dispatch(setShowLoader(true));
                let objToPass = {
                    activeUuid: activeUuid,
                    toByPass: false,
                };
                dispatch(validatePayeeDetails(objToPass));
            }
        } catch (error) {
            console.log(error);
        }
    };

    const setEditableTrueForIndex = (payeeDetails, index) => {
        setFullName(payeeDetails.name);
        setMobile(payeeDetails.mobileNo);
        setAmount(payeeDetails.amount);
        setEditableId(index);
        setMobileError("");
        setAmountError("");
        setNameError("");
    };

    const handleDelete = (payeeDetailsObj) => {
        setMobileError("");
        setAmountError("");
        setNameError("");
        if (payeeDetailsObj.id === manuallyAddedPayeeDetailsList.length - 1) {
            setDuplicatePayeeError("");
        }
        if (payeeDetailsObj.isEditable != undefined) {
            dispatch(removeThePayee(payeeDetailsObj.id));
        } else {
            dispatch(setShowLoader(true));
            var reqObj = {
                id: payeeDetailsObj.id,
                uuid: activeUuid,
                isByPass: true,
            };
            dispatch(deleteThePayeeObjById(reqObj));
        }
    };

    const validateMobileNumber = (mobileNumber) => {
        const COUNTRY_CODE = 256;
        if (mobileNumber != undefined) {
            if (mobileNumber.startsWith("256") && mobileNumber.length === 12) {
                const prefixesWithCountryCode = [
                    "25670",
                    "25674",
                    "25675",
                    "25676",
                    "25677",
                    "25678",
                ];
                if (
                    prefixesWithCountryCode.some((prefix) =>
                        mobileNumber.startsWith(prefix)
                    )
                ) {
                    manuallyPayeeObject.mobileNo = mobile;
                    return true;
                } else {
                    return false;
                }
            } else if (mobileNumber.startsWith("0") && mobileNumber.length === 10) {
                const prefixesWithZero = ["070", "074", "075", "076", "077", "078"];
                if (
                    prefixesWithZero.some((prefix) => mobileNumber.startsWith(prefix))
                ) {
                    manuallyPayeeObject.mobileNo = COUNTRY_CODE + mobileNumber.slice(1);
                    return true;
                } else {
                    return false;
                }
            } else if (mobileNumber.length === 9) {
                const prefixes = ["70", "74", "75", "76", "77", "78"];
                if (prefixes.some((prefix) => mobileNumber.startsWith(prefix))) {
                    manuallyPayeeObject.mobileNo = COUNTRY_CODE + mobileNumber;
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        } else {
            return false;
        }
    };

    const validateAmount = (amount) => {
        return amount >= 500 && amount < 4000000;
    };

    const validateFullName = (fullName) => {
        return fullName !== undefined && fullName !== "";
    };

    const handleBeforeInput = (event) => {
        const input = event.data.trim();
        if (input && !isAlphabateOnly.test(input)) {
            event.preventDefault();
        }
    };

    const handleBeforeNumber = (event) => {
        const input = event.data.trim();
        if (input && !isDigitOnly.test(input)) {
            event.preventDefault();
        }
    };

    const validateDuplicatePayeeObjects = (index) => {
        let duplicatePayees = manuallyAddedPayeeDetailsList.filter((payees) => {
            return (
                payees.name == manuallyPayeeObject.name &&
                payees.mobileNo == manuallyPayeeObject.mobileNo &&
                payees.amount == manuallyPayeeObject.amount &&
                payees.id != index
            );
        });
        if (duplicatePayees.length > 0) {
            return true;
        } else {
            return false;
        }
    };

    const handleSave = (index) => {
        try {
            let isDuplicatePayessExist = false;
            manuallyPayeeObject.id = index;
            manuallyPayeeObject.name = fullName;
            manuallyPayeeObject.mobileNo = mobile;
            manuallyPayeeObject.amount = amount;

            const allFieldsAreValid = new Map([
                ["amount", validateAmount(amount)],
                ["mobile", validateMobileNumber(mobile)],
                ["fullName", validateFullName(fullName)],
            ]);

            setAmountError(
                allFieldsAreValid.get("amount")
                    ? ""
                    : "Amount should be greater than or equal to 500 and less than 4,000,000"
            );
            setMobileError(
                allFieldsAreValid.get("mobile") ? "" : "Mobile number is invalid."
            );
            setNameError(
                allFieldsAreValid.get("fullName")
                    ? ""
                    : "Full name should not be blank."
            );

            dispatch(setManuallyAddedPayeeDetailsListAtIndex(manuallyPayeeObject));
            isDuplicatePayessExist = validateDuplicatePayeeObjects(index);

            if (
                Array.from(allFieldsAreValid.values()).every((value) => value) &&
                !isDuplicatePayessExist
            ) {
                setEditableId(-1);
            }

            if (isDuplicatePayessExist) {
                setDuplicatePayeeError("Duplicate entry not allowed.");
            } else {
                setDuplicatePayeeError("");
            }
        } catch (error) {
            console.log(error);
        }
    };

    const addMorePayeeDetails = () => {
        dispatch(
            addMorePayee({
                id: manuallyAddedPayeeDetailsList.length + 1,
                fullName: "",
                mobile: 0,
                amount: 0,
                status: null,
                isEditable: false,
            })
        );
        setEditableId(manuallyAddedPayeeDetailsList.length);
        setFullName("");
        setMobile("0");
        setAmount("0");
    };

    return (
        <React.Fragment>
            {/* Loader */}
            <div class={isShowLoader ? "lds-dual-ring" : ""}></div>
            <div
                className="modal fade show d-block all-modals-common-class"
                aria-hidden="true"
                tabIndex="-1"
            >
                <div className="modal-dialog modal-max-width900 ">
                    <div className="modal-content" style={{ padding: "45px 35px" }}>
                        {/* Modal Close Icon */}
                        <div className="modal-close-btn">
                            <img
                                src="images/close.png"
                                alt="close-icon"
                                onClick={handleCloseModel}
                            />
                        </div>

                        <div className="modal-header justify-content-start align-items-center mb-4">
                            <span>
                                <img
                                    src="images/plus-icon.png"
                                    alt=""
                                    className="login-user-icon"
                                />
                            </span>
                            <div className="d-flex flex-column">
                                <h2 className="h2 m-0 fw-bold">Confirm Payee Details</h2>
                            </div>
                        </div>
                        <div className="modal-body pb-0">
                            <div
                                className="payee-details-table table-responsive invalid-payee-modal"
                                style={{ maxHeight: "300px" }}
                            >
                                <table className="table mb-0">
                                    <thead>
                                        <tr>
                                            <th className="table-full-name-column">
                                                {" "}
                                                <div className="d-flex align-items-center">
                                                    <img src="images/user.png" alt="" />{" "}
                                                    <p>Submitted Name </p>
                                                </div>
                                            </th>
                                            <th className="table-number-column">
                                                {" "}
                                                <div className="d-flex align-items-center">
                                                    <img src="images/number.png" alt="" />{" "}
                                                    <p>Mobile Money Number </p>
                                                </div>
                                            </th>
                                            <th className="table-amount-column">
                                                {" "}
                                                <div className="d-flex align-items-center">
                                                    <img src="images/amount.png" alt="" />{" "}
                                                    <p>Amount (UGX) </p>
                                                </div>
                                            </th>
                                            <th className="table-btn-status"> &nbsp; </th>
                                            <th className=""> &nbsp; </th>
                                        </tr>
                                    </thead>
                                    <tbody ref={ref}>
                                        {uploadedPayeeDetailsList.length != 0
                                            ? uploadedPayeeDetailsList?.map((payeeDetails) => {
                                                return (
                                                    <tr>
                                                        <td> {payeeDetails.name} </td>
                                                        <td> {payeeDetails.mobileNo} </td>
                                                        <td>
                                                            {" "}
                                                            {culipaFormatter.currencyFormatter.format(
                                                                payeeDetails.amount
                                                            )}{" "}
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                            : manuallyAddedPayeeDetailsList.map(
                                                (payeeDetails, index) => {
                                                    return (
                                                        <tr>
                                                            {editableId === index ? (
                                                                <>
                                                                    <td>
                                                                        {" "}
                                                                        <input
                                                                            className="table-invalid-edit-input-field"
                                                                            autoFocus
                                                                            type="text"
                                                                            value={fullName}
                                                                            onChange={(e) => {
                                                                                handleOnFullnameChange(e);
                                                                            }}
                                                                            onBeforeInput={handleBeforeInput}
                                                                        ></input>
                                                                        {nameError != "" ? (
                                                                            <span className="form-validation-span">
                                                                                {" "}
                                                                                {nameError}
                                                                            </span>
                                                                        ) : null}
                                                                    </td>
                                                                    <td>
                                                                        {" "}
                                                                        <input
                                                                            className="table-invalid-edit-input-field"
                                                                            type="text"
                                                                            value={mobile}
                                                                            onChange={(e) => {
                                                                                handleOnChangeMobile(e);
                                                                            }}
                                                                            maxLength={12}
                                                                            onBeforeInput={handleBeforeNumber}
                                                                        ></input>
                                                                        {mobileError != "" ? (
                                                                            <span className="form-validation-span">
                                                                                {" "}
                                                                                {mobileError}
                                                                            </span>
                                                                        ) : null}
                                                                    </td>
                                                                    <td>
                                                                        {" "}
                                                                        <input
                                                                            className="table-invalid-edit-input-field"
                                                                            type="text"
                                                                            value={amount}
                                                                            onChange={(e) => {
                                                                                handleOnChangeAmount(e);
                                                                            }}
                                                                        ></input>
                                                                        {amountError != "" ? (
                                                                            <span className="form-validation-span">
                                                                                {" "}
                                                                                {amountError}
                                                                            </span>
                                                                        ) : null}
                                                                    </td>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <td
                                                                        onClick={() =>
                                                                            setEditableTrueForIndex(
                                                                                payeeDetails,
                                                                                index
                                                                            )
                                                                        }
                                                                    >
                                                                        {" "}
                                                                        {payeeDetails.name}{" "}
                                                                    </td>
                                                                    <td
                                                                        onClick={() =>
                                                                            setEditableTrueForIndex(
                                                                                payeeDetails,
                                                                                index
                                                                            )
                                                                        }
                                                                    >
                                                                        {" "}
                                                                        {payeeDetails.mobileNo != undefined &&
                                                                            payeeDetails.mobileNo != 0
                                                                            ? `+${payeeDetails.mobileNo
                                                                                .toString()
                                                                                .substring(
                                                                                    0,
                                                                                    3
                                                                                )} ${payeeDetails.mobileNo
                                                                                    .toString()
                                                                                    .substring(3)}`
                                                                            : null}{" "}
                                                                    </td>
                                                                    <td
                                                                        onClick={() =>
                                                                            setEditableTrueForIndex(
                                                                                payeeDetails,
                                                                                index
                                                                            )
                                                                        }
                                                                    >
                                                                        {" "}
                                                                        {culipaFormatter.currencyFormatter.format(
                                                                            payeeDetails.amount
                                                                        )}{" "}
                                                                    </td>
                                                                </>
                                                            )}
                                                            <td>
                                                                {" "}
                                                                {editableId === index ? (
                                                                    <button
                                                                        onClick={() => handleSave(index)}
                                                                        className="btn confirm-save-btn"
                                                                        style={{ backgroundColor: "#3dad49" }}
                                                                    >
                                                                        {" "}
                                                                        Save{" "}
                                                                    </button>
                                                                ) : payeeDetails.status ? (
                                                                    <button className="btn error-btn">
                                                                        {" "}
                                                                        {payeeDetails.status}{" "}
                                                                    </button>
                                                                ) : null}{" "}
                                                            </td>
                                                            <td className="text-end">
                                                                {manuallyAddedPayeeDetailsList.length > 1 ? (
                                                                    <img
                                                                        src="images/delete.png"
                                                                        alt=""
                                                                        className="table-invalid-delete-btn"
                                                                        onClick={(e) => {
                                                                            handleDelete(payeeDetails);
                                                                        }}
                                                                    />
                                                                ) : (
                                                                    <span>&nbsp;</span>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    );
                                                }
                                            )}
                                    </tbody>
                                </table>
                                {duplicatePayeeError != "" ? (
                                    <div className="mt-4 mb-2" style={{ cursor: "pointer" }}>
                                        <span className="form-validation-span">
                                            {" "}
                                            {duplicatePayeeError}
                                        </span>
                                    </div>
                                ) : null}

                                {uploadedPayeeDetailsList.length === 0 ? (
                                    <div className="mt-4 mb-2" style={{ cursor: "pointer" }}>
                                        <span
                                            className="payees-details-add-new-icon"
                                            onClick={addMorePayeeDetails}
                                        >
                                            {" "}
                                            &#43;{" "}
                                        </span>
                                        <span
                                            style={{
                                                color: "#06303c",
                                                fontSize: "15px",
                                                fontWeight: "500",
                                            }}
                                            onClick={addMorePayeeDetails}
                                        >
                                            {" "}
                                            Add Payee
                                        </span>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        <div className="modal-footer justify-content-center px-4 mt-2">
                            {uploadedPayeeDetailsList.length != 0 ? (
                                <button
                                    className="btn float-start registration-next-btn"
                                    onClick={onclickBack}
                                >
                                    Back
                                </button>
                            ) : null}
                            <button
                                className={
                                    uploadedPayeeDetailsList.length != 0
                                        ? "btn float-end registration-login-btn"
                                        : "w-100  btn float-end registration-login-btn"
                                }
                                onClick={onclickNext}
                            >
                                Confirm
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default ManualEntryFormModal;

import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import uuid from "react-uuid";
import sampleCsvFile from "../../file/BulkPay-sample.csv";
import {
    setActiveFormId,
    setActiveUuid,
    setFormErrorMsg,
    setFile,
    setShowLoader,
    uploadFile,
} from "./FormSlice";

function UploadModal() {
    const { file, formErrorMsg, isShowLoader } = useSelector(
        (state) => state.formSlice
    );
    const dispatch = useDispatch();
    const onclickBack = () => {
        dispatch(setActiveFormId(""));
        dispatch(setFile(""));
        dispatch(setFormErrorMsg(""));
    };

    const onclickNext = () => {
        if (file && file.name) {
            const uuidValue = uuid();
            dispatch(setActiveUuid(uuidValue));
            const data = new FormData();
            data.append("file", file);
            data.append("uuid", uuidValue);
            dispatch(setShowLoader(true));
            dispatch(uploadFile(data));
            dispatch(setFormErrorMsg(""));
        } else {
            dispatch(setFormErrorMsg("Please Select the file to upload"));
        }
    };
    const inputRefFile = useRef(null);

    const handleChange = (e) => {
        if (e.target.files[0].name != undefined) {
            let fileName = e.target.files[0].name;
            const fileExtension = fileName.split(".").pop();
            if (["csv", "txt", "xls", "xlsx"].includes(fileExtension)) {
                dispatch(setFile(e.target.files[0]));
                localStorage.setItem("csvName", fileName);
                dispatch(setFormErrorMsg(""));
            } else {
                dispatch(
                    setFormErrorMsg(
                        "Only .csv, .txt, .xls and .xlsx files are supported."
                    )
                );
            }
        }
    };

    const handleCloseModel = () => {
        dispatch(setActiveFormId(""));
        dispatch(setFile(""));
        dispatch(setFormErrorMsg(""));
    };
    const addTimestampToURL = (url) => {
        const timestamp = new Date().getTime(); // Generate a timestamp
        return `${url}?timestamp=${timestamp}`; // Append the timestamp as a query parameter
    };

    const updatedURL = addTimestampToURL(sampleCsvFile);

    return (
        <React.Fragment>
            {/* Loader */}
            <div class={isShowLoader ? "lds-dual-ring" : ""}></div>

            <div
                className="modal fade show d-block all-modals-common-class"
                aria-hidden="true"
                tabIndex="-1"
            >
                <div className="modal-dialog modal-max-width">
                    <div className="modal-content">
                        {/* Modal Close Icon */}
                        <div className="modal-close-btn">
                            <img
                                src="images/close.png"
                                alt="close-icon"
                                onClick={handleCloseModel}
                            />
                        </div>
                        <div className="modal-header row justify-content-start mb-2">
                            <div className="col-12 align-items-center d-flex">
                                <span>
                                    <img
                                        src="images/plus-icon.png"
                                        alt=""
                                        className="login-user-icon"
                                    />
                                </span>
                                <h2 className="h2 m-0 fw-bold">
                                    Upload CSV, TXT, XLS or XLSX.
                                </h2>
                            </div>

                            <div className="col-12">
                                <p className="modal-subtitle mt-3 ms-5">
                                    {" "}
                                    Your file should have 3 columns with a header row:{" "}
                                    <strong> Full Name, Number, Amount. </strong>{" "}
                                </p>
                                <p className="modal-subtitle ms-5 mt-1">
                                    {" "}
                                    <strong>
                                        <i>
                                            {" "}
                                            Download our{" "}
                                            <a href={updatedURL} download="BulkPay-sample.csv">
                                                sample file here.
                                            </a>{" "}
                                        </i>
                                    </strong>
                                </p>
                            </div>
                        </div>
                        <div className="modal-body">
                            <div class="row">
                                <div class="col-md-12">
                                    <form method="post" id="#">
                                        <div class="form-group files upload-file-input-box p-2">
                                            <span>
                                                {" "}
                                                <img src="images/upload-file.png" alt="" />
                                            </span>
                                            {file && file.name ? (
                                                <span> {file.name} </span>
                                            ) : (
                                                <>
                                                    <div className="row flex-column justify-content-center align-items-center">
                                                        <div
                                                            className="col-12 "
                                                            style={{ fontSize: "90%" }}
                                                        >
                                                            {" "}
                                                            <p>
                                                                <strong> Drag & drop </strong> or click to
                                                                select file{" "}
                                                            </p>
                                                        </div>
                                                        <div
                                                            className="col-12 text-center "
                                                            style={{ fontSize: "90%" }}
                                                        >
                                                            {" "}
                                                            <p>
                                                                <small>
                                                                    {" "}
                                                                    <strong> Maximum file size 2 MB </strong>
                                                                </small>{" "}
                                                            </p>
                                                        </div>
                                                        <input
                                                            type="file"
                                                            ref={inputRefFile}
                                                            id="csvFileInput"
                                                            accept=".csv, .txt, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                                            onChange={handleChange}
                                                            className="form-control"
                                                        />
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        {<p className="error-msg">{formErrorMsg ? formErrorMsg : ""}</p>}
                        <div className="modal-footer">
                            <div className="row w-100">
                                <div className="col-6">
                                    {" "}
                                    <button
                                        className="btn float-start registration-next-btn back-black-btn"
                                        onClick={onclickBack}
                                    >
                                        Back
                                    </button>
                                </div>
                                <div className="col-6">
                                    <button
                                        className="btn float-end registration-login-btn"
                                        onClick={onclickNext}
                                    >
                                        Upload
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default UploadModal;

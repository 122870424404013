import { takeLatest, put, call, fork } from "redux-saga/effects";
import agent from "../../agent";
import { loginWithAdminEmail, loginWithEmail, loginWithMobile, sendAdminEmailOtp, sendEmailOtp, sendMobileOtp, sendNohaLoginRequest, setErrorMsg, setLoginStep } from "./LoginSlice";
import { setJwtToken, setRole, setJwtAdminToken } from "../Registration/RegistrationSlice";
import { setActiveFormId, setShowLoader } from "../FormsModal/FormSlice";
// import { loginUser, setActiveRegistrationStep, setIsLoginButtonEnable, setJwtToken, setRegistrationDetails, setRegistrationError, setSuccessMsg, takeRegisterUser, verifyOtpOnEmail } from "./RegistrationSlice";
import verifiedPayeeDetailsList from '../FormsModal/FormSlice';
function* onSendEmailOtpAsync(data) {
  try {
    const result = yield call(agent.User.sendEmailOtp, data.payload);
    if (result && result.status === 200) {
      yield put(setErrorMsg(''));
      yield put(setLoginStep('2'));
      yield put(setShowLoader(false));
    } else if (result && result.response) {
      yield put(setErrorMsg(result.response.data.message));
    }
    yield put(setShowLoader(false));
  } catch (e) {
    console.log(e);
    yield put(setShowLoader(false));
  }
}

function* onSendMobileOtpAsync(data) {
  try {
    const result = yield call(agent.User.sendMobileOtp, data.payload);
    if (result && result.status === 200) {
      yield put(setErrorMsg(''));
      yield put(setLoginStep('2'));
      yield put(setShowLoader(false));
    } else if (result && result.response) {
      yield put(setErrorMsg(result.response.data.message));
    }
    yield put(setShowLoader(false));
  } catch (e) {
    console.log(e);
    yield put(setShowLoader(false));
  }
}

function* sendOtpToAdminEmailAsync(data) {
  try {
    const result = yield call(agent.User.sendAdminEmailOtp, data.payload);
    if (result && result.status === 200) {
      yield put(setErrorMsg(''));
      yield put(setLoginStep('4'));
      yield put(setShowLoader(false));
    } else if (result && result.response) {
      yield put(setErrorMsg(result.response.data.message));
    }
    yield put(setShowLoader(false));
  } catch (e) {
    console.log(e);
    yield put(setShowLoader(false));
  }
}

function* sendNohaLoginRequestAsync(data) {
  try {
    const result = yield call(agent.User.sendNohaLoginRequest, data.payload);
    const email = result.data.data.body.data.email;
    const receivedOtp = "123456";
    var EmailLoginOtp = {
      email: email,
      otp: receivedOtp
  }
    const result2 = yield call(agent.User.adminLoginNoha, EmailLoginOtp)
    localStorage.setItem('jwt-admin', result2.data.data.jwtToken)
    localStorage.setItem('Role', result2.data.data.role)
    if (result && result.status === 200) {
      yield put(setErrorMsg(''));
      yield put(setJwtAdminToken(result2.data.data.jwtToken))
      yield put(setRole(result2.data.data.role))
      if (verifiedPayeeDetailsList.length != 0) {
        window.location.href = "/backoffice";
        yield put(setActiveFormId("5"))
      }
    } else if (result && result.response) {
      console.log(result.response);
      yield put(setLoginStep("4"));
      yield put(setErrorMsg(result.response.data.message));
      yield put(setShowLoader(false));
    }
  } catch (e) {
    console.log(e);
    yield put(setShowLoader(false));
  }
}

function* onLoginAsync(data) {
  try {
    const result = yield call(agent.User.loginUser, data.payload);
    if (result && result.status === 200) {
      localStorage.removeItem("jwt-admin");
      localStorage.removeItem("Role");
      yield put(setJwtToken(result.data.data))
      yield put(setShowLoader(false));
      if (verifiedPayeeDetailsList.length != 0) {
        yield put(setActiveFormId("5"))
      }
    } else if (result && result.response) {
      yield put(setLoginStep("2"));
      yield put(setErrorMsg(result.response.data.message));
      yield put(setShowLoader(false));
    }
  } catch (e) {
    console.log(e);
  }
}

function* onLoginAsyncMobile(data) {
  console.log("data->>", data.payload);
  try {
    const result = yield call(agent.User.loginUserMobile, data.payload);
    if (result && result.status === 200) {
      localStorage.removeItem("jwt-admin");
      localStorage.removeItem("Role");
      yield put(setJwtToken(result.data.data))
      yield put(setShowLoader(false));
      if (verifiedPayeeDetailsList.length != 0) {
        yield put(setActiveFormId("5"))
      }
    } else if (result && result.response) {
      yield put(setLoginStep("2"));
      yield put(setErrorMsg(result.response.data.message));
      yield put(setShowLoader(false));
    }
  } catch (e) {
    console.log(e);
  }
}

function* onAdminLoginAsync(data) {
  try {
    const result = yield call(agent.User.adminLogin, data.payload);
    if (result && result.status === 200) {
      localStorage.removeItem("jwt");
      yield put(setJwtAdminToken(result.data.data.jwtToken))
      yield put(setRole(result.data.data.role))
      yield put(setShowLoader(false));
      if (verifiedPayeeDetailsList.length != 0) {
        yield put(setActiveFormId("5"))
      }
    } else if (result && result.response) {
      yield put(setLoginStep("4"));
      yield put(setErrorMsg(result.response.data.message));
      yield put(setShowLoader(false));
    }
  } catch (e) {
    console.log(e);
  }
}

function* onLogin() {
  // yield takeLatest(takeRegisterUser.type, onRegistrationAsync);
  // yield takeLatest(verifyOtpOnEmail.type, onVerifyOtpOnEmailAsync);
  // yield takeLatest(loginUser.type, onLoginAsync);
  yield takeLatest(sendEmailOtp.type, onSendEmailOtpAsync);
  yield takeLatest(sendMobileOtp.type, onSendMobileOtpAsync);
  yield takeLatest(loginWithEmail.type, onLoginAsync);
  yield takeLatest(loginWithMobile.type, onLoginAsyncMobile);
  yield takeLatest(sendAdminEmailOtp.type, sendOtpToAdminEmailAsync);
  yield takeLatest(sendNohaLoginRequest.type, sendNohaLoginRequestAsync);
  yield takeLatest(loginWithAdminEmail.type, onAdminLoginAsync);
}

export const loginSaga = [fork(onLogin)];
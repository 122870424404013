import { takeLatest, put, call, fork } from "redux-saga/effects";
import agent from "../../agent";
import { loginUser, setActiveRegistrationStep, setIsLoginButtonEnable, setJwtToken, setRegistrationDetails, setRegistrationError, setSuccessMsg, takeRegisterUser, verifyOtpOnEmail, verifyOtpOnPhone } from "./RegistrationSlice";
import {setShowLoader} from "../FormsModal/FormSlice";

function* onRegistrationAsync(data) {
  try {
    yield put(setShowLoader(true));
    const result = yield call(agent.User.register, data.payload);
    if (result && result.status === 200) {
      yield put(setActiveRegistrationStep('4'));
      yield put(setShowLoader(false));
    //   localStorage.setItem("jwt", result.data.token);
    } else if (result && result.response) {
      yield put(setRegistrationError(result.response.data.message));
      yield put(setShowLoader(false));
    }
  } catch (e) {
    yield put(setShowLoader(false));
    console.log(e);
  }
}

function* onVerifyOtpOnEmailAsync(data) {
  try {
    const result = yield call(agent.User.verifyOtpOnEmail, data.payload);
    if (result && result.status === 200) {
      yield put(setSuccessMsg(result.data.message));
      yield put(setRegistrationError(""));
      yield put(setIsLoginButtonEnable(true))
    //   localStorage.setItem("jwt", result.data.token);
    } else if (result && result.response) {
      yield put(setIsLoginButtonEnable(false))
      yield put(setRegistrationError(result.response.data.message));
    }
  } catch (e) {
    console.log(e);
  }
}

function* onVerifyOtpOnPhoneAsync(data) {
  try {
    const result = yield call(agent.User.verifyOtpOnPhone, data.payload);
    if (result && result.status === 200) {
      yield put(setSuccessMsg(result.data.message));
      yield put(setRegistrationError(""));
      yield put(setIsLoginButtonEnable(true))
    //   localStorage.setItem("jwt", result.data.token);
    } else if (result && result.response) {
      yield put(setIsLoginButtonEnable(false))
      yield put(setRegistrationError(result.response.data.message));
    }
  } catch (e) {
    console.log(e);
  }
}

function* onLoginAsync(data) {
  try {
    const result = yield call(agent.User.loginUser, data.payload);
    if (result && result.status === 200) {
      yield put(setSuccessMsg(result.data.message));
      yield put(setRegistrationError(""));
      yield put(setJwtToken(result.data.data))
    } else if (result && result.response) {
      yield put(setIsLoginButtonEnable(false))
      yield put(setRegistrationError(result.response.data.message));
    }
  } catch (e) {
    console.log(e);
  }
}

function* onRegistration() {
  yield takeLatest(takeRegisterUser.type, onRegistrationAsync);
  yield takeLatest(verifyOtpOnEmail.type, onVerifyOtpOnEmailAsync);
  yield takeLatest(verifyOtpOnPhone.type, onVerifyOtpOnPhoneAsync);
  yield takeLatest(loginUser.type, onLoginAsync);
  
}

export const registrationSaga = [fork(onRegistration)];
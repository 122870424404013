import { useDispatch, useSelector } from "react-redux";
import { loginUser, setActiveRegistrationStep, takeRegisterUser, verifyOtpOnEmail, resetUserRegistration } from "./RegistrationSlice";
import { useState } from "react";
import { setActiveFormId } from "../FormsModal/FormSlice";

function RegistrationStepFour(props) {
    const [confirmEmailOtp, setConfirmEmailOtp] = useState();
    const [confirmPhoneNumberOtp, setConfirmPhoneNumberOtp] = useState();
    const { registrationDetails, registrationError, successMsg, isLoginButtonEnable } = useSelector((state) => state.registrationSlice);
    const { verifiedPayeeDetailsList } = useSelector((state) => state.formSlice);
    const dispatch = useDispatch();

    const onClickVerifyEmailOtp = (e) => {
        e.preventDefault();
        var requestObj = {
            email: registrationDetails.email,
            otp: confirmEmailOtp
        }
        dispatch(verifyOtpOnEmail(requestObj));
    }

    const onClickVerifyMobileOtp = () => {
        console.log("Mobile varification ..");
    }

    const onClickPrevious = () => {
        dispatch(setActiveRegistrationStep("3"));
    }

    const onChnageEmail = (e) => {
        setConfirmEmailOtp(e.target.value);
    }

    const onChnageMobileNumber = (e) => {
        setConfirmPhoneNumberOtp(e.target.value);
    }

    const onLogin = () => {
        var requestObj = {
            email: registrationDetails.email,
            otp: confirmEmailOtp
        }
        dispatch(loginUser(requestObj));
        if (verifiedPayeeDetailsList.length != 0) {
            dispatch(setActiveFormId("5"));
        }
    }
    const handleCloseModel = () => {
        dispatch(setActiveRegistrationStep(""));
        dispatch(resetUserRegistration());
    }
    return (
        <div className="modal fade show d-block all-modals-common-class" aria-hidden="true" tabindex="-1">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-close-btn">
                        <img src="images/close.png" alt="close-icon" onClick={handleCloseModel} />
                    </div>
                    <div className="modal-header d-block mb-1">
                        <h4 className="modal-title text-center lh-sm" style={{ letterSpacing: '0px', fontSize: '38px' }}>
                            Verify Your Email & <br /> Phone Number
                        </h4>
                        <p className="modal-subtitle text-center" style={{ fontSize: '13px' }}>Please enter the 4-Digit verification code that was <br />sent to your email and phone number. <br /> The code is valid for 30 minutes.</p>
                    </div>
                    <div className="modal-body mt-3">
                        <form className="row g-3">
                            <div className='col-9 mt-0'>
                                <label for="recipient-name" className="col-form-label">Confirm Your Email Address:</label>
                                <input type="text" className="form-control" id="recipient-name" value={confirmEmailOtp} onChange={(event) => onChnageEmail(event)} />
                            </div>
                            <div className='col-3 mt-0'>
                                <button className="btn registration-verify-btn" onClick={(e) => onClickVerifyEmailOtp(e)}>Verify</button>
                            </div>
                            <div className='col-9 mt-0'>
                                <label for="recipient-name" className="col-form-label">Confirm Your Phone Number:</label>
                                <input type="text" className="form-control" id="recipient-name" value={confirmPhoneNumberOtp} onChange={(event) => onChnageMobileNumber(event)} />
                            </div>
                            <div className='col-3 mt-0'>
                                <button className="btn registration-verify-btn" onClick={onClickVerifyMobileOtp}>Verify</button>
                            </div>
                        </form>
                    </div>
                    <div className="fs-6 fw-light mb-2" style={{ color: 'red' }}>{registrationError}</div>
                    <div className="fs-6 fw-light mb-2" style={{ color: 'green' }}>{successMsg}</div>
                    <div className="modal-footer" style={{ borderTop: "none" }}>
                        <button className="btn float-start registration-next-btn" onClick={onClickPrevious} style={{ backgroundColor: '#06303c', color: '#fff' }}>Back</button>
                        <button className="btn float-end registration-login-btn" disabled={!isLoginButtonEnable} onClick={isLoginButtonEnable ? onLogin : null} >Login</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RegistrationStepFour;
import { createSlice } from "@reduxjs/toolkit";
import { act } from "react-dom/test-utils";

const initialState = {
  activeRegistrationStep: "",
  registrationDetails: {},
  registrationError: '',
  isLogin: localStorage.getItem("jwt") ? true : false,
  isAdminLogin: localStorage.getItem("jwt-admin") ? true : false,
  successMsg: "",
  isLoginButtonEnable: false,
  jwtToken: "",
  jwtAdminToken: "",
  countryCode:'',
  userRegistration:{
    orgName:"",
    name:"",
    surname:"",
    mobNumber:"",
    email:"",
    sex:"",
    dateOfBirth:new Date(),
    nationalIdNumber:"",
    nationalIdCardNumber:"",
    countryCode:"ug",
    confirmEmail:"",
    confirmPhoneNumber:""
  }
};

if (localStorage.getItem("jwt")) {
  initialState.isLogin = true;
}

if (localStorage.getItem("jwt-admin")) {
  initialState.isAdminLogin = true;
}

const registrationSlice = createSlice({
  name: "registration",
  initialState,
  reducers: {
    setSuccessMsg: (state, action) => {
      state.successMsg = action.payload;
      state.registrationError = "";
    },
    setIsLoginButtonEnable: (state, action) => {
      state.isLoginButtonEnable = action.payload
    },
    takeRegisterUser(state, registrationDetails) {
      state.registrationError = "";
    },
    verifyOtpOnEmail(state, otpOnMobile) {
      state.registrationError = "";
    },
    verifyOtpOnPhone(state, otpOnMobile) {
      state.registrationError = "";
    },
    setRegistrationDetails: (state, action) => {
      state.registrationDetails = action.payload;
    },
    setRegistrationError: (state, action) => {
      state.registrationError = action.payload
    },
    setActiveRegistrationStep: (state, action) => {
      state.activeRegistrationStep = action.payload;
      state.registrationError = "";
    },
    setJwtToken: (state, action) => {
      localStorage.setItem("jwt", action.payload);
      state.jwtToken = action.payload
      state.isLogin = action.payload != "" ? true : false
    },
    setJwtAdminToken: (state, action) => {
      localStorage.setItem("jwt-admin", action.payload);
      state.jwtAdminToken = action.payload
      state.isAdminLogin = action.payload != "" ? true : false
    },
    registerUser(state, userEmail, userPassword) {
      state.registrationError = "";
    },

    loginUser(state, loginRequest) {
      state.registrationError = "";
    },

    logout: (state, action) => {
      localStorage.removeItem("jwt");
      state.isLogin = false;
    },
    setLoginError: (state, action) => {
      localStorage.removeItem("jwt");
      state.loginError = action.payload;
      state.isLogin = false;
    },
    getCurrentUser(state, action) { },

    setPhoneCountryCode(state, action) {
      state.countryCode = action.payload
    },
    setUserRegistration(state,action){
      let infoPayload = action.payload;
      state.userRegistration[infoPayload.key] = infoPayload.info;
    },
    resetUserRegistration(state,action){
      state.userRegistration = {
        orgName: "",
        name: "",
        surname: "",
        mobNumber: "",
        email: "",
        sex: "",
        dateOfBirth: new Date(),
        nationalIdNumber: "",
        nationalIdCardNumber: "",
        countryCode: "ug",
        confirmEmail: "",
        confirmPhoneNumber: ""
      };
    },
    setRole: (state, action) => {
      localStorage.setItem("Role", action.payload);
      state.role = action.payload
    },
  },
});

export const {
  registerUser,
  setLoginUser,
  setLoginError,
  getCurrentUser,
  logout,
  setRegistrationDetails,
  setActiveRegistrationStep,
  takeRegisterUser,
  setRegistrationError,
  verifyOtpOnEmail,
  verifyOtpOnPhone,
  setSuccessMsg,
  setIsLoginButtonEnable,
  loginUser,
  setJwtToken,
  setPhoneCountryCode,
  resetUserRegistration,
  setUserRegistration,
  setRole,
  setJwtAdminToken
} = registrationSlice.actions;
export default registrationSlice.reducer;
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loginStep: '',
  email: "",
  mobile: "",
  emailOtp: "",
  mobileOtp: "",
  isLogin: localStorage.getItem("jwt")?true:false,
  successMsg: "",
  errorMsg: "",
  isLoginButtonEnable: false,
  jwtToken: "",
  userOtpMap: new Map(),
};

if (localStorage.getItem("jwt")) {
  initialState.isLogin = true;
}

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setMobile: (state, action) => {
      state.mobile = action.payload;
    },
    setEmailOtp: (state, action) => {
      state.emailOtp = action.payload
    },
    setMobileOtp: (state, action) => {
      state.mobileOtp = action.payload
    },
    setJwtToken : (state, action) => {
      state.jwtToken=action.payload
    },
    loginWithEmail(reqObj) {},
    loginWithMobile(reqObj) {},
    setLoginStep: (state, action) => {
      state.loginStep = action.payload
    },
    setErrorMsg:(state, action) => {
      state.errorMsg = action.payload
    },
    sendEmailOtp(email) { },
    sendMobileOtp(mobile) { },
    sendAdminEmailOtp(email) { },
    sendNohaLoginRequest(){ },
    sendAdminToNohaPageForLogin(){},
    setUserOtpMap: (state, action) => {
      const {key, value} = action.payload;
      state.userOtpMap.set(key, value);
    },
    loginWithAdminEmail(reqObj) {}
  },
});

export const {
  setEmail,
  setMobile,
  sendEmailOtp,
  sendMobileOtp,
  setLoginStep,
  setErrorMsg,
  setEmailOtp,
  setMobileOtp,
  setUserOtpMap,
  loginWithEmail,
  loginWithMobile,
  setJwtToken,
  email,
  sendAdminEmailOtp,
  sendAdminToNohaPageForLogin,
  sendNohaLoginRequest,
  loginWithAdminEmail
} = loginSlice.actions;
export default loginSlice.reducer;
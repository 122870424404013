export function checkMobileNumber(mobileNumber) {
    const COUNTRY_CODE = "256";
    let detailsObj={
        isValidMobile:false,
        errorMessage:""
    }
    if (mobileNumber != undefined && mobileNumber !="" && mobileNumber != null) {
        if (mobileNumber.startsWith('256') && mobileNumber.length === 12) {
            const prefixesWithCountryCode = ["25670", "25674", "25675", "25676", "25677", "25678"];
            if(prefixesWithCountryCode.some(prefix => mobileNumber.startsWith(prefix))){
                detailsObj.isValidMobile = true;
                detailsObj.errorMessage = "";
            }else{
                detailsObj.isValidMobile = false;
                detailsObj.errorMessage = "Mobile number is invalid.";
            }

        } else if (mobileNumber.startsWith('0') && mobileNumber.length === 10) {
            const prefixesWithZero = ["070", "074", "075", "076", "077", "078"];
            if(prefixesWithZero.some(prefix => mobileNumber.startsWith(prefix))){
                detailsObj.isValidMobile = true;
                detailsObj.errorMessage ="";
            }else{
                detailsObj.isValidMobile = false;
                detailsObj.errorMessage = "Mobile number is invalid. It should start with '256' and 12 digits long.";
            }

        } else if (mobileNumber.length === 9) {
            const prefixes = ["70", "74", "75", "76", "77", "78"];
            if(prefixes.some(prefix => mobileNumber.startsWith(prefix))){
                detailsObj.isValidMobile = true;
                detailsObj.errorMessage ="";
            }else{
                detailsObj.isValidMobile = false;
                detailsObj.errorMessage = "Mobile number is invalid. It should start with '256' and 12 digits long.";
            }
        } else {
            detailsObj.isValidMobile = false;
            detailsObj.errorMessage = "Mobile number is invalid.";
        }
    } else {
        detailsObj.isValidMobile = false;
        detailsObj.errorMessage = "Mobile number is required.";
    }
    return detailsObj;
}

export function dateFormatter(inputDate) {
    const date = new Date(inputDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
  
    return `${year}-${month}-${day} ${hours}:${minutes}`;
  }
  
export function handleOnCheckMobileNumber(event, phoneNumber, error ,countryCode) {
    let mobileNumber = event.target.value;
    if (countryCode === '256' || countryCode === 'ug') {
        if (mobileNumber.length === 9) {
            phoneNumber(`256${mobileNumber}`);
            error("")
            return true;
        } else if (mobileNumber.length === 10 && mobileNumber.startsWith("07")) {
            let sliceNumber = mobileNumber.slice(1);
            phoneNumber(`256${sliceNumber}`);
            error("")
            return true;
        } else if (mobileNumber.length === 12 && mobileNumber.startsWith("2567")) {
            phoneNumber(mobileNumber);
            error("")
            return true;
        } else if (mobileNumber === "" || (mobileNumber === countryCode)) {
            error("please enter the mobile number.")
            return false;
        } else {
            error("mobile number (e.g , 0700123456) without any spaces or special characters.")
            return false;
        }
    } else {
        if (mobileNumber === countryCode || mobileNumber === "") {
            error("please enter the mobile number.")
            return false;
        }else{
            error("")
            return true;
        }
    }

}
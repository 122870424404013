import React from "react";

import "./footer.css";

function Footer() {
    return (
        <div className="footer-container">
            <div className="container footer-contain">
                <div className="row justify-content-center align-items-start">
                    <div className="col-md-12">
                        <div className="footer-logo">
                            <img src="images/logo-dark.png" alt="" />
                        </div>
                    </div>
                </div>

                <div className="row justify-content-center align-items-start">
                    <div className="col col-md-4">
                        <div className="footer-link-box">
                            <h3 className="footer-heading"> About Us </h3>
                            <ul className="list-unstyled">
                                <li>
                                    <a className="nav-link" href="https://culipa.com">
                                        Culipa
                                    </a>
                                </li>
                                <li>
                                    <a className="nav-link" href="https://lipi.me">
                                        {" "}
                                        Lipi{" "}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col">
                        <div className="footer-link-box">
                            <h3 className="footer-heading"> Contact Us </h3>
                            <p>
                                {" "}
                                P.O Box 16060, Kampala, Uganda <br /> +256 393 246 095{" "}
                            </p>
                            <a href="/"> mail@culipa.com </a>
                        </div>
                    </div>
                    {/* <div className="col-md-3">
                        <div className="footer-link-box">
                            <h3 className="footer-heading"> Follow Us </h3>
                            <p> SUBSCRIBE TO OUR NEWSLETTER </p>
                            <input type="text" className="" placeholder="Your Email" />
                            <button className="theme-green-btn"> Subscribe </button>
                        </div>
                    </div> */}
                </div>

                <hr className="footer-separation-line" />

                <div className="row justify-content-center align-items-start">
                    <div className="col-md-9">
                        <div className="bottom-footer-menu">
                            <span> User Agreement </span>
                            <span> Privacy Policy </span>
                            <span> Culipa </span>
                            <span> Help </span>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="bottom-footer-media-logo float-end">
                            <a href="https://twitter.com/culipaHQ">
                                <img src="images/twitter-logo.png" alt="" />
                            </a>
                            <a href="https://www.facebook.com/culipaHQ">
                                <img src="images/facebook-logo.png" alt="" />
                            </a>
                            <a href="https://ug.linkedin.com/company/culipa">
                                <img src="images/linkedin-logo.png" alt="" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;



const countryOptions = [
  { value: 'af', label: '+93 Afghanistan' },
  { value: 'ax', label: '+358 Åland Islands' },
  { value: 'al', label: '+355 Albania' },
  { value: 'dz', label: '+213 Algeria' },
  { value: 'as', label: '+1-684 American Samoa' },
  { value: 'ad', label: '+376 Andorra' },
  { value: 'ao', label: '+244 Angola' },
  { value: 'ai', label: '+1-264 Anguilla' },
  { value: 'aq', label: '+672 Antarctica' },
  { value: 'ag', label: '+1-268 Antigua and Barbuda' },
  { value: 'ar', label: '+54 Argentina' },
  { value: 'am', label: '+374 Armenia' },
  { value: 'aw', label: '+297 Aruba' },
  { value: 'au', label: '+61 Australia' },
  { value: 'at', label: '+43 Austria' },
  { value: 'az', label: '+994 Azerbaijan' },
  { value: 'bs', label: '+1-242 Bahamas' },
  { value: 'bh', label: '+973 Bahrain' },
  { value: 'bd', label: '+880 Bangladesh' },
  { value: 'bb', label: '+1-246 Barbados' },
  { value: 'by', label: '+375 Belarus' },
  { value: 'be', label: '+32 Belgium' },
  { value: 'bz', label: '+501 Belize' },
  { value: 'bj', label: '+229 Benin' },
  { value: 'bm', label: '+1-441 Bermuda' },
  { value: 'bt', label: '+975 Bhutan' },
  { value: 'bo', label: '+591 Bolivia' },
  { value: 'ba', label: '+387 Bosnia and Herzegovina' },
  { value: 'bw', label: '+267 Botswana' },
  { value: 'br', label: '+55 Brazil' },
  { value: 'io', label: '+246 British Indian Ocean Territory' },
  { value: 'vg', label: '+1-284 British Virgin Islands' },
  { value: 'bn', label: '+673 Brunei' },
  { value: 'bg', label: '+359 Bulgaria' },
  { value: 'bf', label: '+226 Burkina Faso' },
  { value: 'bi', label: '+257 Burundi' },
  { value: 'kh', label: '+855 Cambodia' },
  { value: 'cm', label: '+237 Cameroon' },
  { value: 'ca', label: '+1 Canada' },
  { value: 'cv', label: '+238 Cape Verde' },
  { value: 'ky', label: '+1-345 Cayman Islands' },
  { value: 'cf', label: '+236 Central African Republic' },
  { value: 'td', label: '+235 Chad' },
  { value: 'cl', label: '+56 Chile' },
  { value: 'cn', label: '+86 China' },
  { value: 'cx', label: '+61 Christmas Island' },
  { value: 'cc', label: '+61 Cocos Islands' },
  { value: 'co', label: '+57 Colombia' },
  { value: 'km', label: '+269 Comoros' },
  { value: 'ck', label: '+682 Cook Islands' },
  { value: 'cr', label: '+506 Costa Rica' },
  { value: 'hr', label: '+385 Croatia' },
  { value: 'cu', label: '+53 Cuba' },
  { value: 'cw', label: '+599 Curaçao' },
  { value: 'cy', label: '+357 Cyprus' },
  { value: 'cz', label: '+420 Czech Republic' },
  { value: 'cd', label: '+243 Democratic Republic of the Congo' },
  { value: 'dk', label: '+45 Denmark' },
  { value: 'dj', label: '+253 Djibouti' },
  { value: 'dm', label: '+1-767 Dominica' },
  { value: 'do', label: '+1-809, +1-829, +1-849 Dominican Republic' },
  { value: 'tl', label: '+670 East Timor' },
  { value: 'ec', label: '+593 Ecuador' },
  { value: 'eg', label: '+20 Egypt' },
  { value: 'sv', label: '+503 El Salvador' },
  { value: 'gq', label: '+240 Equatorial Guinea' },
  { value: 'er', label: '+291 Eritrea' },
  { value: 'ee', label: '+372 Estonia' },
  { value: 'et', label: '+251 Ethiopia' },
  { value: 'fk', label: '+500 Falkland Islands' },
  { value: 'fo', label: '+298 Faroe Islands' },
  { value: 'fj', label: '+679 Fiji' },
  { value: 'fi', label: '+358 Finland' },
  { value: 'fr', label: '+33 France' },
  { value: 'pf', label: '+689 French Polynesia' },
  { value: 'ga', label: '+241 Gabon' },
  { value: 'gm', label: '+220 Gambia' },
  { value: 'ge', label: '+995 Georgia' },
  { value: 'de', label: '+49 Germany' },
  { value: 'gh', label: '+233 Ghana' },
  { value: 'gi', label: '+350 Gibraltar' },
  { value: 'gr', label: '+30 Greece' },
  { value: 'gl', label: '+299 Greenland' },
  { value: 'gd', label: '+1-473 Grenada' },
  { value: 'gu', label: '+1-671 Guam' },
  { value: 'gt', label: '+502 Guatemala' },
  { value: 'gg', label: '+44-1481 Guernsey' },
  { value: 'gn', label: '+224 Guinea' },
  { value: 'gw', label: '+245 Guinea-Bissau' },
  { value: 'gy', label: '+592 Guyana' },
  { value: 'ht', label: '+509 Haiti' },
  { value: 'hn', label: '+504 Honduras' },
  { value: 'hk', label: '+852 Hong Kong' },
  { value: 'hu', label: '+36 Hungary' },
  { value: 'is', label: '+354 Iceland' },
  { value: 'in', label: '+91 India' },
  { value: 'id', label: '+62 Indonesia' },
  { value: 'ir', label: '+98 Iran' },
  { value: 'iq', label: '+964 Iraq' },
  { value: 'ie', label: '+353 Ireland' },
  { value: 'im', label: '+44-1624 Isle of Man' },
  { value: 'il', label: '+972 Israel' },
  { value: 'it', label: '+39 Italy' },
  { value: 'ci', label: '+225 Ivory Coast' },
  { value: 'jm', label: '+1-876 Jamaica' },
  { value: 'jp', label: '+81 Japan' },
  { value: 'je', label: '+44-1534 Jersey' },
  { value: 'jo', label: '+962 Jordan' },
  { value: 'kz', label: '+7 Kazakhstan' },
  { value: 'ke', label: '+254 Kenya' },
  { value: 'ki', label: '+686 Kiribati' },
  { value: 'xk', label: '+383 Kosovo' },
  { value: 'kw', label: '+965 Kuwait' },
  { value: 'kg', label: '+996 Kyrgyzstan' },
  { value: 'la', label: '+856 Laos' },
  { value: 'lv', label: '+371 Latvia' },
  { value: 'lb', label: '+961 Lebanon' },
  { value: 'ls', label: '+266 Lesotho' },
  { value: 'lr', label: '+231 Liberia' },
  { value: 'ly', label: '+218 Libya' },
  { value: 'li', label: '+423 Liechtenstein' },
  { value: 'lt', label: '+370 Lithuania' },
  { value: 'lu', label: '+352 Luxembourg' },
  { value: 'mo', label: '+853 Macau' },
  { value: 'mk', label: '+389 Macedonia' },
  { value: 'mg', label: '+261 Madagascar' },
  { value: 'mw', label: '+265 Malawi' },
  { value: 'my', label: '+60 Malaysia' },
  { value: 'mv', label: '+960 Maldives' },
  { value: 'ml', label: '+223 Mali' },
  { value: 'mt', label: '+356 Malta' },
  { value: 'mh', label: '+692 Marshall Islands' },
  { value: 'mr', label: '+222 Mauritania' },
  { value: 'mu', label: '+230 Mauritius' },
  { value: 'yt', label: '+262 Mayotte' },
  { value: 'mx', label: '+52 Mexico' },
  { value: 'fm', label: '+691 Micronesia' },
  { value: 'md', label: '+373 Moldova' },
  { value: 'mc', label: '+377 Monaco' },
  { value: 'mn', label: '+976 Mongolia' },
  { value: 'me', label: '+382 Montenegro' },
  { value: 'ms', label: '+1-664 Montserrat' },
  { value: 'ma', label: '+212 Morocco' },
  { value: 'mz', label: '+258 Mozambique' },
  { value: 'mm', label: '+95 Myanmar' },
  { value: 'na', label: '+264 Namibia' },
  { value: 'nr', label: '+674 Nauru' },
  { value: 'np', label: '+977 Nepal' },
  { value: 'nl', label: '+31 Netherlands' },
  { value: 'nc', label: '+687 New Caledonia' },
  { value: 'nz', label: '+64 New Zealand' },
  { value: 'ni', label: '+505 Nicaragua' },
  { value: 'ne', label: '+227 Niger' },
  { value: 'ng', label: '+234 Nigeria' },
  { value: 'nu', label: '+683 Niue' },
  { value: 'kp', label: '+850 North Korea' },
  { value: 'mp', label: '+1-670 Northern Mariana Islands' },
  { value: 'no', label: '+47 Norway' },
  { value: 'om', label: '+968 Oman' },
  { value: 'pk', label: '+92 Pakistan' },
  { value: 'pw', label: '+680 Palau' },
  { value: 'ps', label: '+970 Palestine' },
  { value: 'pa', label: '+507 Panama' },
  { value: 'pg', label: '+675 Papua New Guinea' },
  { value: 'py', label: '+595 Paraguay' },
  { value: 'pe', label: '+51 Peru' },
  { value: 'ph', label: '+63 Philippines' },
  { value: 'pn', label: '+64 Pitcairn Islands' },
  { value: 'pl', label: '+48 Poland' },
  { value: 'pt', label: '+351 Portugal' },
  { value: 'pr', label: '+1-787, +1-939 Puerto Rico' },
  { value: 'qa', label: '+974 Qatar' },
  { value: 'cg', label: '+242 Republic of the Congo' },
  { value: 're', label: '+262 Réunion' },
  { value: 'ro', label: '+40 Romania' },
  { value: 'ru', label: '+7 Russia' },
  { value: 'rw', label: '+250 Rwanda' },
  { value: 'bl', label: '+590 Saint Barthélemy' },
  { value: 'sh', label: '+290 Saint Helena' },
  { value: 'kn', label: '+1-869 Saint Kitts and Nevis' },
  { value: 'lc', label: '+1-758 Saint Lucia' },
  { value: 'mf', label: '+590 Saint Martin' },
  { value: 'pm', label: '+508 Saint Pierre and Miquelon' },
  { value: 'vc', label: '+1-784 Saint Vincent and the Grenadines' },
  { value: 'ws', label: '+685 Samoa' },
  { value: 'sm', label: '+378 San Marino' },
  { value: 'st', label: '+239 São Tomé and Príncipe' },
  { value: 'sa', label: '+966 Saudi Arabia' },
  { value: 'sn', label: '+221 Senegal' },
  { value: 'rs', label: '+381 Serbia' },
  { value: 'sc', label: '+248 Seychelles' },
  { value: 'sl', label: '+232 Sierra Leone' },
  { value: 'sg', label: '+65 Singapore' },
  { value: 'sx', label: '+1-721 Sint Maarten' },
  { value: 'sk', label: '+421 Slovakia' },
  { value: 'si', label: '+386 Slovenia' },
  { value: 'sb', label: '+677 Solomon Islands' },
  { value: 'so', label: '+252 Somalia' },
  { value: 'za', label: '+27 South Africa' },
  { value: 'kr', label: '+82 South Korea' },
  { value: 'ss', label: '+211 South Sudan' },
  { value: 'es', label: '+34 Spain' },
  { value: 'lk', label: '+94 Sri Lanka' },
  { value: 'sd', label: '+249 Sudan' },
  { value: 'sr', label: '+597 Suriname' },
  { value: 'sz', label: '+268 Eswatini' },
  { value: 'se', label: '+46 Sweden' },
  { value: 'ch', label: '+41 Switzerland' },
  { value: 'sy', label: '+963 Syria' },
  { value: 'tw', label: '+886 Taiwan' },
  { value: 'tj', label: '+992 Tajikistan' },
  { value: 'tz', label: '+255 Tanzania' },
  { value: 'th', label: '+66 Thailand' },
  { value: 'tg', label: '+228 Togo' },
  { value: 'tk', label: '+690 Tokelau' },
  { value: 'to', label: '+676 Tonga' },
  { value: 'tt', label: '+1-868 Trinidad and Tobago' },
  { value: 'tn', label: '+216 Tunisia' },
  { value: 'tr', label: '+90 Turkey' },
  { value: 'tm', label: '+993 Turkmenistan' },
  { value: 'tc', label: '+1-649 Turks and Caicos Islands' },
  { value: 'tv', label: '+688 Tuvalu' },
  { value: 'vi', label: '+1-340 U.S. Virgin Islands' },
  { value: 'ug', label: '+256 Uganda' },
  { value: 'ua', label: '+380 Ukraine' },
  { value: 'ae', label: '+971 United Arab Emirates' },
  { value: 'gb', label: '+44 United Kingdom' },
  { value: 'us', label: '+1 United States' },
  { value: 'uy', label: '+598 Uruguay' },
  { value: 'uz', label: '+998 Uzbekistan' },
  { value: 'vu', label: '+678 Vanuatu' },
  { value: 'va', label: '+39 Vatican City' },
  { value: 've', label: '+58 Venezuela' },
  { value: 'vn', label: '+84 Vietnam' },
  { value: 'wf', label: '+681 Wallis and Futuna' },
  { value: 'eh', label: '+212 Western Sahara' },
  { value: 'ye', label: '+967 Yemen' },
  { value: 'zm', label: '+260 Zambia' },
  { value: 'zw', label: '+263 Zimbabwe' }
];


export default countryOptions;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setActiveFormId } from "../FormsModal/FormSlice";
import { setLoginStep } from "../Login/LoginSlice";
import { setActiveRegistrationStep } from "../Registration/RegistrationSlice";
import { setTotalPayeeTransactions } from '../FormsModal/FormSlice';
import agent from "../../agent";

function PreValidateUser() {
    const dispatch = useDispatch();
    const onclickLogin = () => {
        dispatch(setActiveFormId(null));
        dispatch(setLoginStep("1"));

    }
    const onclickSignUP = () => {
        fetchTotalPayeeTransaction().then((response) => {
            if (response) {
                dispatch(setTotalPayeeTransactions(response.data.data))
                dispatch(setActiveFormId(null));
                dispatch(setActiveRegistrationStep("1"));
                fetchTotalPayeeTransaction();
            }

        })

    }

    const handleCloseModel = () => {
        dispatch(setActiveFormId(""));
    }
    const fetchTotalPayeeTransaction = () => {
        try {
            return agent.User.payeeTotalTransactions((response) => {
                if (response) {
                    dispatch(setTotalPayeeTransactions(response.data));
                }
            })
        } catch (error) {
            console.log("error fetching total payees and amount.", error);
        }
    }


    return (
        <div className="modal fade show d-block login-modal all-modals-common-class" aria-hidden="true" tabIndex="-1">
            <div className="modal-dialog">
                <div className="modal-content">
                    {/* Modal Close Icon */}
                    <div className="modal-close-btn">
                        <img src="images/close.png" alt="close-icon" onClick={handleCloseModel} />
                    </div>
                    <div className="modal-header">
                        <span>
                            <img src="images/login-user.png" alt="" className="login-user-icon" />
                        </span>
                        <div className="d-flex flex-column">
                            <h4 className="modal-title">
                                Login or signup to continue
                            </h4>

                        </div>
                    </div>

                    <div className="modal-footer" style={{ borderTop: "none" }}>
                        <button className="btn btn-primary send-btn" onClick={onclickLogin}>Login</button>
                        <div className="footer-or-text">
                            <span> or </span>
                        </div>
                        <button className="btn btn-primary send-btn" onClick={onclickSignUP}> Sign Up</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PreValidateUser;
import { call, delay, fork, put, select, takeLatest } from "redux-saga/effects";
import agent from "../../agent";
import {
    deleteThePayeeById, getUploadFileDataByUuid, setActiveFormId, setPayeeTotalCalculation,
    setShowLoader, setUploadedPayeeDetailsList, setValidatedPayeeDetailsList, setVerifiedPayeeDetailsList, setReferenceId,
    updateObjWithInvalidStatus, uploadFile, uploadPayee, validatePayeeDetails, initiatePayments, generateReferenceId, setCulipaFees,
    setManuallyAddedPayeeDetailsList, setEmailContent, setStatusForPayees, initiateAdminPayment, initiatePayByBank, removeThePayee,
    deleteThePayeeObjById, setFormErrorMsg, checkCulipaUserByEmail, checkCulipaUserByMobile, setUpdatedCulipaFees, setPayeeUpdatedCalculation,
    setDefaultCalculationMode, setTotalTax, setUpdatedTax, setMobileMoneyFee, setPayeeAmountToCalculateMobileFee, setTotalMobileMoneyWithFee
} from "./FormSlice";

import { getInitiateDetails, initiateDetailsForUser } from '../UserDashboard/userDashboardSlice';
import { culipaFormatter } from '../../culipaUtils/culipaFormatter';
import { setEmail, setLoginStep, setMobile } from "../Login/LoginSlice";

// Updated fees here means --> Fees with withdrawal amount.
function* calculateUpdatedCharityFess(data) {
    let updatedTotalAmount = 0;
    let updatedCulipaFees = {
        stage1Fees: { payeeCount: 0, payeeFees: 0 },
        stage2Fees: { payeeCount: 0, payeeFees: 0 },
        stage3Fees: { payeeCount: 0, payeeFees: 0 },
        stage4Fees: { payeeCount: 0, payeeFees: 0 },
        totalFees: 0,
        bankCollectionFees: 5000,
    }

    data.map((obj) => {
        // For updated amount when toggle is on.
        let amount = obj.amount;
        let mobileNo = obj.mobileNo;

        if (mobileNo.startsWith("25670") || mobileNo.startsWith("25674") || mobileNo.startsWith("25675")) {
            if (amount <= 2500) {
                amount = parseInt(amount) + 330;
            } else if (amount >= 2501 && amount <= 5000) {
                amount = parseInt(amount) + 440;
            } else if (amount >= 5001 && amount <= 15000) {
                amount = parseInt(amount) + 700;
            } else if (amount >= 15001 && amount <= 30000) {
                amount = parseInt(amount) + 880;
            } else if (amount >= 30001 && amount <= 45000) {
                amount = parseInt(amount) + 1210;
            } else if (amount >= 45001 && amount <= 60000) {
                amount = parseInt(amount) + 1500;
            } else if (amount >= 60001 && amount <= 125000) {
                amount = parseInt(amount) + 1925;
            } else if (amount >= 125001 && amount <= 250000) {
                amount = parseInt(amount) + 3575;
            } else if (amount >= 250001 && amount <= 500000) {
                amount = parseInt(amount) + 7000;
            } else if (amount >= 500001 && amount <= 1000000) {
                amount = parseInt(amount) + 12500;
            } else if (amount >= 1000001 && amount <= 2000000) {
                amount = parseInt(amount) + 12500;
            } else if (amount >= 2000001) {
                amount = parseInt(amount) + 12500;
            }
        }
        else if (mobileNo.startsWith("25676") || mobileNo.startsWith("25677") || mobileNo.startsWith("25678")) {
            if (amount <= 2500) {
                amount = parseInt(amount) + 330;
            } else if (amount >= 2501 && amount <= 5000) {
                amount = parseInt(amount) + 440;
            } else if (amount >= 5001 && amount <= 15000) {
                amount = parseInt(amount) + 700;
            } else if (amount >= 15001 && amount <= 30000) {
                amount = parseInt(amount) + 880;
            } else if (amount >= 30001 && amount <= 45000) {
                amount = parseInt(amount) + 1210;
            } else if (amount >= 45001 && amount <= 60000) {
                amount = parseInt(amount) + 1500;
            } else if (amount >= 60001 && amount <= 125000) {
                amount = parseInt(amount) + 1925;
            } else if (amount >= 125001 && amount <= 250000) {
                amount = parseInt(amount) + 3575;
            } else if (amount >= 250001 && amount <= 500000) {
                amount = parseInt(amount) + 7000;
            } else if (amount >= 500001 && amount <= 1000000) {
                amount = parseInt(amount) + 12500;
            } else if (amount >= 1000001 && amount <= 2000000) {
                amount = parseInt(amount) + 15000;
            } else if (amount >= 2000001 && amount <= 4000000) {
                amount = parseInt(amount) + 18000;
            } else if (amount >= 4000001 && amount <= 5000000) {
                amount = parseInt(amount) + 20000;
            }
        }

        if (amount >= 500 && amount <= 50000) {
            updatedCulipaFees.stage1Fees.payeeCount += 1;
        }
        if (amount >= 50001 && amount <= 500000) {
            updatedCulipaFees.stage2Fees.payeeCount += 1;
        }
        if (amount >= 500001 && amount <= 1000000) {
            updatedCulipaFees.stage3Fees.payeeCount += 1;
        }
        if (amount >= 1000001 && amount <= 5000000) {
            updatedCulipaFees.stage4Fees.payeeCount += 1;
        }
        updatedTotalAmount += Number(amount);

    });

    updatedCulipaFees.stage1Fees.payeeFees = updatedCulipaFees.stage1Fees.payeeCount * 750;
    updatedCulipaFees.stage2Fees.payeeFees = updatedCulipaFees.stage2Fees.payeeCount * 1250;
    updatedCulipaFees.stage3Fees.payeeFees = updatedCulipaFees.stage3Fees.payeeCount * 1750;
    updatedCulipaFees.stage4Fees.payeeFees = updatedCulipaFees.stage4Fees.payeeCount * 2500;
    updatedCulipaFees.totalFees =
        updatedCulipaFees.stage1Fees.payeeFees +
        updatedCulipaFees.stage2Fees.payeeFees +
        updatedCulipaFees.stage3Fees.payeeFees +
        updatedCulipaFees.stage4Fees.payeeFees;


    yield put(setUpdatedCulipaFees(updatedCulipaFees));
    yield put(setPayeeUpdatedCalculation(updatedTotalAmount));
}

function* calculateFees(data) {
    let totalAmount = 0;

    let culipaFees = {
        stage1Fees: { payeeCount: 0, payeeFees: 0 },
        stage2Fees: { payeeCount: 0, payeeFees: 0 },
        stage3Fees: { payeeCount: 0, payeeFees: 0 },
        stage4Fees: { payeeCount: 0, payeeFees: 0 },
        totalFees: 0,
        bankCollectionFees: 5000,
    }

    data.map((obj) => {
        let amount = obj.amount;

        if (amount >= 500 && amount <= 50000) {
            culipaFees.stage1Fees.payeeCount += 1;
        }
        if (amount >= 50001 && amount <= 500000) {
            culipaFees.stage2Fees.payeeCount += 1;
        }
        if (amount >= 500001 && amount <= 1000000) {
            culipaFees.stage3Fees.payeeCount += 1;
        }
        if (amount >= 1000001 && amount <= 5000000) {
            culipaFees.stage4Fees.payeeCount += 1;
        }
        totalAmount += Number(amount);

    });

    culipaFees.stage1Fees.payeeFees = culipaFees.stage1Fees.payeeCount * 750;
    culipaFees.stage2Fees.payeeFees = culipaFees.stage2Fees.payeeCount * 1250;
    culipaFees.stage3Fees.payeeFees = culipaFees.stage3Fees.payeeCount * 1750;
    culipaFees.stage4Fees.payeeFees = culipaFees.stage4Fees.payeeCount * 2500;
    culipaFees.totalFees =
        culipaFees.stage1Fees.payeeFees +
        culipaFees.stage2Fees.payeeFees +
        culipaFees.stage3Fees.payeeFees +
        culipaFees.stage4Fees.payeeFees;

    yield put(setCulipaFees(culipaFees));
    yield put(setPayeeTotalCalculation(totalAmount));
}

function* calculateFeesWithJsonRes(data, jsonRes, defaultFlag) {
    let totalAmount = 0;
    let totalTaxAmount = 0;
    let totalAmountWithTax = 0;

    let disbursementAmountRanges = {
        disbursement: jsonRes.disbursement,
        collection: jsonRes.collection,
    }

    data.map((obj) => {
        let amount = parseInt(obj.amount);
        if (defaultFlag) {
            disbursementAmountRanges.disbursement.tier.map((obj) => {
                if (amount >= obj.min && amount <= obj.max) {
                    let taxToAdd = (amount * (obj.percentage_fee / 100)) + parseFloat(obj.flat_fee);
                    obj.payeeCount += 1;
                    totalAmount += amount;
                    totalTaxAmount += taxToAdd;
                    totalAmountWithTax += amount + taxToAdd;
                }
            });
        } else {
            let obj1 = {
                percentage_fee: disbursementAmountRanges.disbursement.default.percentage_fee,
                flat_fee: disbursementAmountRanges.disbursement.default.flat_fee
            }
            let taxToAdd = (amount * (obj1.percentage_fee / 100)) + parseFloat(obj1.flat_fee);
            obj1.payeeCount += 1;
            totalAmount += amount;
            totalTaxAmount += taxToAdd;
            totalAmountWithTax += amount + taxToAdd;
        }
    });
    yield put(setTotalTax(totalTaxAmount));
    yield put(setPayeeTotalCalculation(totalAmount));
}

// Updated fees here means --> Fees with withdrawal amount.
function* calculateUpdatedFeesWithJsonRes(data, jsonRes, defaultFlag) {
    let totalAmount = 0;
    let totalTaxAmount = 0;
    let totalAmountWithTax = 0;

    let disbursementAmountRanges = {
        disbursement: jsonRes.disbursement,
        collection: jsonRes.collection,
    }

    data.map((obj) => {
        let amount = parseInt(obj.amount);
        let mobileNo = obj.mobileNo;

        if (mobileNo.startsWith("25670") || mobileNo.startsWith("25674") || mobileNo.startsWith("25675")) {
            if (amount <= 2500) {
                amount = parseInt(amount) + 330;
            } else if (amount >= 2501 && amount <= 5000) {
                amount = parseInt(amount) + 440;
            } else if (amount >= 5001 && amount <= 15000) {
                amount = parseInt(amount) + 700;
            } else if (amount >= 15001 && amount <= 30000) {
                amount = parseInt(amount) + 880;
            } else if (amount >= 30001 && amount <= 45000) {
                amount = parseInt(amount) + 1210;
            } else if (amount >= 45001 && amount <= 60000) {
                amount = parseInt(amount) + 1500;
            } else if (amount >= 60001 && amount <= 125000) {
                amount = parseInt(amount) + 1925;
            } else if (amount >= 125001 && amount <= 250000) {
                amount = parseInt(amount) + 3575;
            } else if (amount >= 250001 && amount <= 500000) {
                amount = parseInt(amount) + 7000;
            } else if (amount >= 500001 && amount <= 1000000) {
                amount = parseInt(amount) + 12500;
            } else if (amount >= 1000001 && amount <= 2000000) {
                amount = parseInt(amount) + 12500;
            } else if (amount >= 2000001) {
                amount = parseInt(amount) + 12500;
            }
        }
        else if (mobileNo.startsWith("25676") || mobileNo.startsWith("25677") || mobileNo.startsWith("25678")) {
            if (amount <= 2500) {
                amount = parseInt(amount) + 330;
            } else if (amount >= 2501 && amount <= 5000) {
                amount = parseInt(amount) + 440;
            } else if (amount >= 5001 && amount <= 15000) {
                amount = parseInt(amount) + 700;
            } else if (amount >= 15001 && amount <= 30000) {
                amount = parseInt(amount) + 880;
            } else if (amount >= 30001 && amount <= 45000) {
                amount = parseInt(amount) + 1210;
            } else if (amount >= 45001 && amount <= 60000) {
                amount = parseInt(amount) + 1500;
            } else if (amount >= 60001 && amount <= 125000) {
                amount = parseInt(amount) + 1925;
            } else if (amount >= 125001 && amount <= 250000) {
                amount = parseInt(amount) + 3575;
            } else if (amount >= 250001 && amount <= 500000) {
                amount = parseInt(amount) + 7000;
            } else if (amount >= 500001 && amount <= 1000000) {
                amount = parseInt(amount) + 12500;
            } else if (amount >= 1000001 && amount <= 2000000) {
                amount = parseInt(amount) + 15000;
            } else if (amount >= 2000001 && amount <= 4000000) {
                amount = parseInt(amount) + 18000;
            } else if (amount >= 4000001 && amount <= 5000000) {
                amount = parseInt(amount) + 20000;
            }
        }

        if (defaultFlag) {
            disbursementAmountRanges.disbursement.tier.map((obj) => {
                if (amount >= obj.min && amount <= obj.max) {
                    let taxToAdd = (amount * (obj.percentage_fee / 100)) + parseFloat(obj.flat_fee);
                    obj.payeeCount += 1;
                    totalAmount += amount;
                    totalTaxAmount += taxToAdd;
                    totalAmountWithTax += amount + taxToAdd;
                }
            });
        } else {
            let obj = {
                percentage_fee: disbursementAmountRanges.disbursement.default.percentage_fee,
                flat_fee: disbursementAmountRanges.disbursement.default.flat_fee
            }
            let taxToAdd = (amount * (obj.percentage_fee / 100)) + parseFloat(obj.flat_fee);
            obj.payeeCount += 1;
            totalAmount += amount;
            totalTaxAmount += taxToAdd;
            totalAmountWithTax += amount + taxToAdd;
        }
    });

    yield put(setUpdatedTax(totalTaxAmount));
    yield put(setPayeeUpdatedCalculation(totalAmount));
    yield put(setCulipaFees(totalTaxAmount));
}

const getState = (state) => (state.formSlice.payeeAmountToCalculateMobileFee);

function* calculateMobileMoneyAmount(jsonRes, defaultValue) {
    const amount = yield select(getState);
    let collection = jsonRes.collection;
    let amountToSave = 0;
    console.log();
    if (defaultValue) {
        collection.tier.map((obj) => {
            if (obj.min <= amount && obj.max >= amount) {
                amountToSave = (amount * parseFloat(obj.percentage_fee) / 100);
                return;
            }
        })
        yield put(setTotalMobileMoneyWithFee(amountToSave));
    } else {
        amountToSave = (amount * collection.default.percentage_fee / 100);
        yield put(setTotalMobileMoneyWithFee(amountToSave));
    }
}

function* onUploadFile(data) {
    try {
        const result = yield call(agent.Upload.uploadFile, data.payload);
        if (result && result.status === 200) {
            let formObject = Object.fromEntries(data.payload.entries());
            yield put(getUploadFileDataByUuid(formObject.uuid))
        } else if (result && result.response) {
            yield put(setFormErrorMsg(result.response.data.message));
            yield put(setShowLoader(false));
        }
    } catch (e) {
        console.log(e);
        yield put(setShowLoader(false));
    }
}

function* onGetPayeeDetailsByUuid(data) {
    try {
        const result = yield call(agent.Upload.getUploadedDetailsByUuid, data.payload);
        if (result && result.status === 200) {
            yield put(setUploadedPayeeDetailsList(result.data.data))
            yield put(setActiveFormId("2"));
            yield put(setShowLoader(false));
        } else if (result && result.response) {
            yield put(setFormErrorMsg(result.response.data.message));
            yield put(setShowLoader(false));
        }
    } catch (e) {
        console.log(e);
        yield put(setShowLoader(false));
    }
}

function* onValidatePayeeDetails(obj) {
    try {
        let uuid = ""
        let toByPass = ""

        if (obj.activeUuid != null) {
            console.log(obj.activeUuid, "id 1");
            uuid = obj.activeUuid
            toByPass = obj.toByPass
        } else {
            console.log(obj.payload.activeUuid, "id 2");

            uuid = obj.payload.activeUuid
            toByPass = obj.payload.toByPass
        }
        const result = yield call(agent.Form.validateUserFromCulipa, uuid);

        console.log(result, "staussssssssssssss");
        if (result && result.status === 200) {
            if (!toByPass && result.data.data.length != 0) {
                // If any payee details is not validated successfully.
                yield put(setValidatedPayeeDetailsList(result.data.data));
                yield put(setActiveFormId("3"));
            } else {
                // If every payee's details are validated with no issues.
                yield call(setForNonValidatedPayees, uuid);
            }
            yield put(setShowLoader(false));
        } else if (result && result.response) {
            yield put(setFormErrorMsg(result.response.data.message));
            yield put(setShowLoader(false));
        }
    } catch (e) {
        console.log(e);
        yield put(setShowLoader(false));
    }
}

function* updateObjWithRejectedStatus(data) {
    console.log(data, "here is data from update");
    try {
        let uuid = ""
        let toByPass = ""

        if (data.activeUuid != null) {
            uuid = data.activeUuid
            toByPass = data.toByPass
        } else {
            uuid = data.payload.activeUuid
            toByPass = data.payload.toByPass
        }
        const result = yield call(agent.Form.updatePayeeDetails, data.payload.obj);
        const { isManuallyAddedPayees } = yield select((state) => state.formSlice)
        if (result && result.status === 200) {
            if (!toByPass && result.data.data.length != 0) {
                const jsonResponse = yield call(agent.Form.getJsonResToSetDisbursementRanges, data.payload.uuid);
                console.log("------------------JSON RESPONSE------------------");
                console.log(jsonResponse);
                const response = yield call(agent.Upload.getUploadedDetailsByUuid, data.payload.uuid);
                if (jsonResponse != "" && jsonResponse.data != "" && jsonResponse.data.data != "") {
                    const jsonRes = JSON.parse(jsonResponse.data.data);
                    if (jsonRes != '' && jsonRes != undefined) {
                        if (jsonRes.disbursement.tier.length > 0) {
                            yield put(setDefaultCalculationMode(false));
                            yield call(calculateFeesWithJsonRes, response.data.data, jsonRes, true);
                            yield call(calculateUpdatedFeesWithJsonRes, response.data.data, jsonRes, true);
                        } else if (jsonRes.disbursement.default.flat_fee != 0 && jsonRes.disbursement.default.percentage_fee != 0) {
                            yield put(setDefaultCalculationMode(false));
                            yield call(calculateFeesWithJsonRes, response.data.data, jsonRes, false);
                            yield call(calculateUpdatedFeesWithJsonRes, response.data.data, jsonRes, false);
                        }
                        if (jsonRes.collection.tier.length > 0) {
                            yield put(setPayeeAmountToCalculateMobileFee());
                            yield call(calculateMobileMoneyAmount, jsonRes, true);
                        } else if (jsonRes.collection.default.percentage_fee != 0) {
                            yield put(setPayeeAmountToCalculateMobileFee());
                            yield call(calculateMobileMoneyAmount, jsonRes, false);
                        } else {
                            yield put(setMobileMoneyFee(parseFloat(0.03)));
                        }
                    } else {
                        yield put(setDefaultCalculationMode(true));
                        yield call(calculateFees, response.data.data);
                        yield call(calculateUpdatedCharityFess, response.data.data);
                        yield put(setMobileMoneyFee(parseFloat(0.03)));
                    }
                } else {
                    yield put(setDefaultCalculationMode(true));
                    yield call(calculateFees, response.data.data);
                    yield call(calculateUpdatedCharityFess, response.data.data);
                    yield put(setMobileMoneyFee(parseFloat(0.03)));
                }
                yield put(setValidatedPayeeDetailsList(result.data.data))
            } else {
                yield put(setValidatedPayeeDetailsList([]));
                const result1 = yield call(agent.Upload.getUploadedDetailsByUuid, data.payload.uuid);
                if (isManuallyAddedPayees) {
                    yield put(setManuallyAddedPayeeDetailsList(result1.data.data));
                } else {
                    yield put(setUploadedPayeeDetailsList(result1.data.data));
                }
                if (result1 && result1.status === 200) {
                    let totalAmount = 0;
                    result1.data.data.map((obj) => {
                        totalAmount += Number(obj.amount);
                    })
                    const jsonResponse = yield call(agent.Form.getJsonResToSetDisbursementRanges, data.payload.uuid);
                    const response = yield call(agent.Upload.getUploadedDetailsByUuid, data.payload.uuid);
                    if (jsonResponse != "" && jsonResponse.data != "" && jsonResponse.data.data != "") {
                        const jsonRes = JSON.parse(jsonResponse.data.data);
                        if (jsonRes != '' && jsonRes != undefined) {
                            if (jsonRes.disbursement.tier.length > 0) {
                                yield put(setDefaultCalculationMode(false));
                                yield call(calculateFeesWithJsonRes, response.data.data, jsonRes, true);
                                yield call(calculateUpdatedFeesWithJsonRes, response.data.data, jsonRes, true);
                            } else if (jsonRes.disbursement.default.flat_fee != 0 && jsonRes.disbursement.default.percentage_fee != 0) {
                                yield put(setDefaultCalculationMode(false));
                                yield call(calculateFeesWithJsonRes, response.data.data, jsonRes, false);
                                yield call(calculateUpdatedFeesWithJsonRes, response.data.data, jsonRes, false);
                            }
                            if (jsonRes.collection.tier.length > 0) {
                                yield put(setPayeeAmountToCalculateMobileFee());
                                yield call(calculateMobileMoneyAmount, jsonRes, true);
                            } else if (jsonRes.collection.default.percentage_fee != 0) {
                                yield put(setPayeeAmountToCalculateMobileFee());
                                yield call(calculateMobileMoneyAmount, jsonRes, false);
                            } else {
                                yield put(setMobileMoneyFee(parseFloat(0.03)));
                            }
                        } else {
                            yield put(setDefaultCalculationMode(true));
                            yield call(calculateFees, response.data.data);
                            yield call(calculateUpdatedCharityFess, response.data.data);
                            yield put(setMobileMoneyFee(parseFloat(0.03)));
                        }
                    } else {
                        yield put(setDefaultCalculationMode(true));
                        yield call(calculateFees, response.data.data);
                        yield call(calculateUpdatedCharityFess, response.data.data);
                        yield put(setMobileMoneyFee(parseFloat(0.03)));
                    }
                    yield put(setPayeeTotalCalculation(totalAmount));
                    yield put(setVerifiedPayeeDetailsList(result1.data.data));
                    yield put(setActiveFormId("4"));
                }
            }
            yield put(setShowLoader(false));
        } else if (result && result.response) {
            yield put(setFormErrorMsg(result.response.data.message));
            yield put(setShowLoader(false));
        }
    } catch (e) {
        console.log(e);
        yield put(setShowLoader(false));
    }
}

function* deleteThePayee(data) {
    console.log(data, "Data is sssssss");
    try {
        let uuid = ""
        let toByPass = ""
        if (data.uuid != null) {
            uuid = data.uuid
            console.log("uuid inside if", uuid);
            toByPass = data.toByPass
        } else {
            uuid = data.payload.uuid
            console.log("uuid inside else", uuid);
            toByPass = data.payload.toByPass
        }
        const result = yield call(agent.Form.deleteThePayee, data.payload.id);
        if (result && result.status === 200) {
            if (!toByPass && result.data.data.length != 0) {
                yield put(setValidatedPayeeDetailsList(result.data.data))
            } else {
                yield call(setForNonValidatedPayees, uuid);
            }
            yield put(setShowLoader(false));
        } else if (result && result.response) {
            yield put(setFormErrorMsg(result.response.data.message));
            yield put(setShowLoader(false));
        }
    } catch (e) {
        console.log(e);
        yield put(setShowLoader(false));
    }
}

function* deleteThePayeeObj(data) {
    try {
        const result = yield call(agent.Form.deleteThePayeeObj, data.payload.id);
        const { isManuallyAddedPayees } = yield select((state) => state.formSlice)
        if (result && result.status === 200) {
            yield put(removeThePayee(data.payload.id));
            yield put(setActiveFormId("2"));
            yield put(setShowLoader(false));
        } else if (result && result.response) {
            yield put(setFormErrorMsg(result.response.data.message));
            yield put(setShowLoader(false));
        }
    } catch (e) {
        console.log(e);
        yield put(setShowLoader(false));
    }
}

function* uploadPayeeList(payeeObj) {
    try {
        let uuid = payeeObj.payload.uuid;
        let payeeObject = {
            uuid: uuid,
            payeeDetails: payeeObj.payload.payeeList
        }
        const result = yield call(agent.Form.uploadPayee, payeeObject);
        if (result) {
            let actionPaylod = {
                activeUuid: uuid,
                toByPass: false
            }
            yield call(onValidatePayeeDetails, actionPaylod);
            yield put(setShowLoader(false));
        }
    } catch (error) {
        console.log(error);
        yield put(setShowLoader(false));
    }
}

function* initiatePayment(initiatePaymentRequestPayload) {
    try {
        yield put(setShowLoader(true));
        const response = yield call(agent.Payment.initiatePayment, initiatePaymentRequestPayload.payload);
        if (response.status === 200) {
            yield put(setShowLoader(false));
            yield put(setActiveFormId(""));
            yield put(getInitiateDetails());
        } else {
            yield put(setShowLoader(false));
        }
    } catch (error) {
        yield put(setShowLoader(false));
        console.log(error);
    }
}

function* generateReferenceIdForPayment(data) {
    try {
        let referenceId = '';
        let isPresent = false;
        do {
            const jsonResponse = yield call(agent.Form.getJsonResToSetDisbursementRanges, data.payload);
            if (jsonResponse != "" && jsonResponse.data != "" && jsonResponse.data.data != "") {
                const jsonRes = JSON.parse(jsonResponse.data.data);
                if (jsonRes != '' && jsonRes != undefined && jsonRes.collection.tier.length > 0) {
                    yield put(setPayeeAmountToCalculateMobileFee());
                    yield call(calculateMobileMoneyAmount, jsonRes);
                } else if (jsonRes != '' && jsonRes != undefined && jsonRes.collection.default.percentage_fee != 0) {
                    yield put(setPayeeAmountToCalculateMobileFee());
                    yield call(calculateMobileMoneyAmount, jsonRes, false);
                } else {
                    yield put(setMobileMoneyFee(parseFloat(0.03)));
                }
            }
            referenceId = culipaFormatter.getAlfaNumericDigit();
            let response = yield call(agent.userDashboard.checkTransactionIdExistOrNot, referenceId)
            isPresent = response.data;
        } while (isPresent);
        yield put(setReferenceId(referenceId));
    } catch (error) {
        console.log(error);
    }
}

function* sendEmailWithAttachment(formData) {
    try {
        yield put(setShowLoader(true));
        const response = yield call(agent.User.sendEmail, formData.payload);
        if (response.status === 200) {
            yield put(setShowLoader(false));
            yield put(setActiveFormId(""));
        } else {
            yield put(setShowLoader(false));
        }
    } catch (error) {
        yield put(setShowLoader(false));
        console.log(error);
    }
}

function* setPendingStatusForPayees(formData) {
    try {
        yield put(setShowLoader(true));
        const response = yield call(agent.User.updateStatus, formData.payload);
        if (response.status === 200) {
            yield put(setActiveFormId(""));
            yield put(getInitiateDetails());
            yield delay(3000)
            yield put(initiateDetailsForUser(encodeURIComponent(formData.payload.emailConcatenatedString)));
        } else {
            yield put(setShowLoader(false));
        }
        yield put(setShowLoader(false));
    } catch (error) {
        yield put(setShowLoader(false));
        console.log(error);
    }
    yield put(setShowLoader(false));
}

function* initiateAdminPayments(initiateAdminPaymentRequestPayload) {
    try {
        yield put(setShowLoader(true));
        const response = yield call(agent.Payment.initiateAdminPayment, initiateAdminPaymentRequestPayload.payload);
        if (response.status === 200) {
            yield put(setShowLoader(false));
            yield put(setActiveFormId(""));
            yield put(getInitiateDetails());
            yield delay(3000)
            yield put(initiateDetailsForUser(encodeURIComponent(initiateAdminPaymentRequestPayload.payload.userEmail)));
        } else {
            yield put(setShowLoader(false));
        }
    } catch (error) {
        yield put(setShowLoader(false));
        console.log(error);
    }
}

function* initiatePayByBankRequest(initiatePayByBankRequestPayload) {
    try {
        yield put(setShowLoader(true));
        const response = yield call(agent.Payment.initiatePayByBank, initiatePayByBankRequestPayload.payload);
        if (response.status === 200) {
            yield put(setShowLoader(false));
            yield put(setActiveFormId(""));
            yield put(getInitiateDetails());
            yield delay(3000)
            yield put(initiateDetailsForUser(encodeURIComponent(initiatePayByBankRequestPayload.payload.userEmail)));

        } else {
            yield put(setShowLoader(false));
        }
    } catch (error) {
        yield put(setShowLoader(false));
        console.log(error);
    }
}

function* checkForCulipaUserByEmail(obj) {
    try {
        yield put(setShowLoader(true));
        const result = yield call(agent.Form.getCulipaUserByEmail, obj.payload);
        if (result && result.status === 200) {
            localStorage.removeItem("jwt-admin");
            localStorage.removeItem("Role");
            yield put(setEmail(result.data.data));
            yield put(setFormErrorMsg(''));
            yield put(setLoginStep('2'));
            yield put(setShowLoader(false));
        } else if (result && result.response) {
            yield put(setLoginStep("1"));
            yield put(setFormErrorMsg(result.response.data.data));
            yield put(setShowLoader(false));
        }
    } catch (error) {
        yield put(setShowLoader(false));
        console.log(error);
    }
}

function* checkForCulipaUserByMobile(obj) {
    try {
        yield put(setShowLoader(true));
        const result = yield call(agent.Form.getCulipaUserByMobile, obj.payload);
        if (result && result.status === 200) {
            console.log("Console the result", result);
            localStorage.removeItem("jwt-admin");
            localStorage.removeItem("Role");
            yield put(setMobile(result.data.data));
            yield put(setFormErrorMsg(''));
            yield put(setLoginStep('2'));
            yield put(setShowLoader(false));
        } else if (result && result.response) {
            yield put(setLoginStep("1"));
            yield put(setFormErrorMsg(result.response.data.data));
            yield put(setShowLoader(false));
        }
    } catch (error) {
        yield put(setShowLoader(false));
        console.log(error);
    }
}

function* setForNonValidatedPayees(uuid) {
    const { isManuallyAddedPayees } = yield select((state) => state.formSlice)

    console.log(isManuallyAddedPayees, uuid, "obj hereee");
    const result1 = yield call(agent.Upload.getUploadedDetailsByUuid, uuid);
    if (isManuallyAddedPayees) {
        yield put(setManuallyAddedPayeeDetailsList(result1.data.data));
    } else {
        yield put(setUploadedPayeeDetailsList(result1.data.data));
    }
    if (result1 && result1.status === 200) {
        const jsonResponse = yield call(agent.Form.getJsonResToSetDisbursementRanges, uuid);
        const response = yield call(agent.Upload.getUploadedDetailsByUuid, uuid);
        if (jsonResponse != '' && jsonResponse.data != "" && jsonResponse.data.data != "") {
            const jsonRes = JSON.parse(jsonResponse.data.data);
            if (jsonRes != '' && jsonRes != undefined) {
                if (jsonRes.disbursement.tier.length > 0) {
                    yield put(setDefaultCalculationMode(false));
                    yield call(calculateFeesWithJsonRes, response.data.data, jsonRes, true);
                    yield call(calculateUpdatedFeesWithJsonRes, response.data.data, jsonRes, true);
                } else if (jsonRes.disbursement.default.flat_fee != 0 && jsonRes.disbursement.default.percentage_fee != 0) {
                    yield put(setDefaultCalculationMode(false));
                    yield call(calculateFeesWithJsonRes, response.data.data, jsonRes, false);
                    yield call(calculateUpdatedFeesWithJsonRes, response.data.data, jsonRes, false);
                }
                if (jsonRes.collection.tier.length > 0) {
                    yield put(setPayeeAmountToCalculateMobileFee());
                    yield call(calculateMobileMoneyAmount, jsonRes, true);
                } else if (jsonRes.collection.default.percentage_fee != 0) {
                    yield put(setPayeeAmountToCalculateMobileFee());
                    yield call(calculateMobileMoneyAmount, jsonRes, false);
                } else {
                    yield put(setMobileMoneyFee(parseFloat(0.03)));
                }
            } else {
                yield put(setDefaultCalculationMode(true));
                yield call(calculateFees, response.data.data);
                yield call(calculateUpdatedCharityFess, response.data.data);
                yield put(setMobileMoneyFee(parseFloat(0.03)));
            }
        } else {
            yield put(setDefaultCalculationMode(true));
            yield call(calculateFees, response.data.data);
            yield call(calculateUpdatedCharityFess, response.data.data);
            yield put(setMobileMoneyFee(parseFloat(0.03)));
        }
        yield put(setVerifiedPayeeDetailsList(result1.data.data));
        yield put(setActiveFormId("4"));
    }
    yield put(setShowLoader(false));
}


function* onFormStart() {
    yield takeLatest(uploadFile.type, onUploadFile);
    yield takeLatest(getUploadFileDataByUuid.type, onGetPayeeDetailsByUuid);
    yield takeLatest(validatePayeeDetails.type, onValidatePayeeDetails);
    yield takeLatest(updateObjWithInvalidStatus.type, updateObjWithRejectedStatus);
    yield takeLatest(deleteThePayeeById.type, deleteThePayee);
    yield takeLatest(uploadPayee.type, uploadPayeeList);
    yield takeLatest(initiatePayments.type, initiatePayment);
    yield takeLatest(generateReferenceId.type, generateReferenceIdForPayment);
    yield takeLatest(setEmailContent.type, sendEmailWithAttachment);
    yield takeLatest(setStatusForPayees.type, setPendingStatusForPayees);
    yield takeLatest(initiateAdminPayment.type, initiateAdminPayments);
    yield takeLatest(initiatePayByBank.type, initiatePayByBankRequest);
    yield takeLatest(deleteThePayeeObjById.type, deleteThePayeeObj);
    yield takeLatest(checkCulipaUserByEmail.type, checkForCulipaUserByEmail);
    yield takeLatest(checkCulipaUserByMobile.type, checkForCulipaUserByMobile);
}

export const formSaga = [fork(onFormStart)];